import { Grid } from "@material-ui/core";
import { Text, View } from "@react-pdf/renderer";
import rehypeStringify from "rehype-stringify";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";
import {
  extractFormFieldDetails,
  formItemPadding,
  getMainConnected,
} from "../Form";
import { checkAltLabel } from "../FormHelpersConditions";
import { AdjustPdfProps } from "../common/AdjustPdfProps";
import { parseFormLabelText, pdfDefaultFontSize } from "../common/Common";
import { FormElementTitle } from "../common/FormElementTitle";
import { FormElementTitlePDF } from "../common/FormElementTitlePDF";
import {
  formComponentTypes,
  formTypeToComponentTypeKey,
} from "../components/formComponentTypes";
import { getFormPicklistOptions } from "../components/picklist/formPicklistUtils";
import {
  formElementsWithoutInput,
  formPDFElementsWithoutDefaultTitle,
} from "../editor/GroupElement";
import { formConditionToText } from "../pdf-components/formPdfUtils";
import { FormElementForReadOnly } from "./FormElementForReadOnly";

export const FormElement = ({
  xs = 12,
  item,
  langVersion,
  connectedMap,
  describeMap,
  muBag,
  index,
  formViewType = "editable",
  disabledIds,
  printView,
  renderPrint,
  values,
  idToRenderIndex = {},
  pdfDocument,
  renderElementsWithNumbering,
  itemPadding,
  isOnlyOneInSection,
  ...props
}) => {
  const pdfView = formViewType.includes("pdf");
  const type = item.elementType;
  const elementData = formComponentTypes[type];
  const {
    labelsWidth,
    padding = {},
    itemsSpacing,
    title = {},
    conditions,
    labelAsMarkdown,
    helptextAsMarkdown,
    typeProps,
    tooltip,
    helpText,
  } = item;

  const {
    options,
    isConnected,
    showFieldLabel,
    required,
    allowPDFWrap,
    pageBreak,
  } = typeProps;
  const paddingStyles = {};
  const paddingKeys = [
    "paddingLeft",
    "paddingRight",
    "paddingTop",
    "paddingBottom",
  ];
  paddingKeys.forEach((key) => {
    let toSet = formItemPadding;
    if (pdfView) {
      if (key === "paddingTop" || key === "paddingBottom") {
        toSet = 6;
      } else {
        toSet = 0;
        if (key === "paddingRight" && itemPadding) {
          toSet = 6;
        }
      }
    }
    if (padding[key]) {
      toSet = Number(padding[key]);
    }
    if (itemsSpacing || itemsSpacing === 0) {
      toSet = Number(itemsSpacing);
    }
    paddingStyles[key] = toSet;
  });

  if (type && item.id && elementData) {
    const renderComponent =
      elementData[formTypeToComponentTypeKey[formViewType]];

    if (!renderComponent || disabledIds.includes(item.id)) {
      return null;
    }

    let sfObject, connectedFieldDetails;
    const { connectedField, connectedObject } = getMainConnected(item);
    if (connectedObject && connectedMap[connectedObject]) {
      sfObject = connectedMap[connectedObject].sfObject;
    }
    if (isConnected && connectedField) {
      connectedFieldDetails = extractFormFieldDetails({
        connectedField,
        connectedMap,
        describeMap,
        connectedObject,
      });
    }
    if (options) {
      options.forEach((option) => {
        if (option.connectedField) {
          option.connectedFieldDetails = extractFormFieldDetails({
            connectedField: option.connectedField,
            connectedMap,
            describeMap,
            connectedObject,
          });
        }
      });
    }
    let label = title;
    let helpTextLabel = helpText;
    let tooltipLabel = tooltip;

    if (conditions && conditions.length > 0) {
      const altLabel = checkAltLabel({
        item,
        describeMap,
        connectedMap,
        values,
        errors: props.baseErrors,
        langVersion,
        elementsMap: props.elementsMap,
      });
      if (typeof altLabel === "string") {
        label = altLabel;
      }
      const altHelpText = checkAltLabel({
        item,
        connectedMap,
        describeMap,
        values,
        errors: props.baseErrors,
        langVersion,
        elementsMap: props.elementsMap,
        conditionType: "altHelpText",
      });
      if (typeof altHelpText === "string") {
        helpTextLabel = altHelpText;
      }
      if (
        formViewType === "fillable-pdf" &&
        !formElementsWithoutInput.includes(type)
      ) {
        item.condtionsText = conditions
          .filter((condition) => {
            return (
              condition.conditionTarget &&
              ["hide", "show"].includes(condition.state) &&
              props.elementsMap[condition.conditionTarget]
            );
          })
          .map((condition) => {
            const elementType =
              props.elementsMap[condition.conditionTarget].elementType;
            let parameter = condition.parameter;
            if (elementType === "picklist") {
              const pisklistoptions = getFormPicklistOptions({
                connectedFieldDetails,
                typeProps:
                  props.elementsMap[condition.conditionTarget].typeProps,
                langVersion,
              });
              const picklistOption = pisklistoptions.find(
                (option, index) =>
                  `option${index}` === condition.parameter ||
                  option.apiValue === condition.parameter
              );
              parameter = picklistOption?.title;
            }
            return formConditionToText({
              ...condition,
              parameter,
              affectedElementType: type,
              elementType,
              targetIndex: idToRenderIndex[condition.conditionTarget],
            });
          })
          .filter((v) => v);
      }
    }
    label = parseFormLabelText({
      text: label,
      langVersion,
      objectsFieldsMap: props.objectsFieldsMap,
      describeMap,
      connectedMap,
      pdf: pdfView,
      removeInvalidSigns: !["text"].includes(type),
      returnString: !pdfView,
    });
    helpTextLabel = parseFormLabelText({
      text: helpTextLabel,
      langVersion,
      objectsFieldsMap: props.objectsFieldsMap,
      describeMap,
      connectedMap,
      pdf: pdfView,
      //removeInvalidSigns: true,
      returnString: !pdfView,
    });

    tooltipLabel = parseFormLabelText({
      text: tooltipLabel,
      langVersion,
      objectsFieldsMap: props.objectsFieldsMap,
      describeMap,
      connectedMap,
      pdf: pdfView,
      //removeInvalidSigns: true,
      returnString: !pdfView,
    });
    if (labelAsMarkdown) {
      label = unified()
        .use(remarkParse)
        .use(remarkRehype, { allowDangerousHtml: true })
        .use(rehypeStringify, { allowDangerousHtml: true })
        .processSync(label)
        .toString();
    }

    if (helpTextLabel && helptextAsMarkdown) {
      helpTextLabel = unified()
        .use(remarkParse)
        .use(remarkRehype, { allowDangerousHtml: true })
        .use(rehypeStringify, { allowDangerousHtml: true })
        .processSync(helpTextLabel)
        .toString();
    }

    const alt = item.altLabelPlacement;
    if (renderPrint) {
      return (
        <FormElementForReadOnly
          {...props}
          item={item}
          xs={xs}
          langVersion={langVersion}
          connectedMap={connectedMap}
          describeMap={describeMap}
          muBag={muBag}
          index={index}
          paddingStyles={paddingStyles}
          label={label}
          tooltipLabel={tooltipLabel}
          helptextLabel={helpTextLabel}
          helptextAsMarkdown={helptextAsMarkdown}
          connectedFieldDetails={connectedFieldDetails}
          connectedObject={sfObject}
        />
      );
    }
    let baseElement = renderComponent({
      ...item,
      describeMap,
      disabledIds,
      connectedMap,
      editMode: false,
      connectedObject: sfObject,
      connectedFieldDetails,
      muBag,
      langVersion,
      tooltip: tooltipLabel,
      title: label,
      helpText: helpTextLabel,
      values,
      renderIndex: renderElementsWithNumbering && idToRenderIndex[item.id],
      pdfDocument,
      ...props,
    });
    if (pdfView) {
      // const isBreak =
      //   !isOnlyOneInSection &&
      //   [
      //     "table",
      //     "budget",
      //     "objectives",
      //     "milestones",
      //     "budgetUpdate",
      //     "milestoneUpdate",
      //     "objectivesUpdate",
      //   ].includes(type);

      return (
        <View
          style={{ width: props.printWidth || "100%", ...paddingStyles }}
          wrap={["cenzusDivision"].includes(type) || Boolean(allowPDFWrap)}
          break={pageBreak}
        >
          <AdjustPdfProps typeProps={typeProps}>
            {item.condtionsText && type !== "uploadFiles" ? (
              <View style={{ marginBottom: 8 }}>
                {item.condtionsText.map((text, index) => (
                  <Text
                    key={index}
                    style={{
                      fontSize: pdfDefaultFontSize,
                      fontStyle: "italic",
                    }}
                  >
                    {text}
                  </Text>
                ))}
              </View>
            ) : (
              <View />
            )}
            {formViewType.includes("pdf") &&
              !formPDFElementsWithoutDefaultTitle.includes(type) && (
                <FormElementTitlePDF
                  parseAsHtml={labelAsMarkdown}
                  parseHelpTextAsHtml={helptextAsMarkdown}
                  helpText={helpTextLabel}
                  tooltip={tooltipLabel}
                  required={required}
                  index={idToRenderIndex[item.id]}
                  title={label}
                  showNumering={renderElementsWithNumbering}
                  language={langVersion}
                />
              )}
            {tooltipLabel && type !== "uploadFiles" ? (
              <Text
                style={{
                  fontSize: pdfDefaultFontSize - 2,
                  fontStyle: "italic",
                  marginBottom: 8,
                }}
              >
                {`${tooltipLabel}`}
              </Text>
            ) : (
              <View />
            )}
            {baseElement}
          </AdjustPdfProps>
        </View>
      );
    }

    return (
      <Grid
        key={index}
        id={item.id}
        item
        container
        xs={xs}
        style={{
          width: "100%",
          display: "flex",
          ...paddingStyles,
        }}
        direction={alt ? "row" : "column"}
        className="break-in-print"
        wrap="nowrap"
      >
        <FormElementTitle
          tooltip={tooltipLabel}
          labelsWidth={labelsWidth}
          id={item.id}
          altLabelPlacement={alt}
          helpText={!elementData.customHelptext && helpTextLabel}
          labelAsMarkdown={labelAsMarkdown}
          helptextAsMarkdown={helptextAsMarkdown}
          title={label}
          type={type}
          showFieldLabel={showFieldLabel}
          useMultiuser={props.useMultiuser}
          muBag={muBag}
          disabled={props.disabled}
          connectedObject={sfObject}
          item={item}
        />
        <Grid item xs>
          {baseElement}
        </Grid>
      </Grid>
    );
  }
};
