import { Trans } from "@lingui/macro";

const keyToLabel = {
  phaseDate: <Trans>FORM_MILESTONE_UPDATE_ERROR_DATES_LABEL</Trans>,
  phaseStartDate: <Trans>Start Date</Trans>,
  phaseEndDate: <Trans>End Date</Trans>,
  implementedActivities: (
    <Trans>FORM_MILESTONE_UPDATE_IMPLEMENTED_ACTIVITIES_QUESTION</Trans>
  ),
};

export const FormMilestoneUpdateError = (error, info) => {
  const { item } = info;
  const { typeProps } = item;
  const phases = typeProps.phases || [];
  const langVersion = info.langVersion;

  if (Array.isArray(error)) {
    return {
      title: <Trans>FORM_ELEMENT_MILESTONE_UPDATE</Trans>,
      id: error.find((item) => item?.id)?.id,
      toMap: error
        .map((item, index) => {
          if (!item) {
            return null;
          }
          const label = item?.milestone;
          return (
            <span key={index}>
              <u>
                {index + 1}
                {". "}
                {label}
              </u>
              <ul key={index}>
                {Object.entries(item?.milestoneUpdate).map(([key, value]) => {
                  if (!value?.props?.id) return null;
                  return (
                    <li
                      key={key}
                      className="m-0 text-muted"
                      style={{ maxWidth: "771px" }}
                    >
                      {keyToLabel[key]}
                      {": "}
                      {value}
                    </li>
                  );
                })}
              </ul>
            </span>
          );
        })
        .filter((v) => v),
    };
  } else {
    return error;
  }
};
