import moment from "moment";

/** This function is used to get the value of the nested property by path
 * @param {Object} obj - Object to get the value from
 * @param {string} path - Path to the nested property
 * @returns {any} Value of the nested property
 */
const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, key) => acc && acc[key], obj);
};

/** This function is used to set the value of the nested property by path
 * @param {Object} obj - Object to set the value to
 * @param {string} path - Path to the nested property
 * @param {any} value - Value to set
 * @returns {void}
 */
const setNestedValue = (obj, path, value) => {
  const keys = path.split(".");
  const lastKey = keys.pop();
  const lastObj = keys.reduce((acc, key) => (acc[key] = acc[key] || {}), obj);
  lastObj[lastKey] = value;
};

/** Adapter function to wrap main functions with dynamic fields handling.
 * This function is used to fit the main functions to the structure of dynamic fields.
 * @param {Function} fn - Main function to be wrapped
 * @returns {Function} Wrapped function
 * @param {Object[]} phases - Array of phases ({startDate, endDate})
 * @param {Object[]} arrToUpd - Array of fields to be updated in backend history(field, value, isDate)
 * @param {string} idx - index of the phase in the phases array to be checked
 * @param {string} id - Id of the element in the form
 * @param {Object} fields - mapping the correspondence between the main function fields and object fields
 * @returns {Object} Object with updated phases array(phases) and array of fields to be updated in backend history(arrToUpd).
 * */
const withDynamicFields =
  (fn) =>
  ({ phases, arrToUpd, idx, id, fields }) => {
    const dynamicPhases = phases.map((phase) => ({
      startDate: getNestedValue(phase, fields.startDate || "startDate"),
      endDate: getNestedValue(phase, fields.endDate || "endDate"),
      startDateMinDate: getNestedValue(
        phase,
        fields.startDateMinDate || "startDateMinDate"
      ),
      startDateMaxDate: getNestedValue(
        phase,
        fields.startDateMaxDate || "startDateMaxDate"
      ),
      endDateMinDate: getNestedValue(
        phase,
        fields.endDateMinDate || "endDateMinDate"
      ),
      endDateMaxDate: getNestedValue(
        phase,
        fields.endDateMaxDate || "endDateMaxDate"
      ),
      original: phase,
    }));

    const updatedPhases = fn({ phases: dynamicPhases, arrToUpd, idx, id });

    // Update original phases with new startDate and endDate values from dynamicPhases
    updatedPhases.phases.forEach((phase, i) => {
      setNestedValue(
        phases[i],
        fields.startDate || "startDate",
        phase.startDate
      );
      setNestedValue(phases[i], fields.endDate || "endDate", phase.endDate);
      setNestedValue(
        phases[i],
        fields.startDateMinDate || "startDateMinDate",
        phase.startDateMinDate
      );
      setNestedValue(
        phases[i],
        fields.startDateMaxDate || "startDateMaxDate",
        phase.startDateMaxDate
      );
      setNestedValue(
        phases[i],
        fields.endDateMinDate || "endDateMinDate",
        phase.endDateMinDate
      );
      setNestedValue(
        phases[i],
        fields.endDateMaxDate || "endDateMaxDate",
        phase.endDateMaxDate
      );
    });

    return { phases, arrToUpd };
  };

// Function to check if arrToUpd already contains object with the same field name and update it if needed
const updateArrToUpd = ({ arrToUpd, field, value, isDate = false }) => {
  const index = arrToUpd.findIndex((item) => item[0] === field);
  if (index !== -1) {
    arrToUpd[index][1] = value;
    arrToUpd[index][2] = isDate;
  } else {
    arrToUpd.push([field, value, isDate]);
  }
};

/** Function that calculates the number of days to add to the start date of the phase to get the end date of the phase within the limits of endDateMaxDate and endDateMinDate
 * @param {Object[]} phases - Array of phases ({startDate, endDate})
 * @param {string} idx - index of the phase in the days array
 * @returns {number} Number of days to add to the start date of the phase
 */
const getDaysToAddToStartDate = ({ phases, idx }) => {
  const minEndDate = phases[idx].endDateMinDate;
  const maxEndDate = phases[idx].endDateMaxDate;
  const minDaysToAdd = minEndDate
    ? moment.utc(minEndDate).diff(phases[idx].startDate, "days")
    : 1;
  const maxDaysToAdd = maxEndDate
    ? moment.utc(maxEndDate).diff(phases[idx].startDate, "days")
    : 7;

  let daysToAdd =
    minDaysToAdd > 7 ? minDaysToAdd : maxDaysToAdd > 7 ? 7 : maxDaysToAdd;

  return daysToAdd;
};

/** Function that calculates the number of days to subtract from the end date of the phase to get the start date of the phase within the limits of startDateMaxDate and startDateMinDate
 * @param {Object[]} phases - Array of phases ({startDate, endDate})
 * @param {string} idx - index of the phase in the days array
 * @returns {number} Number of days to subtract from the end date of the phase
 */
const getDaysToSubtractFromEndDate = ({ phases, idx }) => {
  const minStartDate = phases[idx].startDateMinDate;
  const maxStartDate = phases[idx].startDateMaxDate;
  const minDaysToSubtract = maxStartDate
    ? moment.utc(phases[idx].endDate).diff(maxStartDate, "days")
    : 1;
  const maxDaysToSubtract = minStartDate
    ? moment.utc(phases[idx].endDate).diff(minStartDate, "days")
    : 7;

  let daysToSubtract =
    minDaysToSubtract > 7
      ? minDaysToSubtract
      : maxDaysToSubtract > 7
      ? 7
      : maxDaysToSubtract;

  return daysToSubtract;
};

/** Function that checks and corrects the gaps of the previous phases if the start date of the current phase is changed
 * @param {Object[]} phases - Array of phases ({startDate, endDate})
 * @param {Object[]} arrToUpd - Array of fields to be updated in backend history(field, value, isDate)
 * @param {string} idx - index of the phase in the phases array to be checked
 * @param {string} id - Id of the element in the form
 * @returns {Object} Object with updated phases array(phases) and array of fields to be updated in backend history(arrToUpd).
 */
const checkStartDateChangeBackwardDirection = ({
  phases,
  arrToUpd,
  idx,
  id,
}) => {
  if (
    idx > 0 &&
    moment
      .utc(phases[idx].startDate)
      .isSameOrBefore(moment.utc(phases[idx - 1].endDate))
  ) {
    phases[idx - 1].endDate = moment
      .utc(phases[idx].startDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    updateArrToUpd({
      arrToUpd,
      field: `${id}[${idx - 1}].endDate`,
      value: phases[idx - 1].endDate,
      isDate: true,
    });
    const daysToSubtract = getDaysToSubtractFromEndDate({
      phases,
      idx: idx - 1,
    });
    if (
      moment
        .utc(phases[idx - 1].endDate)
        .diff(moment.utc(phases[idx - 1].startDate), "days") < daysToSubtract
    ) {
      phases[idx - 1].startDate = moment
        .utc(phases[idx - 1].endDate)
        .subtract(daysToSubtract, "days")
        .format("YYYY-MM-DD");
      updateArrToUpd({
        arrToUpd,
        field: `${id}[${idx - 1}].startDate`,
        value: phases[idx - 1].startDate,
        isDate: true,
      });
    }
  }
  if (idx > 0) {
    return checkStartDateChangeBackwardDirection({
      phases,
      arrToUpd,
      idx: idx - 1,
      id,
    });
  }
  return { phases, arrToUpd };
};

/** Function that checks and corrects the gaps between the start date and end date of the phases(previous and next) if the start date of the phase is changed
 * @param {Object[]} phases - Array of phases ({startDate, endDate})
 * @param {Object[]} arrToUpd - Array of fields to be updated in backend history(field, value, isDate)
 * @param {string} idx - index of the phase in the phases array to be checked
 * @param {string} id - Id of the element in the form
 * @returns {Object} Object with updated phases array(phases) and array of fields to be updated in backend history(arrToUpd).
 */
const checkStartDateChangeForwardDirection = ({
  phases,
  arrToUpd,
  idx,
  id,
}) => {
  if (
    idx > 0 &&
    moment
      .utc(phases[idx].startDate)
      .isSameOrBefore(moment.utc(phases[idx - 1].endDate))
  ) {
    phases[idx].startDate = moment
      .utc(phases[idx - 1].endDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
    updateArrToUpd({
      arrToUpd,
      field: `${id}[${idx}].startDate`,
      value: phases[idx].startDate,
      isDate: true,
    });
  }
  const daysToAdd = getDaysToAddToStartDate({ phases, idx });
  if (
    moment
      .utc(phases[idx].endDate)
      .diff(moment.utc(phases[idx].startDate), "days") < daysToAdd
  ) {
    phases[idx].endDate = moment
      .utc(phases[idx].startDate)
      .add(daysToAdd, "days")
      .format("YYYY-MM-DD");
    updateArrToUpd({
      arrToUpd,
      field: `${id}[${idx}].endDate`,
      value: phases[idx].endDate,
      isDate: true,
    });
  }
  if (idx < phases.length - 1) {
    return checkStartDateChangeForwardDirection({
      phases,
      arrToUpd,
      idx: idx + 1,
      id,
    });
  }
  return { phases, arrToUpd };
};

/** Function that checks and corrects the gaps of the next phases if the end date of the current phase is changed
 * @param {Object[]} phases - Array of phases ({startDate, endDate})
 * @param {Object[]} arrToUpd - Array of fields to be updated in backend history(field, value, isDate)
 * @param {string} idx - index of the phase in the phases array to be checked
 * @param {string} id - Id of the element in the form
 * @returns {Object} Object with updated phases array(phases) and array of fields to be updated in backend history(arrToUpd).
 */
const endDateChangeForwardDirection = ({ phases, arrToUpd, idx, id }) => {
  if (
    idx < phases.length - 1 &&
    moment
      .utc(phases[idx].endDate)
      .isSameOrAfter(moment.utc(phases[idx + 1].startDate))
  ) {
    phases[idx + 1].startDate = moment
      .utc(phases[idx].endDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
    updateArrToUpd({
      arrToUpd,
      field: `${id}[${idx + 1}].startDate`,
      value: phases[idx + 1].startDate,
      isDate: true,
    });
  }
  if (idx < phases.length - 1) {
    return checkStartDateChangeForwardDirection({
      phases,
      arrToUpd,
      idx: idx + 1,
      id,
    });
  }
  return { phases, arrToUpd };
};

/** Function that checks and corrects the gaps of the previous phases if the end date of the current phase is changed
 * @param {Object[]} phases - Array of phases ({startDate, endDate})
 * @param {Object[]} arrToUpd - Array of fields to be updated in backend history(field, value, isDate)
 * @param {string} idx - index of the phase in the phases array to be checked
 * @param {string} id - Id of the element in the form
 * @returns {Object} Object with updated phases array(phases) and array of fields to be updated in backend history(arrToUpd).
 */
const endDateChangeBackwardDirection = ({ phases, arrToUpd, idx, id }) => {
  if (
    moment
      .utc(phases[idx].endDate)
      .diff(moment.utc(phases[idx].startDate), "days") < 1
  ) {
    const daysToSubtract = getDaysToSubtractFromEndDate({ phases, idx });
    phases[idx].startDate = moment
      .utc(phases[idx].endDate)
      .subtract(daysToSubtract, "days")
      .format("YYYY-MM-DD");
    updateArrToUpd({
      arrToUpd,
      field: `${id}[${idx}].startDate`,
      value: phases[idx].startDate,
      isDate: true,
    });
  }
  if (idx > 0) {
    return checkStartDateChangeBackwardDirection({ phases, arrToUpd, idx, id });
  }
  return { phases, arrToUpd };
};

/** Function that checks and corrects the gaps between the phases if the start date of the phase is changed
 * @param {Object[]} phases - Array of phases ({startDate, endDate})
 * @param {Object[]} arrToUpd - Array of fields to be updated in backend history(field, value, isDate)
 * @param {string} idx - index of the phase in the phases array to be checked
 * @param {string} id - Id of the element in the form
 * @returns {Object} Object with updated phases array(phases) and array of fields to be updated in backend history(arrToUpd).
 */
const startDateChange = ({ phases, arrToUpd, idx, id }) => {
  if (
    moment
      .utc(phases[idx].startDate)
      .isSameOrAfter(moment.utc(phases[idx].endDate))
  ) {
    const daysToAdd = getDaysToAddToStartDate({ phases, idx });
    phases[idx].endDate = moment
      .utc(phases[idx].startDate)
      .add(daysToAdd, "days")
      .format("YYYY-MM-DD");
    updateArrToUpd({
      arrToUpd,
      field: `${id}[${idx}].endDate`,
      value: phases[idx].endDate,
      isDate: true,
    });
    if (idx < phases.length - 1) {
      checkStartDateChangeForwardDirection({
        phases,
        arrToUpd,
        idx: idx + 1,
        id,
      });
    }
  }
  checkStartDateChangeBackwardDirection({ phases, arrToUpd, idx, id });
  if (
    idx > 0 &&
    moment
      .utc(phases[idx].startDate)
      .diff(moment.utc(phases[idx - 1].endDate), "days") > 1
  ) {
    phases[idx - 1].endDate = moment
      .utc(phases[idx].startDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    updateArrToUpd({
      arrToUpd,
      field: `${id}[${idx - 1}].endDate`,
      value: phases[idx - 1].endDate,
      isDate: true,
    });
  }
  return { phases, arrToUpd };
};

/** Function that checks and corrects the gaps between the phases if the end date of the phase is changed
 * @param {Object[]} phases - Array of phases ({startDate, endDate})
 * @param {Object[]} arrToUpd - Array of fields to be updated in backend history(field, value, isDate)
 * @param {string} idx - index of the phase in the phases array to be checked
 * @param {string} id - Id of the element in the form
 * @returns {Object} Object with updated phases array(phases) and array of fields to be updated in backend history(arrToUpd).
 */
const endDateChange = ({ phases, arrToUpd, idx, id }) => {
  endDateChangeForwardDirection({ phases, arrToUpd, idx, id });
  endDateChangeBackwardDirection({ phases, arrToUpd, idx, id });
  if (
    idx < phases.length - 1 &&
    moment.utc(
      moment
        .utc(phases[idx + 1].startDate)
        .diff(moment(phases[idx].endDate), "days") > 1
    )
  ) {
    phases[idx + 1].startDate = moment
      .utc(phases[idx].endDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
    updateArrToUpd({
      arrToUpd,
      field: `${id}[${idx + 1}].startDate`,
      value: phases[idx + 1].startDate,
      isDate: true,
    });
  }
  return { phases, arrToUpd };
};

/** Function that sets the final min and max dates for start and end date of the phases based on the properties of the phases user has set in the form editor
 * @param {Object} id - Id of the element in the form
 * @param {Object[]} value - Array of phases ({startDate, endDate})
 * @param {Object} values - Object with all values from the form
 * @param {Object[]} phasesProps - Array of phases properties
 * @param {Object} typeProps - Type properties of the element
 * @returns {Object[]} Array of phases with min and max dates for start and end date
 */
const getMinMaxDates = ({ id, value, values, phasesProps, typeProps }) => {
  let toSet = [];
  phasesProps.forEach((item, index) => {
    let {
      startDateTooltip,
      endDateTooltip,
      endDateMaxDate,
      endDateMinDate,
      startDateMaxDate,
      startDateMinDate,
      startDateDaysAfterCurrentDate,
    } = item;

    const daysAfter = startDateDaysAfterCurrentDate;

    // If days quantity after current date is set, calculate min date for start and end date
    const startDateDaysAfterCurrentDateDate = startDateDaysAfterCurrentDate
      ? moment().add(startDateDaysAfterCurrentDate, "day")
      : null;
    const endDateDaysAfterCurrentDateDate = startDateDaysAfterCurrentDate
      ? moment().add(Number(startDateDaysAfterCurrentDate) + 1, "day")
      : null;

    const _startDateMinDate =
      startDateMinDate && startDateDaysAfterCurrentDateDate
        ? moment
            .max(startDateMinDate, startDateDaysAfterCurrentDateDate)
            .format("YYYY-MM-DD")
        : startDateMinDate ||
          startDateDaysAfterCurrentDateDate?.format("YYYY-MM-DD");

    const _endDateMinDate =
      endDateMinDate && endDateDaysAfterCurrentDateDate
        ? moment
            .max(endDateMinDate, endDateDaysAfterCurrentDateDate)
            .format("YYYY-MM-DD")
        : endDateMinDate ||
          endDateDaysAfterCurrentDateDate?.format("YYYY-MM-DD");

    const startDate = value[index]?.startDate;
    const endDate = value[index]?.endDate;

    let endDateMin = _endDateMinDate;
    let startDateMin = _startDateMinDate;
    let startDateMax = startDateMaxDate;
    let endDateMax = endDateMaxDate;
    const array = ["startDate", "endDate"];
    array.forEach((key) => {
      const maxElementId = typeProps[key + "MaxDateElement"];
      const momentMaxDate = moment.utc(values[maxElementId]);
      const minElementId = typeProps[key + "MinDateElement"];
      const momentMinDate = moment.utc(values[minElementId]);
      const date = moment(key === "endDate" ? endDate : startDate);
      const subId =
        key === "endDate"
          ? id + `[${index}].endDate`
          : id + `[${index}].startDate`;

      if (minElementId && values[minElementId]) {
        if (key === "endDate") {
          endDateMin = momentMinDate.format("YYYY-MM-DD");
        } else {
          startDateMin = momentMinDate.format("YYYY-MM-DD");
        }
        if (date.isValid() && momentMinDate.isAfter(date)) {
          if (
            !(momentMinDate.isValid() && momentMaxDate.isBefore(momentMinDate))
          ) {
            value[index][key] = momentMinDate.format("YYYY-MM-DD");
          }
        }
      }
      if (maxElementId && values[maxElementId]) {
        if (key === "endDate") {
          endDateMax = momentMaxDate.format("YYYY-MM-DD");
        } else {
          startDateMax = momentMaxDate.format("YYYY-MM-DD");
        }
        if (date.isValid() && momentMaxDate.isBefore(date)) {
          if (
            !(momentMinDate.isValid() && momentMinDate.isAfter(momentMaxDate))
          ) {
            value[index][key] = momentMaxDate.format("YYYY-MM-DD");
          }
        }
      }
    });
    toSet.push({
      ...value[index],
      startDateMinDate: startDateMin,
      endDateMinDate: endDateMin,
      startDateMaxDate: startDateMax,
      endDateMaxDate: endDateMax,
    });
  });
  return toSet;
};

/** Function that sets min and max dates for start and end date of the phases based on the final values of min and max dates
 * @param {Object[]} phases - Array of phases ({startDate, endDate})
 * @returns {Object[]} Array of phases with min and max dates for start and end date values
 */
const completeMinMaxDates = (phases) => {
  // debugger
  const updPhases = [];
  for (let i = 0; i < phases.length; i++) {
    const updPhase = { ...phases[i] };
    updPhases.push(updPhase);
  }

  for (let i = 0; i < updPhases.length; i++) {
    if (i > 0 && updPhases[i - 1].endDateMinDate) {
      updPhases[i].startDateMinDate = updPhases[i].startDateMinDate
        ? moment
            .max(
              moment.utc(updPhases[i - 1].endDateMinDate).add(1, "days"),
              moment.utc(updPhases[i].startDateMinDate)
            )
            .format("YYYY-MM-DD")
        : moment
            .utc(updPhases[i - 1].endDateMinDate)
            .add(1, "days")
            .format("YYYY-MM-DD");
    }
    if (updPhases[i].startDateMinDate) {
      updPhases[i].endDateMinDate = updPhases[i].endDateMinDate
        ? moment
            .max(
              moment.utc(updPhases[i].startDateMinDate).add(1, "days"),
              moment.utc(updPhases[i].endDateMinDate)
            )
            .format("YYYY-MM-DD")
        : moment
            .utc(updPhases[i].startDateMinDate)
            .add(1, "days")
            .format("YYYY-MM-DD");
    }
    if (i < updPhases.length - 1 && updPhases[i + 1].startDateMinDate) {
      updPhases[i].endDateMinDate = updPhases[i].endDateMinDate
        ? moment
            .max(
              moment.utc(updPhases[i + 1].startDateMinDate).subtract(1, "days"),
              moment.utc(updPhases[i].endDateMinDate)
            )
            .format("YYYY-MM-DD")
        : moment
            .utc(updPhases[i + 1].startDateMinDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
    }
    if (i < updPhases.length - 1 && updPhases[i + 1].startDateMaxDate) {
      updPhases[i].endDateMaxDate = updPhases[i].endDateMaxDate
        ? moment
            .min(
              moment.utc(updPhases[i + 1].startDateMaxDate).subtract(1, "days"),
              moment.utc(updPhases[i].endDateMaxDate)
            )
            .format("YYYY-MM-DD")
        : moment
            .utc(updPhases[i + 1].startDateMaxDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
    }
  }
  for (let i = updPhases.length - 1; i >= 0; i--) {
    if (i < updPhases.length - 1 && updPhases[i + 1].startDateMaxDate) {
      updPhases[i].endDateMaxDate = updPhases[i].endDateMaxDate
        ? moment
            .min(
              moment.utc(updPhases[i + 1].startDateMaxDate).subtract(1, "days"),
              moment.utc(updPhases[i].endDateMaxDate)
            )
            .format("YYYY-MM-DD")
        : moment
            .utc(updPhases[i + 1].startDateMaxDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
    }
    if (updPhases[i].endDateMaxDate) {
      updPhases[i].startDateMaxDate = phases[i].startDateMaxDate
        ? moment
            .min(
              moment.utc(updPhases[i].endDateMaxDate).subtract(1, "days"),
              moment.utc(updPhases[i].startDateMaxDate)
            )
            .format("YYYY-MM-DD")
        : moment
            .utc(updPhases[i].endDateMaxDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
    }
  }
  return updPhases;
};

const dynamicStartDateChange = withDynamicFields(startDateChange);
const dynamicEndDateChange = withDynamicFields(endDateChange);

export {
  completeMinMaxDates,
  dynamicEndDateChange as endDateChange,
  getMinMaxDates,
  dynamicStartDateChange as startDateChange,
};
