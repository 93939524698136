import { t } from "@lingui/macro";
import { Text, View } from "@react-pdf/renderer";
import { ImprovedHtml } from "app/views/common-components/ImprovedHtml";
import { myI18n } from "translation/I18nConnectedProvider";
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily,
} from "../pdf-components/FormPdf";
import { purgeInvalidPDFSignFromString } from "../pdf-components/formPdfUtils";
import { pdfDefaultFontSize } from "./Common";

type FormElementTitlePDFProps = {
  index: number;
  parseAsHtml?: boolean;
  parseHelpTextAsHtml?: boolean;
  required?: boolean;
  showNumering?: boolean;
  title: string;
  language: string;
  helpText?: React.ReactNode;
};

export const FormElementTitlePDF = ({
  index,
  parseAsHtml,
  parseHelpTextAsHtml,
  required,
  helpText,
  showNumering,
  language,
  title,
}: FormElementTitlePDFProps) => {
  return (
    <>
      <Text
        style={{
          fontSize: 10,
          fontWeight: "bold",
          marginBottom: 8,
          fontFamily:
            language === "iu-cans"
              ? inuktitutFormPdfFontFamily
              : defaultFormPdfFontFamily,
        }}
      >
        {showNumering && index + ". "}
        {parseAsHtml ? (
          <ImprovedHtml
            collapse={false}
            style={{
              fontSize: pdfDefaultFontSize,
              fontFamily:
                language === "iu-cans"
                  ? inuktitutFormPdfFontFamily
                  : defaultFormPdfFontFamily,
            }}
          >
            {title}
          </ImprovedHtml>
        ) : (
          purgeInvalidPDFSignFromString(title)
        )}
        {required && ` (${myI18n._(t`Required`)})`}
      </Text>
      {helpText ? (
        <View>
          <Text
            style={{
              fontSize: pdfDefaultFontSize - 2,
              fontStyle: "italic",
              marginBottom: 8,
              fontFamily:
                language === "iu-cans"
                  ? inuktitutFormPdfFontFamily
                  : defaultFormPdfFontFamily,
            }}
          >
            {parseHelpTextAsHtml ? (
              <ImprovedHtml
                collapse={false}
                style={{
                  fontSize: pdfDefaultFontSize - 2,
                  fontFamily:
                    language === "iu-cans"
                      ? inuktitutFormPdfFontFamily
                      : defaultFormPdfFontFamily,
                }}
              >
                {String(helpText)}
              </ImprovedHtml>
            ) : (
              helpText
            )}
          </Text>
        </View>
      ) : (
        <View />
      )}
    </>
  );
};
