import { t, Trans } from "@lingui/macro";
import { Grid, Icon, IconButton, Tooltip } from "@material-ui/core";
import sfOauthConfig from "app/services/sfAuth/sfAuthConfig";
import {
  CBTI_RECORD_TYPE,
  opportunitiesStages,
  STF_LP_RECORD_TYPE,
  STF_SIP_RECORD_TYPE,
} from "app/services/sfAuth/sfData/sfOpportunity";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { myI18n } from "translation/I18nConnectedProvider";
import { getProperFormForOpportunity } from "./ApplicationUtils";
import ContactProgramManager from "./ContactProgramManager";

export const ApplicationActions = ({
  formId,
  hasContract,
  fundingStream,
  fundingStreamApplicationForm,
  isPrequalification,
  oldFormVersion,
  grantForm,
  editAccess,
  appToPrint,
  pm,
  id,
  recordType,
  wasHardCoded,
  stageName,
  handleShowPrint,
  handleDelete,
  rolePermissions = {},
  disableDeleteButton,
  ...props
}: {
  formId: string;
  printForm: any;
  hasContract: boolean;
  fundingStream: any;
  fundingStreamApplicationForm: string;
  grantForm: string;
  oldFormVersion: string;
  editAccess: true;
  appToPrint: any;
  id: string;
  pm: any;
  isPrequalification: boolean;
  recordType: any;
  stageName: string;
  handleShowPrint: any;
  wasHardCoded: boolean;
  handleDelete: any;
  rolePermissions: {
    associate?: boolean;
    manager?: boolean;
    contributor?: boolean;
    partner?: boolean;
  };
  disableDeleteButton: boolean;
}) => {
  //@ts-ignore
  const fundingStreams = useSelector((state) => state.fundingStreams?.streams);
  const fundingStreamConfig = fundingStreams.find(
    (fs) => fs.id === fundingStream
  );

  const user = useSelector<any>((state) => state.user);
  const history = useHistory();
  const simulateInternal = useSelector(
    (state: any) => state?.testingOptions?.simulateInternal
  );
  const isInternal = simulateInternal || sfOauthConfig.isInternal;

  const isOldFund = [
    CBTI_RECORD_TYPE,
    STF_LP_RECORD_TYPE,
    STF_SIP_RECORD_TYPE,
  ].includes(recordType);

  const inEditableStage = [
    opportunitiesStages.IN_PROGRESS,
    opportunitiesStages.MORE_INFO_REQUIERED,
  ].includes(stageName);

  const shouldUseCurrentForm = stageName === opportunitiesStages.IN_PROGRESS;

  const shouldShowOldForm = isOldFund && !grantForm && !shouldUseCurrentForm;

  const formHasChanged =
    !shouldShowOldForm &&
    fundingStreamApplicationForm &&
    grantForm !== fundingStreamApplicationForm;

  let teamMember;

  appToPrint &&
    appToPrint.teamMembers.some((member) => {
      // @ts-ignore
      if (member.UserId === user.userId) {
        teamMember = member;
      }
      // @ts-ignore
      return member.UserId === user.userId;
    });
  const per = {
    isTeamMember: Boolean(teamMember),
    opportunityAccessLevel: teamMember?.OpportunityAccessLevel || "Edit",
    teamMember,
    ...rolePermissions,
  };

  const readOnly = !editAccess;

  const currentForm = getProperFormForOpportunity({
    config: fundingStreamConfig,
    stage: stageName,
    grantForm,
    oldFormVersionField: oldFormVersion,
    wasHardCoded,
  });

  const printForm = getProperFormForOpportunity({
    config: fundingStreamConfig,
    printView: true,
    stage: stageName,
    grantForm,
    oldFormVersionField: oldFormVersion,
    wasHardCoded,
  });

  const goToForm = () => {
    if (isPrequalification) {
      history.push(`/grants/prequalification/${id}`);
      return;
    }
    if (currentForm) {
      history.push(`/grants/grant-application/${id}`);
    } else {
      history.push(`/grants/Application/${id}`);
    }
  };

  const viewButton = (
    <Tooltip title={myI18n._(t`View`)}>
      <IconButton
        disabled={Boolean(per.associate && !per.isTeamMember)}
        type="button"
        onClick={goToForm}
      >
        <Icon color="inherit">description</Icon>
      </IconButton>
    </Tooltip>
  );

  let editButton = (
    <Tooltip title={myI18n._(t`Edit`)}>
      <IconButton
        disabled={Boolean(
          !per.manager && !per.contributor && !per.partner && !per.isTeamMember
        )}
        type="button"
        onClick={goToForm}
      >
        <Icon color="inherit">edit</Icon>
      </IconButton>
    </Tooltip>
  );

  if (
    formHasChanged &&
    !isPrequalification &&
    stageName === opportunitiesStages.IN_PROGRESS
  ) {
    editButton = (
      <Grid
        container
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
        style={{
          width: 110,
          backgroundColor: "rgb(255, 244, 229)",
          padding: 6,
        }}
      >
        <Tooltip title={<Trans>APPLICATIONS_LIST_CHANGED_FORM_ALERT</Trans>}>
          <div
            style={{
              padding: 12,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon
              style={{
                color: "#ff9800",
              }}
            >
              warning
            </Icon>
          </div>
        </Tooltip>
        {editButton}
      </Grid>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {pm && (
        <ContactProgramManager
          fundingStreamId={fundingStream}
          recordType={recordType}
          active={pm.isPm}
          opportunityId={isPrequalification ? null : id}
          prequalificationId={isPrequalification ? id : null}
        />
      )}
      {/* @ts-ignore */}
      {((isInternal && pm.assignedManager === user.userId) || hasContract) &&
        !isPrequalification && (
          <Tooltip title={myI18n._(t`Go to contract`)}>
            <IconButton
              onClick={(e) => {
                history.push(`/internal/contract/${id}`);
              }}
            >
              <Icon>receipt_long</Icon>
            </IconButton>
          </Tooltip>
        )}
      {!inEditableStage || readOnly ? viewButton : editButton}

      {(printForm || isOldFund) && (
        <Tooltip title={<Trans>Print</Trans>}>
          <span>
            <IconButton
              onClick={() => {
                handleShowPrint({
                  useOldForm: !printForm,
                  id,
                  fundingStream,
                  appToPrint,
                  printForm,
                });
              }}
            >
              <Icon className="material-icons MuiIcon-root">print</Icon>
            </IconButton>
          </span>
        </Tooltip>
      )}

      {inEditableStage && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title={<Trans>Delete</Trans>}>
            <span>
              <IconButton
                style={{ opacity: disableDeleteButton ? 0.5 : 1 }}
                disabled={!per.manager || disableDeleteButton}
                onClick={() => handleDelete({ id, isPrequalification })}
              >
                <Icon style={{ color: per.manager ? "red" : "initial" }}>
                  delete
                </Icon>
              </IconButton>
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
