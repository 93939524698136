import { opportunitiesStages } from 'app/services/sfAuth/sfData/sfOpportunity'

export const getProperFormForOpportunity = ({
  config,
  stage,
  grantForm,
  wasHardCoded,
  oldFormVersionField,
  printView = false
}) => {
  const shouldUseCurrentForm =
    opportunitiesStages.IN_PROGRESS === stage

  const savedForm = grantForm || oldFormVersionField
  let formId = shouldUseCurrentForm ? config?.form : savedForm

  if (wasHardCoded && !grantForm && !savedForm) {
    if (printView) {
      formId = config?.applicationViewForHardcoded
    } else if (opportunitiesStages.MORE_INFO_REQUIERED === stage) {
      return null
    }
  }

  return formId
}
