import { CKEditor as CKEditor5 } from "@ckeditor/ckeditor5-react";
import { add } from "@ckeditor/ckeditor5-utils/src/translation-service";
import {
  AccessibilityHelp,
  Alignment,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Bold,
  ClassicEditor,
  Essentials,
  GeneralHtmlSupport,
  Heading,
  Italic,
  Link,
  List,
  Paragraph,
  SelectAll,
  Table,
  TableToolbar,
  Undo,
  WordCount,
} from "ckeditor5";
import "ckeditor5/build/translations/en-gb.js";
import "ckeditor5/build/translations/fr.js";
import { useEffect, useRef, useState } from "react";
import "../CKEditor/ckeditor5.css";
import "./iu-cans";

add("fr", {
  Bold: "Gras",
  "Bold text": "Texte en gras",
  "Create link": "Créer un lien",
  Italic: "Italique",
  "Italic text": "Texte en italique",
  "Text alignment": "Alignement du texte",
  "Align left": "Aligner à gauche",
  "Align right": "Aligner à droite",
  "Align center": "Aligner au centre",
  Justify: "Justifier",
  "Bulleted List": "Liste à puces",
  "Numbered List": "Liste numérotée",
  Link: "Lien",
  Paragraph: "Paragraphe",
  Heading: "Titre",
  "Keystrokes that can be used in a list":
    "Raccourcis clavier utilisables dans une liste",
  "Decrease list item indent": "Diminuer le retrait de l'élément de la liste",
  "Increase list item indent": "Augmenter le retrait de l'élément de la liste",
  "Move out of a link": "Sortir d'un lien",
  "Link URL": "URL du lien",
  Dowloadable: "Téléchargeable",
  "Below, you can find a list of keyboard shortcuts that can be used in the editor.":
    "Ci-dessous, vous trouverez une liste des raccourcis clavier qui peuvent être utilisés dans l'éditeur.",
  "Content editing keystrokes": "Raccourcis clavier pour l'édition de contenu",
  "These keyboard shortcuts allow for quick access to content editing features.":
    "Ces raccourcis clavier permettent un accès rapide aux fonctionnalités d'édition de contenu.",
});

add("iu-cans", {
  "Bold text": "ᑕᑕᓂᕈᑎᑦ ᐃᒡᓗᐃᑦ",
  "Create link": "ᓄᕙᐅᓂᖅ ᐱᕈᖅᑐᖅ",
  "Italic text": "ᑎᒃᑎᒋᑕᕈᑎᑦ ᐃᒡᓗᐃᑦ",
  "Keystrokes that can be used in a list": "ᑎᒃᑯᓕᐅᕈᑎᑦ ᐊᑐᕐᑐᐃᖏᓐᓄᑦ ᑎᒥᒃ ",
  "Decrease list item indent": "ᑎᑭᕋᐅᕋᓱᐊᕈᑎᑦ ᐊᑐᕐᑐᐃᖏᓐᓄᑦ",
  "Increase list item indent": "ᐊᐅᓯᕐᕋᓱᐊᕈᑎᑦ ᐊᑐᕐᑐᐃᖏᓐᓄᑦ",
  "Move out of a link": "ᐊᒻᒪᓗ ᐃᑭᕐᕈᖅ ᐱᕈᖅᑐᒥᑦ",
  "Link URL": "ᓄᕙᐅᓂᖅ URL",
  Dowloadable: "ᑐᒃᑐᐊᓘᑦᑐᖅ",
  "Below, you can find a list of keyboard shortcuts that can be used in the editor.":
    "ᐊᒻᒪᓗ ᐅᖃᖅᑐᖅ ᑎᒃᑯᓕᐅᕈᑎᑦ ᑎᓯᕋᑦᓴᐅᔪᓂᒃ ᐊᑐᕐᑐᐃᖏᓐᓄᑦ ᓇᖏᑦᑐᐃᓐᓇᕈᓂᒃ ᑐᕌᖅᑐᐃᓐᓇᕗᖅ ᑐᕌᖅᑐᒥᒃ",
  "Content editing keystrokes": "ᓇᖏᑦᑐᐃᓂᒃ ᑐᕌᖅᑐᐃᓐᓇᕆᔭᑦ ᐊᑐᕐᑐᐃᖏᓐᓄᑦ",
  "These keyboard shortcuts allow for quick access to content editing features.":
    "ᑎᒃᑯᓕᐅᕈᑎᑦ ᐊᑐᕐᑐᐃᖏᓐᓄᑦ ᑐᕌᖅᑐᐃᓐᓇᕆᐊᑦᓴᓕᐅᕐᑐᓂᒃ",
  "Copy selected content": "ᓯᕗᓂᒃ ᐱᒋᔭᐅᔪᒥᒃ ᓂᐅᕕᐅᑉᐸᓗᖕᓂᖅ",
  "Insert a hard break (a new paragraph)": "ᑎᑎᕋᖕᓄᑦ ᐱᓯᕆᐅᑎᒋᔭᐅᔪᒃ (ᐅᓇ ᐃᓕᖅᑯᓯᕋᑦᑐᓂᒃ)",
  "Insert a soft break (a <br> element)": "ᓯᕗᐃᑦ ᐱᓯᕆᐅᑎᒋᔭᐅᔪᒃ (ᑎᑎᕋᕋᓂᖕᓄᑦ)",
  "Paste content": "ᐱᔭᐅᔭᒃᓴᖅ ᓂᐅᕕᐅᑉᐸᖅᑐᖅ",
  "Paste content as plain text": "ᑕᑯᕐᓴᖅᑐᓯᒪᑦᑐᖅ ᐅᑯᓯᕋᔾᔭᕆᔭᐅᔪᒥᒃ",
  "Keystrokes that can be used when a widget is selected (for example: image, table, etc.)":
    "ᓯᓚᑦᑎᒋᔭᐅᓂᖅ ᐊᑎᒍᑎᑦ ᑭᐱᔭᐅᓂᖅ (ᓇᓗᓇᐅᑎᒥᑦ: ᐅᑯᓯᕋᑦᑐᑦ, ᐅᒪᔪᒥᑦ, ᖃᑎᒋᓂᒃ, ᑭᑎᔭᐅᔾᔪᑦ, ᐊᑦᑕᓇᐃᓐᓇᒧᑦ)",
  "Insert a new paragraph directly after a widget": "ᐅᑕᓗᓂᖅ ᐱᔭᐅᑎᒋᔭᐅᓂᖅ ᑎᑎᕋᖕᓄᑦ",
  "Insert a new paragraph directly before a widget": "ᐃᓕᕆᖕᒥᑦ ᑎᑎᕋᖕᓄᑦ ᐱᓯᕆᐅᑎᒋᔭᐅᓂᖅ",
  "Move focus from an editable area back to the parent widget":
    "ᓄᑖᓐᓇᐅᔪᓂᖅ ᓂᐅᕕᐅᑉᐸᖅᑐᖅ ᑭᐱᔭᐅᓯᐅᔪᒥᓂᒃ",
  "Move the caret to allow typing directly after a widget":
    "ᐊᒻᒪ ᐊᑎᓕᓐᓄᑦ ᓄᑖᒥᐅᓗᒃ ᑎᑎᕋᓐᓂᕈᓗᖅ",
  "Move the caret to allow typing directly before a widget":
    "ᐊᒻᒪ ᐊᑎᓕᓐᓄᑦ ᑎᑎᕋᕋᓄᑦ ᐱᓯᕆᓐᓇᕐᓗᖅ",
  "User interface and content navigation keystrokes": "ᐱᔭᕇᖃᕐᓴᒃᑐᑦ ᑎᑎᕋᕐᓯᐅᓂᖅ",
  "Use the following keystrokes for more efficient navigation in the CKEditor 5 user interface.":
    "ᑭᐱᔭᐅᓂᖅ ᓄᓇᓯᐅᑦᓴᓂᑦ ᐊᓂᔪᓂᑦ ᐅᓇ ᑎᑎᕋᓯᐅᓂᖅ",
  "Close contextual balloons, dropdowns, and dialogs":
    "ᐅᖅᑯᓂᖅᑐᖅ ᐃᑎᒍᓄᑦ ᐊᓕᐅᔪᖅᓯᕐᑐᖅ, ᐱᒃᓴᓕᐅᔪᑦᓴᓂᑦ",
  "Execute the currently focused button. Executing buttons that interact with the editor content moves the focus back to the content.":
    "ᐊᑐᐃᓈᖅᑐᖅ ᐊᑎᒍᑕᐅᓂᖅ. ᐊᑐᐃᓈᖅᑐᖅ ᐱᓯᕆᔭᐅᔪᖅᓯᕋᖅ ᐅᑭᐅᑕᓂᖅ",
  "Move focus between form fields (inputs, buttons, etc.)": "ᑎᑎᕋᓯᐅᓂᖅ ᐊᑎᒍᑕᒻᒪᕆᓂᖅ",
  "Move focus in and out of an active dialog window":
    "ᑎᑎᕋᓯᐅᓂᖅ ᐃᑎᓂᖅᓯᓂᖅ ᐱᓕᕆᐊᓯᒪᖕᓄᑦ",
  "Move focus to the menu bar, navigate between menu bars":
    "ᑎᑎᕋᓯᐅᓂᖅ ᐊᑎᒍᑕᒃᓴᓂᒃ ᓂᐅᕕᐅᑉᐸᖅᑐᖅ",
  "Move focus to the toolbar, navigate between toolbars":
    "ᐊᑎᒍᑕᒻᒪᕆᓂᖅ ᓂᐅᕕᐅᑉᐸᖅᑐᖅ ᐊᑐᐃᓂᖅᑎᒋᓯᕋᓗᖅ",
  "Navigate through the toolbar or menu bar": "ᑎᑎᕋᕕᔭᖅᓯᓂᖅ ᐊᑐᐃᓈᖅᑐᖅ",
  "Open the accessibility help dialog": "ᑕᕝᕙᓂᖅ ᐱᓕᕆᔭᕐᔪᖅ",
});

const baseHeadingOptions = [
  { model: "paragraph", class: "ck-heading_paragraph" },
  { model: "heading1", view: "h1", class: "ck-heading_heading1" },
  { model: "heading2", view: "h2", class: "ck-heading_heading2" },
  { model: "heading3", view: "h3", class: "ck-heading_heading3" },
  { model: "heading4", view: "h4", class: "ck-heading_heading4" },
  { model: "heading5", view: "h5", class: "ck-heading_heading5" },
  { model: "heading6", view: "h6", class: "ck-heading_heading6" },
];

const headingTitles = {
  en: [
    "Paragraph",
    "Heading 1",
    "Heading 2",
    "Heading 3",
    "Heading 4",
    "Heading 5",
    "Heading 6",
  ],
  fr: [
    "Paragraphe",
    "Titre 1",
    "Titre 2",
    "Titre 3",
    "Titre 4",
    "Titre 5",
    "Titre 6",
  ],
  iu: ["ᐱᕙᓂᖅ", "ᑭᐅᔭᖅ 1", "ᑭᐅᔭᖅ 2", "ᑭᐅᔭᖅ 3", "ᑭᐅᔭᖅ 4", "ᑭᐅᔭᖅ 5", "ᑭᐅᔭᖅ 6"],
};

const getHeadingOptions = (langVersion = "en") => {
  const langKey = langVersion.startsWith("fr")
    ? "fr"
    : langVersion.startsWith("iu")
    ? "iu"
    : "en";
  return baseHeadingOptions.map((heading, index) => ({
    ...heading,
    title: headingTitles[langKey][index],
  }));
};

// this items are used in the toolbar when the allowAddingTables is true
const tableToolbarItems = [
  "undo",
  "redo",
  "|",
  "selectAll",
  "|",
  "heading",
  "|",
  "bold",
  "italic",
  "|",
  "link",
  "|",
  "bulletedList",
  "numberedList",
  "|",
  "alignment",
  "|",
  "insertTable",
  "|",
  "accessibilityHelp",
];

export const baseCKEeditorConfig = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "selectAll",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "link",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "alignment",
      "|",
      "accessibilityHelp",
    ],
    shouldNotGroupWhenFull: false,
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    AutoLink,
    Autosave,
    BalloonToolbar,
    Bold,
    Essentials,
    GeneralHtmlSupport,
    Heading,
    Italic,
    Link,
    Paragraph,
    SelectAll,
    List,
    Undo,
    WordCount,
  ],
  balloonToolbar: ["bold", "italic", "|", "link"],
  htmlSupport: {
    allow: [
      {
        name: /^.*$/,
        styles: true,
        attributes: true,
        classes: true,
      },
    ],
  },
  initialData: "",
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: "https://",
    decorators: {
      toggleDownloadable: {
        mode: "manual",
        label: "Downloadable",
        attributes: {
          download: "file",
        },
      },
    },
  },
  menuBar: {
    isVisible: true,
  },
  placeholder: "",
};

export const CKEditor = ({
  editorRef,
  value,
  disabled,
  maxCharacters,
  wordLimit,
  setValue,
  langVersion,
  allowAddingTables = false,
  additionalConfig, //this and the rest of the props are provided when CKEdior is used with formik
  data,
  onChangeWithFormik,
  onBlur,
  onFocus,
  ...props
}) => {
  const localRef = useRef(null);
  const ref = editorRef || localRef;
  const [timer, setTimer] = useState(null);
  const [inputValue, setInputValue] = useState("");
  //const [stats, setStats] = useState({ words: 0, characters: 0 })
  const [editorConfig, setEditorConfig] = useState(baseCKEeditorConfig);
  const [editorLangVersion, setEditorLangVersion] = useState(
    langVersion === "fr" ? langVersion : "en-gb"
  );

  useEffect(() => {
    const newLangVersion = langVersion === "en" ? "en-gb" : langVersion;

    setEditorLangVersion(newLangVersion);
    setEditorConfig((prevConfig) => ({
      ...prevConfig,
      language: newLangVersion,
      heading: {
        options: getHeadingOptions(newLangVersion || "en"),
      },
    }));
  }, [langVersion]);

  useEffect(() => {
    return () => {
      if (ref.current) {
        ref.current.editor?.destroy().catch((error) => {
          console.error("Failed to destroy CKEditor instance:", error);
        });
      }
    };
  }, [editorLangVersion]);

  const inputChanged = () => {
    if (ref.current && ref.current.editor) {
      const newValue = ref.current.editor.getData();
      const wordCount = ref.current.editor.plugins.get("WordCount").words;
      const charCount = ref.current.editor.plugins.get("WordCount").characters;
      let error = false;

      if (maxCharacters && maxCharacters < charCount) {
        error = true;
      }

      if (wordLimit && wordCount > wordLimit) {
        error = true;
      }

      setInputValue(newValue);
      clearTimeout(timer);
      if (error) {
        if (newValue !== inputValue) {
          ref.current.editor.setData(inputValue);
        }
      } else {
        const newTimer = setTimeout(() => {
          setValue(newValue);
        }, 700);
        setTimer(newTimer);
      }
    }
  };

  return (
    <div
      key={editorLangVersion}
      className={langVersion === "iu-cans" ? "pigiarniq-font" : ""}
    >
      <CKEditor5
        ref={ref}
        editor={ClassicEditor}
        config={{
          ...editorConfig,
          // wordCount: {
          //   onUpdate: (stats) => {
          //     setStats(stats)
          //   },
          // },
          language: editorLangVersion || "en",
          items: [
            ...(editorConfig?.toolbar?.items || []),
            ...(allowAddingTables ? tableToolbarItems : []),
          ],
          plugins: [
            ...(editorConfig?.plugins || []),
            ...(allowAddingTables ? [Table, TableToolbar] : []),
          ],
          ...(allowAddingTables && {
            table: {
              contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
            },
          }),
          ...additionalConfig,
        }}
        disabled={disabled}
        onReady={(editor) => {
          if (value && !onChangeWithFormik) {
            setInputValue(value);
            editor.setData(value);
          }
        }}
        onChange={(evt, editor) => {
          if (onChangeWithFormik) {
            onChangeWithFormik();
          } else {
            inputChanged();
          }
        }}
        data={data}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  );
};
