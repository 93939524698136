import { t, Trans } from "@lingui/macro";
import { defaultFormPdfFontFamily } from "app/views/forms/pdf-components/FormPdf";
import { myI18n } from "translation/I18nConnectedProvider";

const styles = {
  text: {
    fontSize: 14,
    fontFamily: defaultFormPdfFontFamily,
  },
  question: {
    fontSize: 14,
    fontFamily: defaultFormPdfFontFamily,
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 4,
  },
  line: {
    marginBottom: 8,
  },
  title: {
    fontSize: 20,
    fontFamily: defaultFormPdfFontFamily,
    marginBottom: 8,
    marginLeft: 8,
  },
};

/**
 * Component to render FormCenzusDivision element in print preview
 * @param {object} value - value of FormCenzusDivision element
 * @param {string} title - title of FormCenzusDivision element
 * @param {object} props - props of FormCenzusDivision element
 */
export const FormCensusDivisionPdfEditor = ({
  value,
  title,
  langVersion,
  ...props
}) => {
  const connectedValues = props?.typeProps?.connectedTo?.[0];

  const connectedObjectId = connectedValues?.connectedObject;
  const connectedObject = props?.objectsConnected?.find(
    (obj) => obj.identId === connectedObjectId
  );
  const objName = connectedObject?.name;

  const census = {
    scopeType: (
      <>
        <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_PLACEHOLDER</Trans>
        {connectedValues?.regionalScope ? (
          <>
            {" "}
            for <i>{connectedValues?.regionalScope?.name}</i> from{" "}
            <i>{objName}</i>
          </>
        ) : (
          ""
        )}
      </>
    ),
    muncipality: (
      <>
        <Trans>CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY_PLACEHOLDER</Trans>
        {connectedValues?.cities ? (
          <>
            {" "}
            for <i>{connectedValues?.cities?.name}</i> from <i>{objName}</i>
          </>
        ) : (
          ""
        )}
      </>
    ),
    provinces: (
      <>
        <Trans>CENZUS_DIVISION_ELEMENT_PROVINCES_PLACEHOLDER</Trans>
        {connectedValues?.provinces ? (
          <>
            {" "}
            for <i>{connectedValues?.provinces?.name}</i> from <i>{objName}</i>
          </>
        ) : (
          ""
        )}
      </>
    ),
    selected: (
      <>
        <Trans>CENZUS_DIVISION_ELEMENT_SELECTED_PLACEHOLDER</Trans>
        {connectedValues?.cenzusDivision ? (
          <>
            {" "}
            for <i>{connectedValues?.cenzusDivision?.name}</i> from{" "}
            <i>{objName}</i>
          </>
        ) : (
          ""
        )}
      </>
    ),
    pilotPhase: (
      <>
        <Trans>CENZUS_DIVISION_ELEMENT_PILOT_PHASE_QUESTION_PLACEHOLDER</Trans>
        {connectedValues?.pilotPhase ? (
          <>
            {" "}
            for <i>{connectedValues?.pilotPhase?.name}</i> from <i>{objName}</i>
          </>
        ) : (
          ""
        )}
      </>
    ),
    pilotPhaseScopeType: (
      <>
        <Trans>
          CENZUS_DIVISION_ELEMENT_SELECT_PILOT_PHASE_SCOPE_PLACEHOLDER
        </Trans>
        {connectedValues?.pilotPhaseScopeType ? (
          <>
            {" "}
            for <i>{connectedValues?.pilotPhaseScopeType?.name}</i> from{" "}
            <i>{objName}</i>
          </>
        ) : (
          ""
        )}
      </>
    ),
    pilotPhaseMuncipality: (
      <>
        <Trans>
          CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY_PILOT_PHASE_PLACEHOLDER
        </Trans>
        {connectedValues?.cities ? (
          <>
            {" "}
            for <i>{connectedValues?.cities?.name}</i> from <i>{objName}</i>
          </>
        ) : (
          ""
        )}
      </>
    ),
    pilotPhaseProvinces: (
      <>
        <Trans>CENZUS_DIVISION_ELEMENT_PROVINCES_PILOT_PHASE_PLACEHOLDER</Trans>
        {connectedValues?.provinces ? (
          <>
            {" "}
            for <i>{connectedValues?.provinces?.name}</i> from <i>{objName}</i>
          </>
        ) : (
          ""
        )}
      </>
    ),
    pilotPhaseSelected: (
      <>
        <Trans>CENZUS_DIVISION_ELEMENT_PILOT_PHASE_SELECTED_PLACEHOLDER</Trans>
        {connectedValues?.cenzusDivision ? (
          <>
            {" "}
            for <i>{connectedValues?.cenzusDivision?.name}</i> from{" "}
            <i>{objName}</i>
          </>
        ) : (
          ""
        )}
      </>
    ),
    impactsProvincialNorth: (
      <>
        <Trans>
          CENZUS_DIVISION_ELEMENT_IMPACTS_PROVINCIAL_NORTH_PLACEHOLDER
        </Trans>
        {connectedValues?.impactsProvincialNorth ? (
          <>
            {" "}
            for <i>{connectedValues?.impactsProvincialNorth?.name}</i> from{" "}
            <i>{objName}</i>
          </>
        ) : (
          ""
        )}
      </>
    ),
  };

  return (
    <div style={styles.line}>
      {title && <div style={styles.title}>{title || ""}</div>}
      <div style={styles.line}>
        <div style={styles.line}>
          <div style={styles.question}>
            {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE`)}
          </div>
          <div style={styles.text}>{census.scopeType}</div>
        </div>
        {census.muncipality ? (
          <div style={styles.line}>
            <div style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY`)}
            </div>
            <div style={styles.text}>{census.muncipality}</div>
          </div>
        ) : null}
        {census.provinces ? (
          <div style={styles.line}>
            <div style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_PROVINCES`)}
            </div>
            <div style={styles.text}>{census.provinces}</div>
          </div>
        ) : null}
        {census.selected ? (
          <div style={styles.line}>
            <div style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_SELECTED_LABEL`)}
            </div>
            <div style={styles.text}>{census.selected}</div>
          </div>
        ) : null}
        {census.pilotPhase ? (
          <div style={styles.line}>
            <div style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_PILOT_PHASE_QUESTION`)}
            </div>
            <div style={styles.text}>{census.pilotPhase}</div>
          </div>
        ) : null}
        {census.pilotPhaseScopeType ? (
          <div style={styles.line}>
            <div style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_SELECT_PILOT_PHASE_SCOPE`)}
            </div>
            <div style={styles.text}>{census.pilotPhaseScopeType}</div>
          </div>
        ) : null}
        {census.pilotPhaseMuncipality ? (
          <div style={styles.line}>
            <div style={styles.question}>
              {myI18n._(
                t`CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY_PILOT_PHASE`
              )}
            </div>
            <div style={styles.text}>{census.pilotPhaseMuncipality}</div>
          </div>
        ) : null}
        {census.pilotPhaseProvinces ? (
          <div style={styles.line}>
            <div style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_PROVINCES_PILOT_PHASE`)}
            </div>
            <div style={styles.text}>{census.pilotPhaseProvinces}</div>
          </div>
        ) : null}
        {census.pilotPhaseSelected ? (
          <div style={styles.line}>
            <div style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_PILOT_PHASE_SELECTED_LABEL`)}
            </div>
            <div style={styles.text}>{census.pilotPhaseSelected}</div>
          </div>
        ) : null}
        {census.impactsProvincialNorth ? (
          <div style={styles.line}>
            <div style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_NORTH_FOCUS_QUESTION`)}
            </div>
            <div style={styles.text}>{census.impactsProvincialNorth}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
