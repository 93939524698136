import { t } from '@lingui/macro'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { pad } from 'lodash'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'

const styles = {
  table: {
    ...pdfTableStyles.table,
    width: '100%',
  },
  tableRow: {
    ...pdfTableStyles.tableRow,
    display: 'flex',
  },
  firstCol: {
    width: '35%'
  },
  secondCol: {
    width: '20%'
  },
  thirdCol: {
    width: '45%'
  },
  tableCol: {
    ...pdfTableStyles.tableCol,
    minHeight: 35,
  },
  tableCell: {
    ...pdfTableStyles.tableCell,
    margin: '2px',
    padding: '10px 20px'
  },
  secondTitle: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 14,
    margin: '2px',
    textAlign: 'center',
    marginBottom: '5px'
  },
  mainTableCell: {
    ...pdfTableStyles.mainTableCell,
    margin: '2px',
    padding: '10px 20px'
  },
  helptextCell: {
    ...pdfTableStyles.helptextCell,
    padding: '0 20px 10px 20px',
    marginTop: '-12px'
  }
}

export const FormBudgetPdfEditor = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  targetFormType,
  ...props
}) => {


  console.log({value, elementType, typeProps, langVersion, title, helpText, tooltip, altLabelPlacement, ...props})

  const expenses = typeProps.expensesLines || []
  const revenues = typeProps.revenuesLines || []
  const expensesHelpText = typeProps?.expensesHelpText?.[langVersion] || ''
  const revenuesHelpText = typeProps?.revenuesHelpText?.[langVersion] || ''

  const expensesYears = Object.keys(value.expenses)
  const revenuesYears = Object.keys(value.revenues)

  // const years = [...new Set([
  //   ...expensesYears, ...revenuesYears,
  // ])].sort((a, b) => a - b)

  const years = targetFormType === "pdf"? ['Year Placeholder']: ['']
  const valuePlaceholder = targetFormType === "pdf"? "$ Value Placeholder": ""
  const commentPlaceholder = targetFormType === "pdf"? "Comment Placeholder": ""

  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
    styles.mainTableCell.fontFamily = inuktitutFormPdfFontFamily
    styles.helptextCell.fontFamily = inuktitutFormPdfFontFamily
  }

  return (
    <div>
      <div style={pdfTableStyles.title}>{title}</div>
      {years.map((year, index) => (
        <div key={index} >
          {year && <h2 style={styles.secondTitle}>{myI18n._(t`Year`)}: {year}</h2>}
          <div style={styles.table}>
            <div style={styles.tableRow} wrap={false}>
              <div style={{...styles.tableCol, ...styles.firstCol}}>
                <p style={styles.mainTableCell}>{myI18n._(t`Revenues`)}</p>
                {revenuesHelpText ? (
                  <p style={styles.helptextCell}>{revenuesHelpText}</p>
                ) : null}
              </div>
              <div style={{...styles.tableCol, ...styles.secondCol}}>
                <p style={styles.tableCell}>{myI18n._(t`Amount`)}</p>
              </div>
              <div style={{...styles.tableCol, ...styles.thirdCol}}>
                <p style={styles.tableCell}>{myI18n._(t`Comments`)}</p>
              </div>
            </div>

            {revenues.map((item, index) => (
              <div style={styles.tableRow} wrap={false} key={index}>
                <div style={{...styles.tableCol, ...styles.firstCol}}>
                  <p style={styles.tableCell}>{myI18n._(item)}</p>
                </div>
                <div style={{...styles.tableCol, ...styles.secondCol}}><p style={styles.tableCell}>{valuePlaceholder}</p></div>
                <div style={{...styles.tableCol, ...styles.thirdCol}}><p style={styles.tableCell}>{commentPlaceholder}</p></div>
              </div>
            ))}

            <div style={styles.tableRow} wrap={false}>
              <div style={{...styles.tableCol, ...styles.firstCol}}>
                <p style={styles.mainTableCell}>{myI18n._(t`Expenses`)}</p>
                {expensesHelpText ? (
                  <p style={styles.helptextCell}>{expensesHelpText}</p>
                ) : null}
              </div>
              <div style={{...styles.tableCol, ...styles.secondCol}}></div>
              <div style={{...styles.tableCol, ...styles.thirdCol}}></div>
            </div>

            {expenses.map((item, index) => (
              <div style={styles.tableRow} wrap={false} key={index}>
                <div style={{...styles.tableCol, ...styles.firstCol}}>
                  <p style={styles.tableCell}>{myI18n._(item)}</p>
                </div>
                <div style={{...styles.tableCol, ...styles.secondCol}}><p style={styles.tableCell}>{valuePlaceholder}</p></div>
                <div style={{...styles.tableCol, ...styles.thirdCol}}><p style={styles.tableCell}>{commentPlaceholder}</p></div>
              </div>
            ))}

          </div>
        </div> 
      ))}
    </div>
  )
}
