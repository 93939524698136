import { Trans, t } from "@lingui/macro";
import {
  Button,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { CurrencyFormated } from "app/views/common/Formats";
import { FieldArray, useFormikContext } from "formik";
import { myI18n } from "translation/I18nConnectedProvider";
import { getMainConnected } from "../../../Form";
import MUTextField from "../../../multiuser/components/MUTextField";
import { endEditingField } from "../../../multiuser/grpcMultiuserEdit";

export const formOtherGrantsDefaultValue = (obj, info, item) => {
  const { connectedField } = getMainConnected(item);
  const field = connectedField?.name;
  if (!field || !obj || !obj[field]) {
    return [];
  }
  return obj[field].split(";;;").map((i) => {
    const grant = i.split(";");
    return { name: grant[0], value: grant[1], state: grant[2] };
  });
};

export const grantStatusOptions = [
  {
    label: <Trans>Approved</Trans>,
    labelPDF: myI18n._("Approved"),
    value: "en_ApprovedUS",
  },
  {
    label: <Trans>Pending</Trans>,
    labelPDF: myI18n._("Pending"),
    value: "Pending",
  },
  {
    label: <Trans>Not submitted yet</Trans>,
    labelPDF: myI18n._("Not submitted yet"),
    value: "Not submitted yet",
  },
  {
    label: <Trans>Declined</Trans>,
    labelPDF: myI18n._("Declined"),
    value: "Declined",
  },
];

export const formOtherGrantsValueToText = (v) => {
  const toRet = (
    <div>
      <Grid container style={{ width: "100%" }}>
        <Grid item xs={5} style={{ padding: 4 }}>
          <Typography className="form-print-subtitle">
            <Trans>Name</Trans>
          </Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className="form-print-subtitle">
            <Trans>Value</Trans>
          </Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className="form-print-subtitle">
            <Trans>Grants: State</Trans>
          </Typography>
        </Grid>
      </Grid>
      {v.map((grant, index) => {
        return (
          <>
            <div style={{ height: 8 }} />
            <Grid container key={index} style={{ width: "100%" }}>
              <Grid item xs={5} style={{ padding: 4 }}>
                <Typography>{grant.name}</Typography>
              </Grid>
              <Grid item xs style={{ padding: 4 }}>
                <Typography>
                  <CurrencyFormated value={grant.value} />
                </Typography>
              </Grid>
              <Grid item xs style={{ padding: 4 }}>
                <Typography>
                  <Trans id={grant.state} />
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      })}
    </div>
  );
  return {
    en: toRet,
    fr: toRet,
  };
};

export const formOtherGrantsExtractSaveKey = ({
  saveMap,
  item,
  sfObject,
  value,
  connectedProps,
  subObjectsMap,
  connectedObjectId,
}) => {
  const { connectedField, connectedObject } = connectedProps;
  const fieldName = connectedField?.name;
  if (fieldName && connectedObject) {
    saveMap[connectedObject][fieldName] = value
      .map((i) => `${i.name};${i.value};${i.state}`)
      .join(";;;");
  }
};

/**
 * Form element which allows providing list of other grants that are related to the project.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {SalesforceObjectConnection}  connectedObject Connected Salesforce object.
 * @param  {SalesforceFieldConnection}  connectedField Connected Salesforce field object the value of input will be save to. Accepts 'textarea' and 'string' type fields.
 */
export const FormOtherGrants = ({
  id,
  value,
  useMultiuser,
  muBag,
  disabled,
  title,
  tooltip,
  typeProps,
  ...props
}) => {
  const { connectedTo = [] } = typeProps;
  const columns = [t`Name`, t`Value`, t`Status`, t`Delete`];

  const { values } = useFormikContext();

  const isElementLocked = Object.keys(values?.muInfo).find((key) => {
    if (key.includes(id)) return true;
    return false;
  });

  if (connectedTo.length === 0) {
    return (
      <div style={{ color: "red" }}>
        <b>
          <Trans>Grants field was not connected in form editor!</Trans>
        </b>
      </div>
    );
  }

  const grants = value || [];
  return (
    <FieldArray
      name={id}
      render={(arrayHelpers) => (
        <Grid
          container
          spacing={1}
          style={{ marginBottom: 5 }}
          justify="space-between"
        >
          <Grid item xs={12} style={{ marginTop: 25 }}>
            <div
              style={{
                marginBottom: -15,
                marginLeft: 20,
              }}
            >
              {title}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <IconButton aria-label="delete" size="small">
                    <HelpOutlineIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              {!disabled && (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={disabled}
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    arrayHelpers.push({
                      name: "",
                      value: 0,
                      state: "",
                    });
                    if (useMultiuser) {
                      endEditingField({
                        ...muBag,
                        fieldId: id,
                        fieldValue: [
                          ...value,
                          {
                            name: "",
                            value: 0,
                            state: "",
                          },
                        ],
                      });
                    }
                  }}
                >
                  <Icon color="inherit">add</Icon>
                  <span>
                    <Trans>Add Grant</Trans>
                  </span>
                </Button>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} style={{ marginTop: 15 }}>
              <Table
                style={{ whiteSpace: "pre", minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <colgroup>
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                  <col width="10%" />
                </colgroup>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                    {columns.map((item, i) => (
                      <TableCell key={i} style={{ paddingLeft: 35 }}>
                        <p
                          style={{
                            textShadow: "2px 2px 0px rgba(63,107,169, 0.15)",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          <Trans id={item} style={{ marginRight: 10 }} />
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {grants.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ paddingTop: 15 }}>
                        <MUTextField
                          useMultiuser={useMultiuser}
                          displayFieldHistoryIcon={true}
                          muBag={muBag}
                          id={id + `[${index}].name`}
                          label={myI18n?._(t`Grant's Name`)}
                          disabled={disabled}
                        />
                      </TableCell>
                      <TableCell style={{ paddingTop: 15 }}>
                        <MUTextField
                          useMultiuser={useMultiuser}
                          displayFieldHistoryIcon={true}
                          elementType="textInputNumeric"
                          muBag={muBag}
                          id={id + `[${index}].value`}
                          label={myI18n?._(t`Grant's Value`)}
                          type="number"
                          currency={true}
                          disabled={disabled}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell style={{ paddingTop: 15 }}>
                        <MUTextField
                          select
                          useMultiuser={useMultiuser}
                          displayFieldHistoryIcon={true}
                          muBag={muBag}
                          id={id + `[${index}].state`}
                          label={myI18n?._(t`Grant's Status`)}
                          disabled={disabled}
                        >
                          {grantStatusOptions.map((item) => (
                            <MenuItem value={item.value} key={item.key}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </MUTextField>
                      </TableCell>
                      <TableCell style={{ paddingTop: 15 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {!disabled && (
                            <IconButton
                              variant="filled"
                              onClick={() => {
                                arrayHelpers.remove(index);
                                const newValue = [...value];
                                newValue.splice(index, 1);
                                if (useMultiuser) {
                                  endEditingField({
                                    ...muBag,
                                    fieldId: id,
                                    fieldValue: newValue,
                                  });
                                }
                              }}
                              disabled={disabled || isElementLocked}
                            >
                              <Icon
                                color={isElementLocked ? "disabled" : "error"}
                                className="material-icons MuiIcon-root MuiIcon-colorError"
                                variant="filled"
                              >
                                delete
                              </Icon>
                            </IconButton>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    />
  );
};
