import { Trans } from "@lingui/macro";
import {
  Card,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { getLabelFromTranslationData } from "app/views/common/TranslationsCommon";
import ConfigureMultilanguageTextField from "app/views/internal/ConfigureMultilanguageTextField";
import { useFormikContext } from "formik";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import rehypeStringify from "rehype-stringify";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";
import { formItemPadding } from "../Form";
import {
  NumberFormatDefault,
  getElementIdToFormPosition,
} from "../common/Common";
import DebouncedTextField from "../common/DebouncedTextField";
import { FormElementPrintProps } from "../common/FormElementPrintProps";
import { FormElementTitle } from "../common/FormElementTitle";
import {
  formComponentTypes,
  formTypeToComponentTypeKey,
  formTypeToEditorPreviewKey,
} from "../components/formComponentTypes";
import ConditionalElementEditor from "./ConditionalElementEditor";
import { FormEditorElementHeaderBar } from "./FormEditorElementHeaderBar";
import { draggableItemTypes } from "./FormWizard";

export const initialTouchedFormElements = [
  "uploadFiles",
  "connectContact",
  "connectAccount",
  "associatedContactsList",
  "cenzusDivision",
  "objectives",
];

export const formElementsWithoutInput = [
  "text",
  "table",
  "textWithReferences",
  "image",
  "header",
  "html",
  "blank",
  "saveButton",
  "submitButton",
  "homePageButton",
];
export const formElementsWithoutTooltip = [
  'text',
  'textWithReferences',
  'table',
  'accountJoinList',
  'image',
  'header',
  'html',
  'blank',
  'saveButton',
  'submitButton',
  'submitOpportunity',
  'milestones',
  'objectives',
  'budget',
  'icce_budget',
  'icce_workplan',
  'budgetUpdate',
  'objectivesUpdate',
  'banner',
]
export const formElementsWithoutHelpText = [
  'text',
  'textWithReferences',
  'blank',
  'objectivesUpdate',
  'banner'
]

export const formPDFElementsWithoutDefaultTitle = [
  "image",
  "text",
  "textWithReferences",
  "html",
  "blank",
  "bool",
  "header",
  "banner",
];

export const formElementsWithoutDefaultTitle = [
  "image",
  "textWithReferences",
  "html",
  "blank",
  "otherGrants",
  "saveButton",
  "submitButton",
  "submitOpportunity",
  "homePageButton",
  "banner",
];

export const formElementsWithoutFieldHistory = ["uploadFiles"];

export const formElementsWithCustomTitleRender = [
  "budget",
  "image",
  "table",
  "bool",
  "objectives",
  "accountJoinList",
  "icce_budget",
  "icce_workplan",
  "milestones",
  "text",
  "otherGrants",
  "header",
  "homePageButton",
  "budgetUpdate",
  "objectivesUpdate",
  "banner",
];

export const formElementsWithoutCardInPrintView = [
  "budget",
  "objectives",
  "milestones",
  "table",
  "icce_budget",
  "header",
  "accountJoinList",
];

const usesMarkdownInRender = ["bool"];

export const formUseStyles = makeStyles((theme) => ({
  root: {
    "& p": {
      margin: 0,
      padding: 0,
    },
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
}));

const rendersTitle = (type) =>
  Boolean(
    !formElementsWithoutDefaultTitle.includes(type) ||
      formElementsWithCustomTitleRender.includes(type)
  );

export const GroupElement = ({
  injectable,
  editMode,
  isDragLayer,
  parentIsDummy,
  movingInStack,
  selectedParent,
  index,
  elementType,
  fieldType,
  width,
  columns,
  langVersion = "en",
  depth = [],
  selected,
  emptySpace,
  typeProps,
  conditions = [],
  formType,
  ...props
}) => {
  const dummy = props.dummy || parentIsDummy;
  const dispatch = useDispatch();

  const tree = useSelector((state) => state.formEditorTree);
  const elementIdToFormPosition = getElementIdToFormPosition(tree);

  const [collected, drag, preview] = useDrag(
    () => ({
      type: draggableItemTypes.ITEM_ELEMENT,
      item: () => {
        dispatch({
          type: "START_DRAG",
          injectable,
          depth,
        });
        const dragRectWidth = body.current?.getBoundingClientRect().width;
        return {
          index,
          elementType,
          depth,
          typeProps,
          editMode: editMode,
          selected: selected,
          type: draggableItemTypes.ITEM_ELEMENT,
          width: dragRectWidth,
          conditions,
          ...props,
        };
      },
      canDrag: () => !selectedParent,
      end(item, monitor) {
        dispatch({
          type: "END_DRAG",
          injectable,
          depth: [...item.depth],
        });
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [
      editMode,
      isDragLayer,
      parentIsDummy,
      movingInStack,
      selectedParent,
      index,
      elementType,
      fieldType,
      width,
      columns,
      langVersion,
      depth,
      selected,
      emptySpace,
      props,
    ]
  );

  const body = useRef();

  const [{ isHoveredOverStrict }, drop] = useDrop({
    accept: [draggableItemTypes.GROUP_CARD, draggableItemTypes.ITEM_ELEMENT],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isHoveredOverStrict: monitor.isOver({ shallow: true }),
      };
    },
    drop(item, monitor) {
      if (!body.current) {
        return;
      }
      if (!isHoveredOverStrict) {
        return;
      }
      if (item.dropped) {
        return;
      }
      const dragDepth = [...item.depth];
      const hoverDepth = [...depth];
      if (dragDepth.join(".") === hoverDepth.join(".")) {
        dispatch({
          type: "FIELD",
          injectable,
          depth,
          fieldName: "dummy",
          fieldValue: false,
        });
        return;
      }
      const dragParentPath = [...dragDepth];
      dragParentPath.splice(-1, 1);
      const hoverParentPath = [...hoverDepth];
      hoverParentPath.splice(-1, 1);
      const isInSameGroup =
        hoverParentPath.join(".") === dragParentPath.join(".");
      if (!isInSameGroup) {
        dispatch({
          type: "MOVE_ITEM_BETWEEN",
          injectable,

          dragDepth,
          hoverDepth,
          endDragDepth: [...hoverDepth],
        });
      } else {
        const dragIndex = dragDepth[dragDepth.length - 1];
        const hoverIndex = hoverDepth[hoverDepth.length - 1];
        const groupDepth = [...depth];
        groupDepth.splice(-1, 1);
        dispatch({
          dragIndex,
          hoverIndex,
          depth: groupDepth,
          endDragDepth: [...hoverDepth],
          injectable,
          type: "MOVE_ITEM",
        });
      }
      item.depth = [...hoverDepth];
      item.dropped = true;
    },
  });

  if (emptySpace) {
    return (
      <Grid item xs={12 / +columns} style={{ opacity: 0 }}>
        <div />
      </Grid>
    );
  }

  const conditionsToPass = JSON.stringify(conditions);

  return (
    <>
      <div ref={isDragLayer ? null : preview} />
      <Grid
        item
        xs={columns ? 12 / +columns : 12}
        style={{
          width: Boolean(depth.length === 2) && "100%",
        }}
      >
        <div ref={isDragLayer ? null : drop} style={{ flex: 1 }}>
          <div ref={isDragLayer ? null : drag}>
            <ElementMemoized
              isHoverOver={isHoveredOverStrict}
              depth={depth.join(".")}
              body={body}
              elementType={elementType}
              conditionsToPass={conditionsToPass}
              dummy={dummy}
              movingInStack={movingInStack}
              width={width}
              selectedParent={selectedParent}
              langVersion={langVersion}
              id={props.id}
              formId={props.formId}
              selected={selected}
              typeProps={typeProps}
              altLabelPlacement={props.altLabelPlacement}
              labelAsMarkdown={props.labelAsMarkdown}
              helptextAsMarkdown={props.helptextAsMarkdown}
              showBasicElements={props.showBasicElements}
              showCustomElements={props.showCustomElements}
              sectionLength={props.sectionLength}
              editMode={editMode}
              showPrintProps={props.showPrintProps}
              showPdfProps={props.showPdfProps}
              showEditableProps={props.showEditableProps}
              showPdfComponent={props.showPdfComponent}
              showPrintComponent={props.showPrintComponent}
              showEditableComponent={props.showEditableComponent}
              padding={props.padding}
              injectable={injectable}
              injectableId={props.injectableId}
              targetFormType={props.targetFormType}
              selectedTargetFormType={props.selectedTargetFormType}
              title={props.title}
              helpText={props.helpText}
              tooltip={props.tooltip}
              i18n={props.i18n}
              objectsConnected={props.objectsConnected}
              position={elementIdToFormPosition[props.id]}
              forceSaveWhenDisabled={props.forceSaveWhenDisabled}
            />
          </div>
        </div>
      </Grid>
    </>
  );
};

const ElementMemoized = React.memo((props) => {
  const dispatch = useDispatch();
  const {
    body,
    injectable,
    elementType,
    dummy,
    movingInStack,
    width,
    showBasicElements = formComponentTypes[elementType]?.basic,
    showCustomElements = !formComponentTypes[elementType]?.basic,
    id,
    isHoverOver,
    labelAsMarkdown,
    helptextAsMarkdown,
    altLabelPlacement,
    editMode,
    typeProps,
    padding = {},
    langVersion,
    showEditableProps,
    showPrintProps,
    showPdfProps,
    i18n,
    position,
    targetFormType,
    forceSaveWhenDisabled,
  } = props;
  const disabled = !injectable && props.injectableId;
  const { setFieldValue, values } = useFormikContext();
  const { supportedFormType } = values;
  const depth = props.depth.split(".");
  const index = Number(depth[depth.length - 1]);
  const tooltip = getLabelFromTranslationData({
    langVersion,
    i18n,
    data: props?.tooltip,
  });
  let helpText = getLabelFromTranslationData({
    langVersion,
    i18n,
    data: props?.helpText,
  });
  if (helpText && helptextAsMarkdown) {
    helpText = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .processSync(helpText)
      .toString();
  }

  const showFieldLabel = typeProps?.showFieldLabel;
  let title = getLabelFromTranslationData({
    langVersion,
    i18n,
    data: props?.title,
  });

  if (labelAsMarkdown) {
    title = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .processSync(title)
      .toString();
  }
  const elementData = formComponentTypes[elementType];
  const hasLabel =
    !formElementsWithCustomTitleRender.includes(elementType) &&
    !formElementsWithoutDefaultTitle.includes(elementType) &&
    !showFieldLabel;

  const paddingStyles = {};
  const paddingKeys = [
    "paddingLeft",
    "paddingRight",
    "paddingTop",
    "paddingBottom",
  ];
  paddingKeys.forEach((key) => {
    let toSet = formItemPadding;
    if (padding[key]) {
      toSet = Number(padding[key]);
    }
    paddingStyles[key] = toSet;
  });

  const hasEditorPreview =
    elementData && elementData[formTypeToEditorPreviewKey[targetFormType]];
  const hasSupportedRender =
    elementData && elementData[formTypeToComponentTypeKey[targetFormType]];

  const displayTitle = hasSupportedRender && hasEditorPreview && hasLabel;

  const allowedTypesForForceSave = [
    "textInput",
    "picklist",
    "numericSlider",
    "textInputNumeric",
    "uploadFiles",
    "googleMapsPicker",
    "datePicker",
    "milestoneUpdate",
    "icce_budget",
    "excelFileImport",
    "connectContact",
    "connectAccount",
    "bool",
  ];

  return (
    <Card
      ref={body}
      elevation={5}
      style={{
        padding: 5,
        backgroundColor:
          Boolean(isHoverOver && !dummy && !movingInStack) &&
          "rgba(255, 255, 180, 0.7)",
        margin: 10,
        width: width,
        flexGrow: 1,
        opacity: Boolean(movingInStack || dummy) && 0.2,
        border: "1px solid rgba(0, 0, 0, 1)",
      }}
      id={id}
    >
      <FormEditorElementHeaderBar
        {...props}
        index={index}
        depth={depth}
        position={position}
      />
      {!editMode && (
        <Grid
          container
          direction={altLabelPlacement ? "row" : "column"}
          style={{ ...paddingStyles }}
        >
          {displayTitle && (
            <Grid item>
              <FormElementTitle
                title={title}
                helpText={!elementData?.customHelptext && helpText}
                tooltip={tooltip}
                id={id}
                altLabelPlacement={altLabelPlacement}
                labelAsMarkdown={labelAsMarkdown}
                helptextAsMarkdown={helptextAsMarkdown}
                targetFormType={targetFormType}
                type={elementType}
              />
            </Grid>
          )}
          <div style={{ flex: 1 }}>
            {!hasSupportedRender ? (
              <Alert severity="warning">
                <Trans>FORM_EDITOR_INVALID_ELEMENT_FOR_FORM_TYPE_WARNING</Trans>
              </Alert>
            ) : hasEditorPreview ? (
              elementData[formTypeToEditorPreviewKey[targetFormType]]({
                ...props,
                value: values[id],
                langVersion,
                title,
                helpText,
                tooltip,
              })
            ) : (
              <Alert severity="warning">
                <Trans>FORM_EDITOR_NO_PREVIEW_CONFIGURED_WARNING</Trans>
              </Alert>
            )}
          </div>
        </Grid>
      )}
      {editMode && (
        <Grid container direction="column" style={{ padding: 15 }}>
          {disabled && (
            <Alert severity="info" style={{ marginBottom: 15 }}>
              <AlertTitle>
                This component was added from global list of reusable components
                and therefore cannot be edited
              </AlertTitle>
            </Alert>
          )}
          {rendersTitle(elementType) && (
            <ConfigureMultilanguageTextField
              style={{ marginBottom: 10 }}
              disabled={disabled}
              label={
                elementType === "text" ? (
                  <Trans>Text</Trans>
                ) : (
                  <Trans>Title</Trans>
                )
              }
              value={props.title}
              variant="outlined"
              multiline={elementType === "text"}
              rows={4}
              handleChange={(value, e, lang) => {
                if (
                  e?.nativeEvent?.inputType &&
                  e?.nativeEvent?.inputType?.startsWith("insertFromPaste")
                ) {
                  value[lang] = e.currentTarget.value.replace(/\r?\n|\r/g, " ");
                }
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth,
                  fieldName: `title`,
                  fieldValue: value,
                });
              }}
              fullWidth
              useDebounce
            />
          )}

          <Grid
            style={{ marginTop: 5 }}
            container
            direction="row"
            wrap="nowrap"
          >
            <Grid item xs style={{ marginRight: 10 }}>
              <FormLabel style={{ padding: 3, marginTop: 10 }}>
                <Trans>Element type</Trans>
              </FormLabel>
              <Autocomplete
                freeSolo={false}
                value={elementType || ""}
                disabled={
                  (!showCustomElements && !showBasicElements) || disabled
                }
                getOptionLabel={(key) => formComponentTypes[key].text}
                style={{ marginBottom: 10 }}
                onChange={(e, v) => {
                  const fieldArray = [];
                  fieldArray.push({
                    fieldName: "elementType",
                    fieldValue: v,
                  });
                  const conditions =
                    props.conditionsToPass &&
                    JSON.parse(props.conditionsToPass);
                  if (conditions) {
                    fieldArray.push({
                      fieldName: "conditions",
                      fieldValue: conditions.filter((condition) =>
                        [
                          "hide",
                          "show",
                          "altLabel",
                          "required",
                          "notRequired",
                        ].includes(condition.state)
                      ),
                    });
                  }
                  const newDef = formComponentTypes[v].defaultValue;
                  if (typeof newDef === "function") {
                    setFieldValue(id, newDef({}, {}, { ...props }));
                  } else {
                    setFieldValue(id, newDef);
                  }
                  fieldArray.push({
                    fieldName: "typeProps",
                    fieldValue: {},
                  });
                  if (formElementsWithoutTooltip.includes(v)) {
                    fieldArray.push({
                      fieldName: `tooltip`,
                      fieldValue: {},
                    });
                  }
                  if (formElementsWithoutHelpText.includes(v)) {
                    fieldArray.push({
                      fieldName: `helpText`,
                      fieldValue: {},
                    });
                  }
                  if (!rendersTitle(v)) {
                    fieldArray.push({
                      fieldName: `title`,
                      fieldValue: {},
                    });
                  }
                  if (
                    !formElementsWithCustomTitleRender.includes(v) &&
                    !formElementsWithoutDefaultTitle.includes(v)
                  ) {
                    fieldArray.push({
                      fieldName: "altLabelPlacement",
                      fieldValue: null,
                    });
                  } else {
                    if (!usesMarkdownInRender.includes(v)) {
                      fieldArray.push({
                        fieldName: "labelAsMarkdown",
                        fieldValue: null,
                      });
                    }
                  }
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth,
                    fieldArray,
                  });
                }}
                fullWidth
                options={Object.keys(formComponentTypes)
                  .filter((key) => {
                    const obj = formComponentTypes[key];
                    const allSupportedFormTypesNull = supportedFormType.every(
                      (type) => !obj[formTypeToComponentTypeKey[type]]
                    );
                    if (allSupportedFormTypesNull) {
                      return false;
                    } else if (!showBasicElements && obj.basic) {
                      return false;
                    } else if (!showCustomElements && !obj.basic) {
                      return false;
                    } else if (obj.deprecated) {
                      return false;
                    } else if (obj.disabled) {
                      return false;
                    } else if (injectable && !obj.injectable) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .sort((a, b) => {
                    const obj1 = formComponentTypes[a];
                    const obj2 = formComponentTypes[b];
                    return obj1.text.localeCompare(obj2.text);
                  })}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} />
                )}
              />
            </Grid>

            <Grid item xs="auto">
              <Grid
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 10,
                }}
                container
                direction="column"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(showBasicElements)}
                      disabled={disabled}
                      onChange={(e) => {
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth,
                          fieldName: "showBasicElements",
                          fieldValue: e.currentTarget.checked,
                        });
                      }}
                    />
                  }
                  label={<Trans>Show basic elements</Trans>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(showCustomElements)}
                      disabled={disabled}
                      onChange={(e) => {
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth,
                          fieldName: "showCustomElements",
                          fieldValue: e.currentTarget.checked,
                        });
                      }}
                    />
                  }
                  label={<Trans>Show advanced elements</Trans>}
                />
              </Grid>
            </Grid>
          </Grid>

          {Boolean(hasLabel || usesMarkdownInRender.includes(elementType)) && (
            <FormControlLabel
              style={{ marginLeft: 5, marginTop: -5 }}
              control={
                <Checkbox
                  checked={Boolean(labelAsMarkdown)}
                  disabled={disabled}
                  onChange={(e) => {
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth,
                      fieldName: "labelAsMarkdown",
                      fieldValue: e.currentTarget.checked,
                    });
                  }}
                />
              }
              label={<Trans>Interpret label as markdown</Trans>}
            />
          )}
          {hasLabel && (
            <FormControlLabel
              style={{ marginLeft: 5, marginTop: -5 }}
              control={
                <Checkbox
                  checked={Boolean(altLabelPlacement)}
                  disabled={disabled}
                  onChange={(e) => {
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth,
                      fieldName: "altLabelPlacement",
                      fieldValue: e.currentTarget.checked,
                    });
                  }}
                />
              }
              label={<Trans>Place label on the left</Trans>}
            />
          )}
          {Boolean(
            !formElementsWithoutTooltip.includes(elementType) &&
              showEditableProps
          ) && (
            <ConfigureMultilanguageTextField
              disabled={disabled}
              variant="outlined"
              style={{ marginBottom: 10, marginTop: 10 }}
              label={<Trans>Tooltip</Trans>}
              value={props.tooltip}
              handleChange={(value, e, lang) => {
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth,
                  fieldName: `tooltip`,
                  fieldValue: value,
                });
              }}
              useDebounce
            />
          )}
          {Boolean(!formElementsWithoutHelpText.includes(elementType)) && (
            <>
              <ConfigureMultilanguageTextField
                disabled={disabled}
                variant="outlined"
                style={{ marginBottom: 10, marginTop: 10 }}
                label={<Trans>Help text</Trans>}
                value={props.helpText}
                multiline
                handleChange={(value, e, lang) => {
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth,
                    fieldValue: value,
                    fieldName: "helpText",
                  });
                }}
                fullWidth
                useDebounce
              />
              <FormControlLabel
                style={{ marginLeft: 5, marginTop: -5 }}
                control={
                  <Checkbox
                    checked={Boolean(helptextAsMarkdown)}
                    disabled={disabled}
                    onChange={(e) => {
                      dispatch({
                        type: "FIELD",
                        injectable,
                        depth,
                        fieldName: "helptextAsMarkdown",
                        fieldValue: e.currentTarget.checked,
                      });
                    }}
                  />
                }
                label={<Trans>Interpret help text as markdown</Trans>}
              />
            </>
          )}
          <Grid container>
            {[
              {
                key: "paddingLeft",
                label: <Trans>Padding left</Trans>,
                pdf: 0,
              },
              {
                key: "paddingRight",
                label: <Trans>Padding right</Trans>,
                pdf: 0,
              },
              { key: "paddingTop", label: <Trans>Padding top</Trans>, pdf: 6 },
              {
                key: "paddingBottom",
                label: <Trans>Padding bottom</Trans>,
                pdf: 6,
              },
            ].map((obj, index) => {
              const defaultValue = targetFormType.includes("pdf")
                ? obj.pdf
                : formItemPadding;
              return (
                <Grid item xs key={index} style={{ padding: 5 }}>
                  <DebouncedTextField
                    disabled={disabled}
                    style={{ marginBottom: 10 }}
                    label={obj.label}
                    value={padding[obj.key] || defaultValue}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatDefault,
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      const toSet = { ...padding };
                      toSet[obj.key] = e.target.value;
                      dispatch({
                        type: "FIELD",
                        injectable,
                        depth,
                        fieldName: "padding",
                        fieldValue: toSet,
                      });
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          {allowedTypesForForceSave.includes(elementType) && (
            <FormControlLabel
              style={{ marginLeft: 5, marginTop: -5 }}
              control={
                <Checkbox
                  checked={Boolean(forceSaveWhenDisabled)}
                  onChange={(e) => {
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth,
                      fieldName: "forceSaveWhenDisabled",
                      fieldValue: e.currentTarget.checked,
                    });
                  }}
                />
              }
              label={<Trans>FORM_EDITOR_FORCE_SAVE_WHEN_DISABLED_LABEL</Trans>}
            />
          )}
          {Boolean(elementData && elementData.editorComponent) && (
            <>
              {elementData.editorComponent({
                ...props,
                i18n,
                disabled,
                value: values[id],
                title,
                helpText,
                tooltip,
              })}
              {showPrintProps && (
                <FormElementPrintProps
                  disabled={disabled}
                  elementType={elementType}
                  {...props}
                />
              )}
            </>
          )}

          {!injectable && (
            <ConditionalElementEditor
              conditions={JSON.parse(props.conditionsToPass)}
              {...props}
            />
          )}
        </Grid>
      )}
    </Card>
  );
});

export const GroupElementWithoutDnD = ElementMemoized;
