import { Trans } from "@lingui/macro";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { dateFormat } from "app/appSettings";
import { MILESTONE_TRANSLATIONS } from "app/services/sfAuth/sfData/sfOpportunity";
import CustomDatePicker from "app/views/common-components/CustomDatePicker";
import DebouncedTextField from "app/views/forms/common/DebouncedTextField";
import { FormColorAutocomplete } from "app/views/forms/common/FormColorAutocomplete";
import { FormConnectToObject } from "app/views/forms/common/FormConnectToObject";
import { PdfPropsForm } from "app/views/forms/common/PdfPropsForm";
import { useFormEditorContext } from "app/views/forms/editor/FormEditorContext";
import ConfigureMultilanguageTextField from "app/views/internal/ConfigureMultilanguageTextField";
import { useDispatch, useSelector } from "react-redux";
import { languages } from "translation/I18nConnectedProvider";
import {
  NumberFormatDefault,
  mapEditorFormElements,
} from "../../../common/Common";
import { FormMilestones } from "./FormMilestones";

export const FormEditorMilestones = ({
  editMode,
  showPdfProps,
  showPrintProps,
  depth,
  id,
  langVersion,
  typeProps,
  disabled,
  injectable,
  injectableId,
  ...props
}) => {
  const {
    phases = [],
    commentsRequired,
    activitiesRequired,
    datesRequired,
    commentsMaxChar,
    commentsMaxWords,
    commentsExpandable,
    commentsRows,
    activitiesRows,
    activitiesExpandable,
    activitiesMaxChar,
    activitiesMaxWords,
    pdfHeaderTextProps = [],
    pdfBackgroundColor,
    pdfFontSize,
  } = typeProps;
  const { availableObjects } = useFormEditorContext();
  const dispatch = useDispatch();
  const tree = useSelector((state) => state.formEditorTree);
  const avaliableElements = mapEditorFormElements({
    data: tree,
    langVersion,
    id,
    elementTypes: ["datePicker"],
  });

  let milestonesAPIoptions = Object.keys(MILESTONE_TRANSLATIONS);
  const benchmarkObjectData = availableObjects.find(
    (obj) => obj.name === "FGM_Base__Benchmark__c"
  );
  if (benchmarkObjectData) {
    milestonesAPIoptions = benchmarkObjectData.fields
      .find((field) => field.name === "FGM_Base__Status__c")
      ?.picklistValues.map((opt) => opt.value);
  }

  if (!editMode) {
    return <FormMilestones editMode id={id} typeProps={typeProps} {...props} />;
  }

  return (
    <div>
      <div style={{ marginTop: 10 }}>
        <Typography variant="h6" style={{ marginBottom: 5 }}>
          <Trans>Start/end date fields</Trans>
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(datesRequired)}
              disabled={disabled}
              onChange={(e) => {
                const toSet = { ...typeProps };
                toSet.datesRequired = e.target.checked;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: toSet,
                });
              }}
            />
          }
          label={<Trans>Are required?</Trans>}
        />
      </div>
      <div>
        <Typography variant="h6" style={{ marignTop: 5, marginBottom: 5 }}>
          <Trans>Comments field</Trans>
        </Typography>
        <Grid container wrap="nowrap" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(commentsRequired)}
                disabled={disabled}
                onChange={(e) => {
                  const toSet = { ...typeProps };
                  toSet.commentsRequired = e.target.checked;
                  dispatch({
                    type: "FIELD",
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
              />
            }
            label={<Trans>Is required?</Trans>}
          />
          <DebouncedTextField
            style={{ marginTop: 5, marginBottom: 5, marginRight: 10 }}
            disabled={disabled}
            label={<Trans>Characters limit</Trans>}
            value={commentsMaxChar}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant="outlined"
            onChange={(e) => {
              const toSet = { ...typeProps };
              toSet.commentsMaxChar = e.target.value;
              dispatch({
                type: "FIELD",
                injectable,
                depth: depth.split("."),
                fieldName: "typeProps",
                fieldValue: { ...toSet },
              });
            }}
          />
          <DebouncedTextField
            style={{ marginTop: 5, marginBottom: 5 }}
            label={<Trans>Words limit</Trans>}
            value={commentsMaxWords}
            disabled={disabled}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant="outlined"
            onChange={(e) => {
              const toSet = { ...typeProps };
              toSet.commentsMaxWords = e.target.value;
              dispatch({
                type: "FIELD",
                injectable,
                depth: depth.split("."),
                fieldName: "typeProps",
                fieldValue: { ...toSet },
              });
            }}
          />
          <Grid item>
            <DebouncedTextField
              style={{ marginTop: 5, marginBottom: 5, marginLeft: 10 }}
              label={<Trans>Rows</Trans>}
              value={commentsRows || ""}
              disabled={disabled}
              InputProps={{ inputComponent: NumberFormatDefault }}
              variant="outlined"
              onChange={(e) => {
                const toSet = { ...typeProps };
                toSet.commentsRows = e.target.value;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: { ...toSet },
                });
              }}
            />
          </Grid>
          <Grid item style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(commentsExpandable)}
                  disabled={disabled}
                  onChange={(e) => {
                    const toSet = { ...typeProps };
                    toSet.commentsExpandable = e.target.checked;
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: { ...toSet },
                    });
                  }}
                />
              }
              label={<Trans>Allow expanding rows?</Trans>}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography variant="h6" style={{ marignTop: 5, marginBottom: 5 }}>
          <Trans>Primary activities field</Trans>
        </Typography>
        <Grid container wrap="nowrap" alignItems="center">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(activitiesRequired)}
                  disabled={disabled}
                  onChange={(e) => {
                    const toSet = { ...typeProps };
                    toSet.activitiesRequired = e.target.checked;
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: toSet,
                    });
                  }}
                />
              }
              label={<Trans>Is required?</Trans>}
            />
          </div>
          <DebouncedTextField
            style={{ marginTop: 5, marginBottom: 5, marginRight: 10 }}
            label={<Trans>Characters limit</Trans>}
            value={activitiesMaxChar}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant="outlined"
            disabled={disabled}
            onChange={(e) => {
              const toSet = { ...typeProps };
              toSet.activitiesMaxChar = e.target.value;
              dispatch({
                type: "FIELD",
                injectable,
                depth: depth.split("."),
                fieldName: "typeProps",
                fieldValue: { ...toSet },
              });
            }}
          />
          <DebouncedTextField
            style={{ marginTop: 5, marginBottom: 5 }}
            label={<Trans>Words limit</Trans>}
            value={activitiesMaxWords}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant="outlined"
            disabled={disabled}
            onChange={(e) => {
              const toSet = { ...typeProps };
              toSet.activitiesMaxWords = e.target.value;
              dispatch({
                type: "FIELD",
                injectable,
                depth: depth.split("."),
                fieldName: "typeProps",
                fieldValue: { ...toSet },
              });
            }}
          />
          <Grid item>
            <DebouncedTextField
              style={{ marginTop: 5, marginBottom: 5, marginLeft: 10 }}
              label={<Trans>Rows</Trans>}
              value={activitiesRows || ""}
              InputProps={{ inputComponent: NumberFormatDefault }}
              variant="outlined"
              disabled={disabled}
              onChange={(e) => {
                const toSet = { ...typeProps };
                toSet.activitiesRows = e.target.value;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: { ...toSet },
                });
              }}
            />
          </Grid>
          <Grid item style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(activitiesExpandable)}
                  disabled={disabled}
                  onChange={(e) => {
                    const toSet = { ...typeProps };
                    toSet.activitiesExpandable = e.target.checked;
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: { ...toSet },
                    });
                  }}
                />
              }
              label={<Trans>Allow expanding rows?</Trans>}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 10 }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 5, marginBottom: 10 }}
          disabled={disabled}
          onClick={(e) => {
            const toSet = { ...typeProps };
            toSet.phases = [...phases];
            const label = languages.reduce((acc, lang) => {
              acc[lang] = "";
              return acc;
            }, {});
            toSet.phases.push({ label });
            dispatch({
              type: "FIELD",
              injectable,
              depth: depth.split("."),
              fieldName: "typeProps",
              fieldValue: { ...toSet },
            });
          }}
        >
          <Grid container alignItems="center">
            <Trans>Add phase</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Grid>
        </Button>
        {phases.map((item, index) => (
          <div key={index}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item>
                <b style={{ marginLeft: 10, marginRight: 5 }}>
                  {+index + 1}
                  {"."}
                </b>
              </Grid>

              <Grid item xs>
                <ConfigureMultilanguageTextField
                  value={item.label}
                  label={<Trans>Label</Trans>}
                  handleChange={(value) => {
                    const toSet = { ...typeProps };
                    toSet.phases[index].label = value;
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: toSet,
                    });
                  }}
                  useDebounce
                />
              </Grid>

              <Grid item>
                <IconButton
                  disabled={disabled}
                  onClick={(e) => {
                    const toSet = { ...typeProps };
                    toSet.phases.splice(index, 1);
                    dispatch({
                      type: "FIELD",
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: { ...toSet },
                    });
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs style={{ padding: 5, minWidth: 250 }}>
              <TextField
                variant="outlined"
                label={<Trans>API value</Trans>}
                fullWidth
                select
                value={item.apiValue || ""}
                disabled={disabled}
                onChange={(e) => {
                  const toSet = { ...typeProps };
                  toSet.phases[index].apiValue = e.target.value;
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
              >
                {milestonesAPIoptions.map((key, index) => (
                  <MenuItem value={key} key={index}>
                    {key}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <div>
              <h6 style={{ marginLeft: 5, marginTop: 5 }}>
                <Trans>Start date</Trans>
              </h6>
              <Grid container>
                <Grid item xs style={{ padding: 5 }}>
                  <CustomDatePicker
                    fullWidth
                    clearable
                    format={dateFormat}
                    value={item.startDateMinDate || null}
                    label={<Trans>Minimum input date</Trans>}
                    inputVariant="outlined"
                    disabled={disabled}
                    onChange={(e) => {
                      const toSet = { ...typeProps };
                      delete toSet.phases[index].startDateMinDateElement;
                      toSet.phases[index].startDateMinDate = e;
                      dispatch({
                        type: "FIELD",
                        injectable,
                        depth: depth.split("."),
                        fieldName: "typeProps",
                        fieldValue: toSet,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs style={{ padding: 5 }}>
                  <DebouncedTextField
                    id={id}
                    disabled={disabled}
                    label={
                      <Trans>
                        FORM_EDITOR_MILESTONES_DAYS_AFTER_CURRENT_DATE_LABEL
                      </Trans>
                    }
                    fullWidth
                    variant="outlined"
                    value={item.startDateDaysAfterCurrentDate}
                    onChange={(e) => {
                      const toSet = { ...typeProps };
                      toSet.phases[index].startDateDaysAfterCurrentDate =
                        e.target.value;
                      dispatch({
                        type: "FIELD",
                        injectable,
                        depth: depth.split("."),
                        fieldName: "typeProps",
                        fieldValue: toSet,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs style={{ padding: 5 }}>
                  <Grid container wrap="nowrap">
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label={<Trans>Minimum date based on element</Trans>}
                      value={item.startDateMinDateElement || ""}
                      disabled={disabled || avaliableElements.length === 0}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        delete toSet.phases[index].startDateMinDate;
                        toSet.phases[index].startDateMinDateElement =
                          e.target.value;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                    >
                      {avaliableElements.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <IconButton
                      disabled={!item.startDateMinDateElement}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        delete toSet.phases[index].startDateMinDateElement;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs style={{ padding: 5 }}>
                  <CustomDatePicker
                    fullWidth
                    clearable
                    format={dateFormat}
                    value={item.startDateMaxDate || null}
                    label={<Trans>Maximum input date</Trans>}
                    inputVariant="outlined"
                    disabled={disabled}
                    onChange={(e) => {
                      const toSet = { ...typeProps };
                      delete toSet.phases[index].startDateMaxDateElement;
                      toSet.phases[index].startDateMaxDate = e;
                      dispatch({
                        type: "FIELD",
                        injectable,
                        depth: depth.split("."),
                        fieldName: "typeProps",
                        fieldValue: toSet,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs style={{ padding: 5 }}>
                  <Grid container wrap="nowrap">
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label={<Trans>Maximum date based on element</Trans>}
                      value={item.startDateMaxDateElement || ""}
                      disabled={disabled || avaliableElements.length === 0}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        delete toSet.phases[index].startDateMaxDate;
                        toSet.phases[index].startDateMaxDateElement =
                          e.target.value;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                    >
                      {avaliableElements.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <IconButton
                      disabled={!item.startDateMaxDateElement}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        delete toSet.phases[index].startDateMaxDateElement;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <ConfigureMultilanguageTextField
              value={item.startDateTooltip}
              label={<Trans>Start date - tooltip</Trans>}
              style={{ paddingTop: 10, paddingBottom: 10 }}
              handleChange={(value) => {
                const toSet = { ...typeProps };
                toSet.phases[index].startDateTooltip = value;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: toSet,
                });
              }}
              useDebounce
            />

            <div>
              <h6 style={{ marginLeft: 5, marginTop: 5 }}>
                <Trans>End date</Trans>
              </h6>
              <Grid container>
                <Grid item xs style={{ padding: 5 }}>
                  <CustomDatePicker
                    fullWidth
                    clearable
                    format={dateFormat}
                    value={item.endDateMinDate || null}
                    disabled={disabled}
                    label={<Trans>Minimum input date</Trans>}
                    inputVariant="outlined"
                    onChange={(e) => {
                      const toSet = { ...typeProps };
                      delete toSet.phases[index].endDateMinDateElement;
                      toSet.phases[index].endDateMinDate = e;
                      dispatch({
                        type: "FIELD",
                        injectable,
                        depth: depth.split("."),
                        fieldName: "typeProps",
                        fieldValue: toSet,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs style={{ padding: 5 }}>
                  <Grid container wrap="nowrap">
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label={<Trans>Minimum date based on element</Trans>}
                      value={item.endDateMinDateElement || ""}
                      disabled={disabled || avaliableElements.length === 0}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        delete toSet.phases[index].endDateMinDate;
                        toSet.phases[index].endDateMinDateElement =
                          e.target.value;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                    >
                      {avaliableElements.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <IconButton
                      disabled={!item.endDateMinDateElement}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        delete toSet.phases[index].endDateMinDateElement;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs style={{ padding: 5 }}>
                  <CustomDatePicker
                    fullWidth
                    clearable
                    format={dateFormat}
                    value={item.endDateMaxDate || null}
                    label={<Trans>Maximum input date</Trans>}
                    inputVariant="outlined"
                    disabled={disabled}
                    onChange={(e) => {
                      const toSet = { ...typeProps };
                      delete toSet.phases[index].endDateMaxDateElement;
                      toSet.phases[index].endDateMaxDate = e;
                      dispatch({
                        type: "FIELD",
                        injectable,
                        depth: depth.split("."),
                        fieldName: "typeProps",
                        fieldValue: toSet,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs style={{ padding: 5 }}>
                  <Grid container wrap="nowrap">
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label={<Trans>Maximum date based on element</Trans>}
                      value={item.endDateMaxDateElement || ""}
                      disabled={disabled || avaliableElements.length === 0}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        delete toSet.phases[index].endDateMaxDate;
                        toSet.phases[index].endDateMaxDateElement =
                          e.target.value;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                    >
                      {avaliableElements.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <IconButton
                      disabled={!item.endDateMaxDateElement}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        delete toSet.phases[index].endDateMaxDateElement;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <ConfigureMultilanguageTextField
              label={<Trans>End date - tooltip</Trans>}
              value={item.endDateTooltip}
              style={{ paddingTop: 10, paddingBottom: 10 }}
              handleChange={(value) => {
                const toSet = { ...typeProps };
                toSet.phases[index].endDateTooltip = value;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: toSet,
                });
              }}
              useDebounce
            />

            <Divider />
          </div>
        ))}
      </div>
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <PdfPropsForm
            typeProps={typeProps}
            dispatch={dispatch}
            injectable={injectable}
            depth={depth}
          />
          <div style={{ padding: 5, marginTop: 10 }}>
            <Typography style={{ marginBottom: 10, fontSize: 16 }}>
              <Trans>Header</Trans>
            </Typography>
            <TextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant="outlined"
              value={pdfFontSize || ""}
              disabled={disabled}
              onChange={(e) => {
                const toSet = { ...typeProps };
                toSet.pdfFontSize = e.target.value;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: toSet,
                });
              }}
              inputProps={{
                maxLength: 2,
              }}
              InputProps={{
                inputComponent: NumberFormatDefault,
              }}
            />
            <FormGroup row>
              {[
                { key: "bold", label: <Trans>Bold</Trans> },
                { key: "italics", label: <Trans>Italics</Trans> },
                { key: "underline", label: <Trans>Underline</Trans> },
              ].map((item) => (
                <FormControlLabel
                  key={item.key}
                  control={
                    <Checkbox
                      checked={pdfHeaderTextProps.includes(item.key)}
                      value={item.key}
                      disabled={disabled}
                      onChange={(e) => {
                        const v = e.target.value;
                        const toSet = { ...typeProps };
                        const newTextProps = [...pdfHeaderTextProps];
                        if (newTextProps.includes(v)) {
                          newTextProps.splice(newTextProps.indexOf(v), 1);
                        } else {
                          newTextProps.push(v);
                        }
                        toSet.pdfHeaderTextProps = newTextProps;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: { ...toSet },
                        });
                      }}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
            <FormColorAutocomplete
              depth={depth}
              disabled={disabled}
              injectable={injectable}
              label={<Trans>Cell background color</Trans>}
              name="pdfBackgroundColor"
              typeProps={typeProps}
              value={pdfBackgroundColor}
            />
          </div>
        </div>
      )}
    </div>
  );
};
