import axios from "axios";
import jsforce from "jsforce";
import { REVIEWER, mapRoles } from "../../auth/authRoles";
import localStorageService from "../localStorageService";
import sfOauthConfig from "./sfAuthConfig";
import { getAccountParsedById, getOrganizations } from "./sfData/sfAccount";
import {
  setAccountOwnershipByFlow,
  setReviewerStatusByFlow,
} from "./sfData/sfContact";
import { getFundingStreamsParsed } from "./sfData/sfFundingStream";
import {
  appSetDefaultAccountTeamThroughFlow,
  getUserGroupMembership,
  getUserParsed,
  saveUser,
} from "./sfData/sfUser";
import { getConfigurationVariablesParsed } from "./sfData/sfVariables";

export const NO_USER = "no_user";
export const NO_ID_PROVIDED = "no id for record was provided";

class SFAuthService {
  auth;
  jsForceOauth;
  user = {
    role: "GRANTEE",
    token: null,
  };

  constructor() {
    this.init();
  }

  init = () => {
    this.jsForceOauth = new jsforce.OAuth2(sfOauthConfig.oauth2);
  };
  setUser = (user) => {
    console.log("sfAuthService set user", user);
    this.user = user;
    localStorageService.setItem("auth_user", user);
  };
  removeUser = () => {
    this.user = null;
    localStorage.removeItem("auth_user");
  };

  getConnection = (user) => {
    if (!user) {
      user = this.user;
    }
    if (user) {
      // console.log('get connection', user)
      let conn = new jsforce.Connection({
        oauth2: sfOauthConfig.oauth2,
        // logLevel: 'DEBUG',
        instanceUrl: sfOauthConfig.instanceUrl,
        accessToken: user.access_token,
        refreshToken: user.refresh_token,
        version: "49.0",
      });
      return conn;
    } else {
      return null;
    }
  };
  doLogin = (state) => {
    window.location = this.jsForceOauth.getAuthorizationUrl({
      response_type: sfOauthConfig.response_type,
      scope: sfOauthConfig.scope,
      state: JSON.stringify(state),
    });
  };

  parseToken = () => {
    let query = new URLSearchParams(window.location.query);
    // http://localhost:3000/?
    // error=OAUTH_APP_ACCESS_DENIED&
    // error_description=user+is+not+admin+approved+to+access+this+app
    // &state=%7B%22uri%22%3A%22%2F%22%7D

    if (query.error === "OAUTH_APP_ACCESS_DENIED") {
      console.error(query.error_description);
      return Promise.reject(query.error);
    }
    let params = new URLSearchParams(window.location.hash.slice(1));
    let access_token = params.get("access_token");
    let refresh_token = params.get("refresh_token");
    let state = JSON.parse(params.get("state"));
    let id = params.get("id");
    console.log(
      "oauth2 redirect data",
      id,
      Object.fromEntries(params),
      access_token,
      refresh_token
    );

    let userId = id ? id.split("/")[5] : "";
    /*
    https://test.salesforce.com/id/00D4m0000008bIYEAY/0054m000000KIPHAA4

    access_token=0
    instance_url=https%3A%2F%2Fchtc-ctlc--pc.my.salesforce.com
    id=https%3A%2F%2Ftest.salesforce.com%2Fid%2F00D4c0000008cutEAA%2F0054c000000JlQbAAK
    issued_at=1590558555169
    signature=j5AdKFvbTdWxY%2BiFIVvs88t0tRt28tV3Z1Q0FsEGB3s%3D
    sfdc_community_url=https%3A%2F%2Fpc-chtc.cs138.force.com%2FAPIConnectionPortal
    sfdc_community_id=0DB4c00000000OwGAI
    state=%7B%22uri%2/2%3A%22%2F%22%7D
    scope=id+api+web
    token_type=Bearer
    */
    if (access_token) {
      let user = {
        id,
        userId,
        access_token,
        refresh_token,
      };
      this.setUser(user);
      return this.getUserInfo(user, state);
    } else {
      this.setUser(null);
      return Promise.reject(NO_USER);
    }
  };

  getUserInfo = (user, state = {}) => {
    // replace host from id from testing to the instance url
    let id_url = new URL(user.id);
    id_url.host = new URL(sfOauthConfig.instanceUrl).host;

    return Promise.all([
      axios.get(id_url.toString(), {
        params: { access_token: user.access_token },
      }),
      getUserParsed(user.userId, true),
      getConfigurationVariablesParsed(),
      sfOauthConfig.isInternal
        ? Promise.resolve([])
        : getOrganizations({
            permissions: {
              TEAM_MEMBERS_FULL: true,
            },
            fields: [
              "Id",
              "Name",
              "BillingState",
              "OwnerId",
              "LastModifiedDate",
              "RecordType.Name",
              "RecordType.Id",
            ],
          }),
      getUserGroupMembership(user.userId),
      getFundingStreamsParsed(),
    ]).then(
      ([
        userInfoResponse,
        userObject,
        variables,
        organizations,
        groupMemberships,
        fundingStreams,
      ]) => {
        let userInfo = userInfoResponse.data;
        const orgList = organizations
          .filter(function (acc) {
            if (acc.RecordType.Name === "Household Account") {
              return false;
            }
            if (acc.AccountTeamMembers) {
              let isMember = false;
              acc.AccountTeamMembers.records.forEach((member) => {
                if (member.UserId === user.userId) {
                  isMember = true;
                }
              });
              return isMember;
            } else {
              return false;
            }
          })
          .map((org) => ({
            id: org.Id,
            province: org.BillingState,
            name: org.Name,
          }));

        console.log("get userInfo", variables, userObject, orgList);
        setAccountOwnershipByFlow(userInfo.user_id, userObject.contactId).catch(
          (error) => {
            console.log("setAccountOwnershipByFlow error", error);
          }
        );
        let role = mapRoles(userObject.role);
        if (role.indexOf(REVIEWER) !== -1) {
          setReviewerStatusByFlow(userInfo.user_id).catch((error) => {
            console.log("setReviewerStatusByFlow error", error);
          });
        } else {
          console.log("check reviewer role", role);
        }
        let getAssociatedOrganizations;
        const currentOrg =
          userObject.associatedOrganizations &&
          organizations.find(
            (orgList) => orgList.Id === userObject.associatedOrganizations
          );
        if (
          !userObject.associatedOrganizations ||
          (currentOrg &&
            String(currentOrg.Name).toLowerCase().includes("duplicate"))
        ) {
          getAssociatedOrganizations = appSetDefaultAccountTeamThroughFlow(
            userInfo.user_id
          )
            .then((ret) => {
              console.log("set default org to ", ret[0].outputValues.result);
              return ret[0].outputValues.result;
            })
            .catch(null);
        } else {
          getAssociatedOrganizations = Promise.resolve(
            userObject.associatedOrganizations
          );
        }

        return getAssociatedOrganizations.then((orgId) => {
          console.log("looking for default org", orgId);
          userObject.associatedOrganizations = orgId;
          const retObject = {
            configuration: variables,
            user: {
              ...user,
              groupMemberships,
              organizationMember: null,
              role,
              email: userInfo.email,
              displayName: userInfo.display_name,
              firstName: userInfo.first_name,
              lastName: userInfo.last_name,
              photoURL: userInfo.photos.picture,
              thumbnailURL: userInfo.photos.thumbnail,
              userId: userInfo.user_id,
              username: userInfo.username,
              language: userObject.language,
              userInfo,
              userObject,
              userFilled: true,
            },
            avaliableOrganizations: [...orgList],
            fundingStreams,
            organization: {},
            state,
          };
          if (!userObject.associatedOrganizations) {
            return retObject;
          }
          return getAccountParsedById(userObject.associatedOrganizations, {
            permissions: { TEAM_MEMBERS: true },
          }).then((acc) => {
            const noValidAccount =
              !acc ||
              !acc.members.find((member) => member.UserId === user.userId);
            if (noValidAccount) {
              return saveUser({
                Id: user.userId,
                Associated_Organizations__c: null,
              }).then((result) => {
                return appSetDefaultAccountTeamThroughFlow(
                  userInfo.user_id
                ).then((ret) => {
                  const newOrgId = ret[0].outputValues.result;
                  return getAccountParsedById(newOrgId, {
                    permissions: { TEAM_MEMBERS: true },
                  }).then((acc) => {
                    delete userObject.associatedOrganizations;
                    retObject.organization = {
                      ...acc,
                    };
                    retObject.user.organizationMember = acc.members.find(
                      (member) => member.UserId === user.userId
                    );
                    return retObject;
                  });
                });
              });
            }
            delete userObject.associatedOrganizations;
            retObject.organization = {
              ...acc,
            };
            retObject.user.organizationMember = acc.members.find(
              (member) => member.UserId === user.userId
            );
            return retObject;
          });
        });
      },
      (reject) => {
        if (reject.message === "Network Error") {
          throw new Error(reject.message);
        }
        setTimeout(() => {
          this.getUserInfo(user, state);
        }, 5000);
      }
    );
  };

  checkLogin = (user) => {
    return this.getUserInfo(user);
  };

  logout = () => {
    let conn = this.getConnection();
    if (!conn) {
      return Promise.reject();
    }
    return conn.logout().then(
      (res) => {
        console.log("logged out user", res);
        this.setUser(null);
      },
      (reject) => {
        this.setUser(null);
      }
    );
  };
}

const instance = new SFAuthService();

export default instance;
