import { Trans } from "@lingui/macro";
import { defaultFormPdfFontFamily } from "app/views/forms/pdf-components/FormPdf";

const styles = {
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    fontSize: 14,
    fontFamily: defaultFormPdfFontFamily,
  },
  section: {
    marginBottom: 8,
  },
  objectiveTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 4,
    marginTop: 4,
  },
};

/**
 * Form element which allows to display objectives updates in Pdf view Editor.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection} connectedObject Connected Salesforce object of type Opportunity.
 */
export const FormObjectivesUpdatePdfEditor = ({
  id,
  title,
  helpText,
  connectedObject,
  value,
  ...props
}) => {
  return (
    <div>
      {title ? <div style={styles.title}>{title}</div> : <div />}

      <div style={styles.section}>
        <div>
          <div style={styles.objectiveTitle}>
            <Trans>FORM_OBJECTIVES_UPDATE_OBJECTIVE_TITLE_PLACEHOLDER</Trans>
          </div>
        </div>
        <div>
          <div style={styles.subtitle}>
            <Trans>Needs</Trans>
          </div>
        </div>
        <div>
          <div style={styles.text}>
            <Trans>FORM_OBJECTIVES_UPDATE_OBJECTIVE_NEEDS_PLACEHOLDER</Trans>
          </div>
        </div>

        <div>
          <div style={styles.text}>
            <Trans>FORM_OBJECTIVES_UPDATE_LAST_REPORT_SUBTITLE</Trans>:{" "}
            <Trans>FORM_OBJECTIVES_UPDATE_PREV_REPORT_DATE_PLACEHOLDER</Trans>
          </div>
          <div style={styles.text}>
            <Trans>
              FORM_OBJECTIVES_UPDATE_PREV_REPORT_IDENTIFIED_NEED_CHANGES_PLACEHOLDER
            </Trans>
          </div>
        </div>

        <div>
          <div>
            <div style={styles.text}>
              <Trans>FORM_OBJECTIVES_UPDATE_IDENTIFIED_NEEDS_CHANGES</Trans>
            </div>
          </div>
          <div>
            <div style={styles.text}>
              <Trans>
                FORM_OBJECTIVES_UPDATE_IDENTIFIED_NEED_CHANGES_PLACEHOLDER
              </Trans>
            </div>
          </div>
        </div>

        <div>
          <div style={styles.subtitle}>
            <Trans>Outcomes</Trans>
          </div>
        </div>
        <div style={styles.text}>
          <Trans>FORM_OBJECTIVES_UPDATE_PREV_REPORT_OUTCOMES_PLACEHOLDER</Trans>
        </div>

        <div>
          <div style={styles.text}>
            <Trans>FORM_OBJECTIVES_UPDATE_LAST_REPORT_SUBTITLE</Trans>:{" "}
            <Trans>FORM_OBJECTIVES_UPDATE_PREV_REPORT_DATE_PLACEHOLDER</Trans>
          </div>
          <div style={styles.text}>
            <Trans>
              FORM_OBJECTIVES_UPDATE_PREV_REPORT_DESIRED_OUTCOME_CHANGES_PLACEHOLDER
            </Trans>
          </div>
        </div>

        <div>
          <div>
            <div style={styles.text}>
              <Trans>FORM_OBJECTIVES_UPDATE_DESIRED_OUTCOME_CHANGES</Trans>
            </div>
          </div>
          <div>
            <div style={styles.text}>
              <Trans>
                FORM_OBJECTIVES_UPDATE_DESIRED_OUTCOME_CHANGES_PLACEHOLDER
              </Trans>
            </div>
          </div>
        </div>

        <div>
          <div style={styles.text}>
            <Trans>FORM_OBJECTIVES_UPDATE_LAST_REPORT_SUBTITLE</Trans>:{" "}
            <Trans>FORM_OBJECTIVES_UPDATE_PREV_REPORT_DATE_PLACEHOLDER</Trans>
          </div>
          <div style={styles.text}>
            <Trans>
              FORM_OBJECTIVES_UPDATE_PREV_REPORT_ACTUAL_OUTCOMES_PLACEHOLDER
            </Trans>
          </div>
        </div>

        <div style={styles.text}>
          <Trans>FORM_OBJECTIVES_UPDATE_ACTUAL_OUTCOMES</Trans>
        </div>
        <div style={styles.text}>
          <Trans>FORM_OBJECTIVES_UPDATE_ACTUAL_OUTCOMES_PLACEHOLDER</Trans>
        </div>
      </div>
    </div>
  );
};
