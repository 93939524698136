import { t } from "@lingui/macro";
import { EgretLoadable } from "egret";
import { myI18n } from "translation/I18nConnectedProvider";
import { authRoles } from "../../auth/authRoles";
import { internalEnvs } from "../../services/sfAuth/sfAuthConfig";
import GenerateFillablePDFForm from "./screens/GenerateFillablePDFForm";

const ProgramManagerApplicationList = EgretLoadable({
  loader: () => import("./ProjectManagerApplicationList"),
});

const BudgetEmbedded = EgretLoadable({
  loader: () => import("./BudgetEmbedded"),
});

const ExportSurveys = EgretLoadable({
  loader: () => import("./ExportSurveys"),
});

const ExportImportObjects = EgretLoadable({
  loader: () => import("./ExportImportObjects"),
});

const TranslationsEditor = EgretLoadable({
  loader: () => import("./TranslationsEditor"),
});

const FormConfigurablePrintView = EgretLoadable({
  loader: () => import("./FormConfigurablePrintView"),
});

const ConfigurePortalConfigurations = EgretLoadable({
  loader: () => import("./ConfigurePortalConfigurations"),
});

const ConfigureFundingStreams = EgretLoadable({
  loader: () => import("./funding-streams/ConfigureFundingStreams"),
});

const TechnicalAdvisoryRanking = EgretLoadable({
  loader: () => import("./TechnicalAdvisoryRanking"),
});

const Contract = EgretLoadable({
  loader: () => import("./Contract"),
});

const InternalHelp = EgretLoadable({
  loader: () => import("./InternalHelp"),
});

const ConfigureUserPermissions = EgretLoadable({
  loader: () => import("./ConfigureUserPermissions"),
});

const AdminMultiuserPanel = EgretLoadable({
  loader: () => import("../forms/multiuser/admin/AdminMultiuserPanel"),
});

const PageTranslations = EgretLoadable({
  loader: () => import("./PageTranslations"),
});

const internalRoutes = [
  {
    path: "/internal/project-schedule/:id",
    component: EgretLoadable({
      loader: () => import("./GranteeReportEditor"),
    }),
    auth: authRoles.pm,
    isTranslatable: true,
    label: myI18n._(t`Program Manager Schedule Editor`),
  },
  {
    path: "/internal/TranslationsEditor",
    component: TranslationsEditor,
    auth: authRoles.translator,
    isTranslatable: true,
    label: myI18n._(t`Program Manager Schedule Editor`),
  },
  {
    path: "/internal/ProgramManagerApplicationList",
    component: ProgramManagerApplicationList,
    auth: authRoles.pm,
    isTranslatable: true,
    label: myI18n._(t`Program Manager Applications List`),
  },
  {
    path: "/internal/contract/:id",
    component: Contract,
    env: internalEnvs,
    auth: authRoles.pm,
    isTranslatable: true,
    label: myI18n._(t`Contract`),
  },
  {
    path: "/internal/TechnicalAdvisoryRanking",
    component: TechnicalAdvisoryRanking,
    auth: authRoles.pm,
    isTranslatable: true,
    label: myI18n._(t`Technical Advisory Ranking`),
  },
  {
    path: "/internal/widgets/budget/:id",
    component: BudgetEmbedded,
    auth: authRoles.pm,
    isTranslatable: true,
    label: myI18n._(t`Budget view`),
  },
  {
    path: "/internal/ExportSurveys",
    component: ExportSurveys,
    auth: authRoles.pm,
    isTranslatable: true,
    label: myI18n._(t`Export Surveys`),
  },
  {
    path: "/internal/InternalHelp",
    component: InternalHelp,
    auth: authRoles.admin,
  },
  {
    path: "/internal/create-form-preview-url",
    component: FormConfigurablePrintView,
    auth: authRoles.editor,
    isTranslatable: true,
    label: myI18n._(t`Program Manager Applications List`),
  },
  {
    path: "/internal/ConfigureFundingStreams",
    component: ConfigureFundingStreams,
    auth: authRoles.admin,
    isTranslatable: true,
    label: myI18n._(t`Configure Funding Streams`),
  },
  {
    path: "/internal/PortalConfigurations",
    component: ConfigurePortalConfigurations,
    auth: authRoles.admin,
    isTranslatable: true,
    label: myI18n._(t`Portal Configurations`),
  },
  {
    path: "/internal/ConfigureUserPermissions",
    component: ConfigureUserPermissions,
    auth: authRoles.admin,
    isTranslatable: true,
    label: myI18n._(t`Configure User Permissions`),
  },
  {
    path: "/internal/page-translations",
    component: PageTranslations,
    auth: authRoles.admin,
  },
  {
    path: "/internal/ExportImportObjects",
    component: ExportImportObjects,
    auth: authRoles.admin,
  },
  {
    path: "/internal/active-sessions",
    component: AdminMultiuserPanel,
    auth: authRoles.admin,
  },
  {
    path: "/internal/generate-pdf-for-application",
    component: GenerateFillablePDFForm,
    auth: authRoles.admin,
  },
];

export default internalRoutes;
