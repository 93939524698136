import { Trans } from "@lingui/macro";
import { Checkbox, FormControlLabel, FormGroup, Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DebouncedTextField from "app/views/forms/common/DebouncedTextField";
import { FormColorAutocomplete } from "app/views/forms/common/FormColorAutocomplete";
import { FormConnectToObject } from "app/views/forms/common/FormConnectToObject";
import { PdfPropsForm } from "app/views/forms/common/PdfPropsForm";
import ConfigureMultilanguageTextField from "app/views/internal/ConfigureMultilanguageTextField";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { NumberFormatDefault } from "../../../common/Common";
import FormObjectiveItem from "./FormObjectiveItem";
import { FormObjectives } from "./FormObjectives";

export const FormEditorObjectives = ({
  editMode,
  showPrintProps,
  showPdfProps,
  typeProps,
  depth,
  injectable,
  injectableId,
  disabled,
  ...props
}) => {
  const {
    pdfHeaderTextProps = [],
    pdfBackgroundColor,
    pdfFontSize,
    options = [],
    displayNeedsInPDF,
  } = typeProps;
  const dispatch = useDispatch();
  const { values } = useFormikContext();

  if (!editMode) {
    return <FormObjectives editMode typeProps={typeProps} {...props} />;
  }

  let avaliableRecordTypes = [];
  let avaliableObjectives = [];
  values.objects.forEach((object) => {
    if (object.name === "Opportunity" && object.recordTypes) {
      avaliableRecordTypes = Object.values(object.recordTypes)
        .filter((recordType) => recordType.active)
        .map((recordType) => ({
          value: recordType.recordTypeId,
          label: recordType.name,
        }));
    }
    if (object.name === "Objective__c") {
      object.fields.some((field) => {
        const bool = field.name === "Objective__c";
        if (bool) {
          avaliableObjectives = field.picklistValues.filter(
            (value) => value.active
          );
        }
        return bool;
      });
    }
  });

  return (
    <div>
      {[
        { label: <Trans>Needs field</Trans>, key: "needs" },
        { label: <Trans>Outcomes field</Trans>, key: "outcomes" },
      ].map((obj, index) => {
        const { label, key } = obj;
        return (
          <div key={index}>
            <h6 style={{ marignTop: 5, marginBottom: 5 }}>{label}</h6>
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      checked={Boolean(typeProps[key + "Required"])}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        toSet[key + "Required"] = e.target.checked;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                    />
                  }
                  label={<Trans>Is required?</Trans>}
                />
              </Grid>
              <Grid item>
                <DebouncedTextField
                  style={{ marginTop: 5, marginBottom: 5, marginRight: 10 }}
                  label={<Trans>Characters limit</Trans>}
                  value={typeProps[key + "MaxChar"]}
                  InputProps={{ inputComponent: NumberFormatDefault }}
                  variant="outlined"
                  disabled={disabled}
                  onChange={(e) => {
                    const toSet = { ...typeProps };
                    toSet[key + "MaxChar"] = e.target.value;
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: { ...toSet },
                    });
                  }}
                />
              </Grid>
              <Grid item>
                <DebouncedTextField
                  style={{ marginTop: 5, marginBottom: 5, marginRight: 10 }}
                  label={<Trans>Words limit</Trans>}
                  value={typeProps[key + "MaxWords"]}
                  InputProps={{ inputComponent: NumberFormatDefault }}
                  variant="outlined"
                  disabled={disabled}
                  onChange={(e) => {
                    const toSet = { ...typeProps };
                    toSet[key + "MaxWords"] = e.target.value;
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: { ...toSet },
                    });
                  }}
                />
              </Grid>
              <Grid item>
                <DebouncedTextField
                  style={{ marginTop: 5, marginBottom: 5 }}
                  label={<Trans>Rows</Trans>}
                  value={typeProps[key + "Rows"] || ""}
                  InputProps={{ inputComponent: NumberFormatDefault }}
                  variant="outlined"
                  disabled={disabled}
                  onChange={(e) => {
                    const toSet = { ...typeProps };
                    toSet[key + "Rows"] = e.target.value;
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: { ...toSet },
                    });
                  }}
                />
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(typeProps[key + "Expandable"])}
                      disabled={disabled}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        toSet[key + "Expandable"] = e.target.checked;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: { ...toSet },
                        });
                      }}
                    />
                  }
                  label={<Trans>Allow expanding rows?</Trans>}
                />
              </Grid>
            </Grid>
            <ConfigureMultilanguageTextField
              value={typeProps[key + "HelpText"]}
              label={<Trans>Help text</Trans>}
              style={{ paddingTop: 10, paddingBottom: 10 }}
              handleChange={(value) => {
                const toSet = { ...typeProps };
                toSet[key + "HelpText"] = value;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: { ...toSet },
                });
              }}
              useDebounce
            />
          </div>
        );
      })}

      <div>
        <Grid container alignItems="center" style={{ marginTop: 10 }}>
          <Grid item>
            <h6 style={{ marignTop: 5, marginBottom: 5 }}>
              <Trans>Objective options</Trans>
            </h6>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
              disabled={disabled}
              onClick={(e) => {
                const toSet = { ...typeProps };
                const newOptions = [...options];
                newOptions.push({
                  value: "",
                  recordTypes: [],
                  labels: {},
                });
                toSet.options = newOptions;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: toSet,
                });
              }}
            >
              <Trans>Add option</Trans>
              <Icon style={{ marginLeft: 5 }}>add</Icon>
            </Button>
          </Grid>
        </Grid>
        {options.map((item, index) => {
          return (
            <FormObjectiveItem
              key={`${item.value}-${index}`}
              item={item}
              index={index}
              typeProps={typeProps}
              dispatch={dispatch}
              injectable={injectable}
              depth={depth}
              disabled={disabled}
              avaliableObjectives={avaliableObjectives}
              avaliableRecordTypes={avaliableRecordTypes}
              options={options}
            />
          );
        })}
      </div>
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <PdfPropsForm
            typeProps={typeProps}
            dispatch={dispatch}
            injectable={injectable}
            depth={depth}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(displayNeedsInPDF)}
                disabled={disabled}
                onChange={(e) => {
                  const toSet = { ...typeProps };
                  toSet.displayNeedsInPDF = e.target.checked;
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: { ...toSet },
                  });
                }}
              />
            }
            label={<Trans>SHOW_NEEDS_COLUMN_IN_PDF</Trans>}
          />
          <div style={{ padding: 5, marginTop: 10 }}>
            <Typography style={{ marginBottom: 10, fontSize: 16 }}>
              <Trans>Header</Trans>
            </Typography>
            <DebouncedTextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant="outlined"
              disabled={disabled}
              value={pdfFontSize || ""}
              onChange={(e) => {
                const toSet = { ...typeProps };
                toSet.pdfFontSize = e.target.value;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: toSet,
                });
              }}
              inputProps={{
                maxLength: 2,
              }}
              InputProps={{
                inputComponent: NumberFormatDefault,
              }}
            />
            <FormGroup row>
              {[
                { key: "bold", label: <Trans>Bold</Trans> },
                { key: "italics", label: <Trans>Italics</Trans> },
                { key: "underline", label: <Trans>Underline</Trans> },
              ].map((item) => (
                <FormControlLabel
                  key={item.key}
                  control={
                    <Checkbox
                      checked={pdfHeaderTextProps.includes(item.key)}
                      value={item.key}
                      disabled={disabled}
                      onChange={(e) => {
                        const v = e.target.value;
                        const toSet = { ...typeProps };
                        const newTextProps = [...pdfHeaderTextProps];
                        if (newTextProps.includes(v)) {
                          newTextProps.splice(newTextProps.indexOf(v), 1);
                        } else {
                          newTextProps.push(v);
                        }
                        toSet.pdfHeaderTextProps = newTextProps;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: { ...toSet },
                        });
                      }}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
            <FormColorAutocomplete
              disabled={disabled}
              injectable={injectable}
              depth={depth}
              label={<Trans>Cell background color</Trans>}
              name="pdfBackgroundColor"
              typeProps={typeProps}
              value={pdfBackgroundColor}
            />
          </div>
        </div>
      )}
    </div>
  );
};
