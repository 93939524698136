import { Trans } from "@lingui/macro";
import { getLabelFromTranslationData } from "app/views/common/TranslationsCommon";

const keyToLabel = {
  comments: <Trans>Comments</Trans>,
  primaryActivities: <Trans>Primary Activities</Trans>,
};

export const FormMilestonesError = (error, { item, langVersion }) => {
  const { typeProps } = item;
  const phases = typeProps.phases || [];
  if (Array.isArray(error)) {
    return {
      toMap: error
        .map((item, index) => {
          if (!item) {
            return null;
          }
          const label = getLabelFromTranslationData({
            data: phases[index]?.label,
            langVersion,
          });
          return (
            <span key={index}>
              <u>
                {index + 1}
                {". "}
                {label}
              </u>
              <ul key={index}>
                {Object.entries(item).map(([key, value]) => {
                  return (
                    <li
                      key={key}
                      className="m-0 text-muted"
                      style={{ maxWidth: "771px" }}
                    >
                      {keyToLabel[key]}
                      {": "}
                      {value}
                    </li>
                  );
                })}
              </ul>
            </span>
          );
        })
        .filter((v) => v),
    };
  } else {
    return error;
  }
};
