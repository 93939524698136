import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

export const FormTitle = ({ title, children }) => (
  <div
    style={{
      margin: "5px 0px",
      borderRadius: 4,
      padding: 15,
    }}
  >
    <Typography
      variant="h2"
      style={{
        paddingLeft: 35,
        marginTop: 0,
        display: "flex",
        alignItems: "center",
      }}
    >
      <DoubleArrowIcon style={{ paddingRight: 10 }} />
      <Grid container item>
        <Grid item xs>
          <Typography variant='h2'>{title}</Typography>
        </Grid>
        <Grid item style={{ paddingLeft: 18 }}>
          {children}
        </Grid>
      </Grid>
    </Typography>
  </div>
);
