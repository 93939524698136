import { Trans } from "@lingui/macro";
import {
  Button,
  Card,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { DateTimePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import {
  dateFormat,
  datetimeFormat,
  portalLanguagesData,
} from "app/appSettings";
import { setConfigurationData } from "app/redux/actions/ConfigurationActions";
import sfOauthConfig from "app/services/sfAuth/sfAuthConfig";
import {
  deleteApplicationConfig,
  getConfigurations,
  getFormPages,
  saveApplicationConfigWithUpdateByFlow,
} from "app/services/sfAuth/sfData/sfForms";
import { getRecordTypes } from "app/services/sfAuth/sfData/sfOpportunity";
import {
  describe,
  findSurveyTemplatesWithChildren,
} from "app/services/sfAuth/sfData/sfSurvey";
import Loading from "egret/components/EgretLoadable/Loading";
import { FastField, Field, Formik } from "formik";
import {
  FormikCheckboxField,
  FormikCheckboxGroupField,
} from "formik-material-fields";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmptyLangValues, isValidJSON } from "utils";
import { checkPromiseAllSuccess } from "../../services/sfAuth/sfData/jsConnectionUtils";
import { FormikFastTextField } from "../common-components/FormikFastTextField";
import { getLabelFromTranslationData } from "../common/TranslationsCommon";
import ProgressSnackbar from "../page-layouts/CustomSnackbars";
import { filterConfigurations } from "../utilities/configuration";
import MultilanguageTextFieldWithFormik from "./MultilanguageTextFieldWithFormik";

const useStyles = makeStyles((theme) => ({
  FormGroup_grid: {
    flexDirection: "row",
    marginLeft: 25,
    justifyContent: "space-between",
  },
  FormControlLabel_grid: {
    width: "45%",
  },
  HelperText_root: {
    marginLeft: 10,
    marginBottom: 8,
    marginTop: 18,
    fontSize: 14,
    lineHeight: 1,
  },
  MuiCheckbox_root: {
    padding: 3,
  },
}));

export const matchOpportunityWithConfiguration = ({
  configurations = [],
  fundingStreamId,
  recordTypeId,
}) => {
  let toRet;
  if (fundingStreamId) {
    configurations.some((config) => {
      if (config.fundingStream === fundingStreamId) {
        toRet = config;
        return true;
      }
      return false;
    });
  }
  if (!toRet) {
    configurations.some((config) => {
      if (config.recordType === recordTypeId) {
        toRet = config;
        return true;
      }
      return false;
    });
  }
  return toRet;
};

export const convertTranslationsIfNeeded = (config) => {
  if (config && !config.hasOwnProperty("subtitle")) {
    const updatedConfig = { ...config };
    const translationTexts = ["name", "description", "moreInfoUrl", "subtitle"];
    const languages = ["en", "fr"];
    translationTexts.forEach((text) => {
      updatedConfig[text] = getEmptyLangValues();
      languages.forEach((lang) => {
        const property = text + lang.toUpperCase();
        if (updatedConfig.hasOwnProperty(property)) {
          const translation = updatedConfig[property];
          if (translation) {
            updatedConfig[text] = {
              ...updatedConfig[text],
              [lang]: translation,
            };
          }
        }
      });
    });
    return updatedConfig;
  }
  return config;
};

const ConfigureApplicationTypes = () => {
  const lang = useSelector((state) => state.user.language);
  const [formsData, setFormsData] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [saving, setSaving] = React.useState(false);
  const [recordTypes, setRecordTypes] = React.useState([]);
  const [objectiveRecordTypes, setObjectiveRecordTypes] = React.useState([]);
  const [surveyTemplates, setSurveyTemplates] = React.useState([]);
  const fundingStreams = useSelector((state) => state.fundingStreams.streams);
  const [technicalAdvisoryCategories, setTechnicalAdvisoryCategories] =
    React.useState([]);
  const classes = useStyles();
  const configurations = useSelector((state) => state.configuration);
  const dispatch = useDispatch();

  console.log("configurations: ", configurations, fundingStreams);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [lang]);

  const fetchData = () => {
    return Promise.all([
      getFormPages(),
      getConfigurations(),
      getRecordTypes("Opportunity"),
      getRecordTypes("Objective__c"),
      findSurveyTemplatesWithChildren({
        language: lang,
      }),
      sfOauthConfig.isIcce && describe("TechnicalAdvisoryAssignment__c"),
    ]).then(
      ([
        forms,
        configurations,
        recordTypes,
        objectiveRecordTypes,
        surveyTemplates,
        technicalAdvisoryDescribeResult,
      ]) => {
        console.log("got survey tempaltes", surveyTemplates);
        console.log("got forms", forms);
        console.log("got configurations", configurations);
        console.log("got record types", recordTypes, objectiveRecordTypes);
        let array = [];
        if (sfOauthConfig.isIcce) {
          technicalAdvisoryDescribeResult.fields.forEach((field) => {
            if (field.type === "number" || field.type === "double") {
              array.push({ label: field.label, value: field.name });
            }
          });
          setTechnicalAdvisoryCategories(array);
        }
        setSurveyTemplates(
          Object.values(surveyTemplates).map((obj) => ({
            id: obj.id,
            name: obj.name,
          }))
        );
        setRecordTypes(
          Object.keys(recordTypes).map((key) => {
            const item = recordTypes[key];
            return {
              id: item.recordTypeId,
              name: item.name,
            };
          })
        );
        setObjectiveRecordTypes(
          Object.keys(objectiveRecordTypes).map((key) => {
            const item = objectiveRecordTypes[key];
            return {
              id: item.recordTypeId,
              name: item.name,
            };
          })
        );
        const formsCount = {};
        forms.forEach((form) => {
          if (form.origin) {
            if (formsCount[form.origin]) {
              formsCount[form.origin].push(form);
            } else {
              formsCount[form.origin] = [form];
            }
          }
        });
        setFormsData(
          forms
            .map((obj) => {
              let name = obj.name[lang] || "";
              if (obj.config.version && formsCount[obj.origin]) {
                name += " [v." + obj.config.version + "]";
              }
              if (obj.comments) {
                name += " (" + obj.comments + ")";
              }
              return {
                name,
                id: obj.id,
              };
            })
            .filter((name) => name)
            .sort((a, b) => String(a.name).localeCompare(b.name))
        );
        setInitialValues({
          configList: filterConfigurations(configurations)
            .map((item, index) => {
              let data = {};
              if (isValidJSON(item.Value__c)) {
                data = JSON.parse(item.Value__c) || {};
              }
              data = convertTranslationsIfNeeded(data);
              if (data.startTime) {
                const localValue = moment(data.startTime).utc(true);
                data.startTime = localValue;
              }
              if (data.endTime) {
                const localValue = moment(data.endTime).utc(true);
                data.endTime = localValue;
              }
              // if (!data.nameEN) {
              //   data.nameEN = item.Name
              // }
              if (!data.name["en"]) {
                data.name["en"] = item.Name;
              }
              delete data.expanded;
              if (!data.disableInProvinces) {
                data.disableInProvinces = [];
              }
              if (!data.avaliableInLanguages) {
                data.avaliableInLanguages = ["en", "fr"];
              }
              let order = data.order;
              if (!order && order !== 0) {
                order = index;
              }
              return {
                technicalAdvisoryScoringCategories: [],
                id: item.Id,
                ...data,
                order,
              };
            })
            .sort((a, b) => a.order - b.order),
        });
        setLoading(false);
      }
    );
  };

  const handleSave = (values) => {
    const savingSnackbar = enqueueSnackbar(null, {
      persist: true,
      content: (key) => ProgressSnackbar(<Trans>Saving</Trans>),
    });
    setSaving(true);
    return saveApplicationConfigWithUpdateByFlow(values).then(
      (result) => {
        console.log("saved configuration", result);
        if (!checkPromiseAllSuccess(result)) {
          return fetchData().then(() => {
            setSaving(false);
            closeSnackbar(savingSnackbar);
            enqueueSnackbar(<Trans>Successfully saved!</Trans>, {
              variant: "success",
            });
            const toSet = { ...configurations };
            toSet.AVAILABLE_APPLICATIONS = values.configList;
            dispatch(setConfigurationData(toSet));
          });
        } else {
          enqueueSnackbar(<Trans>Error occurred while saving!</Trans>, {
            variant: "error",
          });
          setSaving(false);
          closeSnackbar(savingSnackbar);
          return Promise.reject();
        }
      },
      (reject) => {
        console.error("rejected configuration", reject);
        setSaving(false);
        closeSnackbar(savingSnackbar);
        enqueueSnackbar(<Trans>Error occurred while saving!</Trans>, {
          variant: "error",
        });
      }
    );
  };

  if (loading || !initialValues) {
    return <Loading />;
  }

  const { formatToTimeZone } = require("date-fns-timezone");

  return (
    <Formik enableReinitialize initialValues={initialValues}>
      {({ values, setValues, setFieldValue }) => {
        const { configList } = values;

        return (
          <div style={{ padding: 20 }}>
            <Grid container direction="row" justify="space-between">
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  setFieldValue("configList", [
                    ...configList,
                    {
                      disableInProvinces: [],
                      avaliableInLanguages: [],
                      technicalAdvisoryScoringCategories: [],
                      type: "Application Type",
                    },
                  ]);
                }}
              >
                <Trans>Add new configuration</Trans>
                <Icon>add</Icon>
              </Button>

              <Button
                disabled={saving}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSave(values);
                }}
              >
                <Trans>Save</Trans>
                <Icon style={{ marginLeft: 5 }}>save</Icon>
              </Button>
            </Grid>
            <Alert severity="info" style={{ marginTop: 10 }}>
              <Trans>
                Applications in create application dialog will appear in order
                in which they are sorted on this list
              </Trans>
            </Alert>
            <List>
              {configList.map((item, index) => {
                const { expanded, type } = item;
                const appType = type === "Application Type";
                return (
                  <ListItem key={index}>
                    <Card
                      style={{
                        flexGrow: 1,
                        padding: 20,
                        border: "1px solid #C4C4C4",
                      }}
                    >
                      <Grid justify="space-between" container wrap="nowrap">
                        <IconButton
                          onClick={() => {
                            setFieldValue(
                              `configList.${index}.expanded`,
                              !expanded
                            );
                          }}
                        >
                          <Icon>
                            {expanded ? "expand_less" : "expand_more"}
                          </Icon>
                        </IconButton>

                        <Grid item>
                          <IconButton
                            disabled={index === 0}
                            onClick={() => {
                              const toSet = [...configList];
                              const toMove = toSet[index];
                              const toReplace = toSet[index - 1];
                              toSet[index - 1] = toMove;
                              toSet[index] = toReplace;
                              setFieldValue("configList", toSet);
                            }}
                          >
                            <Icon>arrow_upward</Icon>
                          </IconButton>
                          <IconButton
                            disabled={index + 1 === configList.length}
                            onClick={() => {
                              const toSet = [...configList];
                              const toMove = toSet[index];
                              const toReplace = toSet[index + 1];
                              toSet[index + 1] = toMove;
                              toSet[index] = toReplace;
                              setFieldValue("configList", toSet);
                            }}
                          >
                            <Icon>arrow_downward</Icon>
                          </IconButton>
                          <DeleteConfigurationButton
                            handleDelete={(e) => {
                              const toDelete = configList[index];
                              const toSet = [...configList];
                              if (!toDelete.id) {
                                toSet.splice(index, 1);
                                setFieldValue("configList", toSet);
                                enqueueSnackbar(
                                  <Trans>Configuration deleted!</Trans>,
                                  {
                                    variant: "success",
                                  }
                                );
                                return Promise.resolve();
                              } else {
                                return deleteApplicationConfig(
                                  toDelete.id
                                ).then(
                                  (result) => {
                                    return fetchData().then(() => {
                                      enqueueSnackbar(
                                        <Trans>Configuration deleted!</Trans>,
                                        {
                                          variant: "success",
                                        }
                                      );
                                      toSet.splice(index, 1);
                                      setFieldValue("configList", toSet);
                                    });
                                  },
                                  (reject) => {
                                    enqueueSnackbar(
                                      <Trans>
                                        Error occurred while deleting!
                                      </Trans>,
                                      {
                                        variant: "error",
                                      }
                                    );
                                  }
                                );
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <MultilanguageTextFieldWithFormik
                        name={`configList.${index}.name`}
                        label={<Trans>Name</Trans>}
                        style={{ padding: 5 }}
                        inputDebounce
                      />
                      <MultilanguageTextFieldWithFormik
                        name={`configList.${index}.subtitle`}
                        label={<Trans>Subtitle</Trans>}
                        style={{ padding: 5 }}
                        inputDebounce
                      />
                      <MultilanguageTextFieldWithFormik
                        name={`configList.${index}.description`}
                        label={<Trans>Description</Trans>}
                        style={{ padding: 5 }}
                        inputDebounce
                      />

                      <Grid item style={{ padding: 5 }}>
                        <FormikFastTextField
                          name={`configList.${index}.notes`}
                          label={<Trans>Notes</Trans>}
                        />
                      </Grid>

                      <Collapse in={expanded}>
                        <Grid container direction="column">
                          <Field name={`configList.${index}.form`}>
                            {({ field, form: { setFieldValue } }) => {
                              return (
                                <Grid container wrap="nowrap">
                                  <TextField
                                    {...field}
                                    value={field.value || ""}
                                    label={<Trans>Form</Trans>}
                                    style={{ margin: 5 }}
                                    select
                                    fullWidth
                                    variant="outlined"
                                  >
                                    {formsData.map((item, index) => (
                                      <MenuItem key={index} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <IconButton
                                    onClick={(e) => {
                                      setFieldValue(
                                        `configList.${index}.form`,
                                        null
                                      );
                                    }}
                                  >
                                    <Icon>close</Icon>
                                  </IconButton>
                                </Grid>
                              );
                            }}
                          </Field>

                          {appType && (
                            <div style={{ width: "100%" }}>
                              <FormSelectField
                                label={<Trans>Quiz</Trans>}
                                name={`configList.${index}.quiz`}
                                formsData={formsData}
                              />

                              <FormSelectField
                                label={<Trans>Print form</Trans>}
                                name={`configList.${index}.printForm`}
                                formsData={formsData}
                              />

                              {/* <Typography
                                style={{ padding: 10, fontWeight: 'bold' }}
                              >
                                <Trans>Grantee reports</Trans>
                              </Typography>

                              <FormSelectField
                                label={
                                  <Trans>
                                    Grantee report application preview form
                                  </Trans>
                                }
                                name={`configList.${index}.granteeReportForm`}
                                formsData={formsData}
                              />

                              <FormSelectField
                                label={
                                  <Trans>
                                    Grantee report survey form (testing)
                                  </Trans>
                                }
                                name={`configList.${index}.granteeReportSurveyForm`}
                                formsData={formsData}
                              />

                              <FormSelectField
                                label={
                                  <Trans>
                                    Grantee progress report survey template
                                  </Trans>
                                }
                                name={`configList.${index}.granteeProgressReportSurveyTemplate`}
                                formsData={surveyTemplates}
                              />

                              <FormSelectField
                                label={
                                  <Trans>
                                    Grantee final report survey template
                                  </Trans>
                                }
                                name={`configList.${index}.granteeFinalReportSurveyTemplate`}
                                formsData={surveyTemplates}
                              /> */}

                              <Typography
                                style={{ padding: 10, fontWeight: "bold" }}
                              >
                                <Trans>Program Manager</Trans>
                              </Typography>

                              <FormSelectField
                                label={
                                  <Trans>Applications list print preview</Trans>
                                }
                                name={`configList.${index}.internalPrintPreview`}
                                formsData={formsData}
                              />

                              <FormSelectField
                                label={<Trans>Contract</Trans>}
                                name={`configList.${index}.contract`}
                                formsData={formsData}
                              />
                              <FormSelectField
                                label={
                                  <Trans>Contract opportunity preview</Trans>
                                }
                                name={`configList.${index}.previewPM`}
                                formsData={formsData}
                              />
                              <FormSelectField
                                label={
                                  <Trans>Selection Committee preview</Trans>
                                }
                                name={`configList.${index}.SC`}
                                formsData={formsData}
                              />
                              <FormSelectField
                                label={
                                  <Trans>Allocation Committee preview</Trans>
                                }
                                name={`configList.${index}.AC`}
                                formsData={formsData}
                              />
                              <FormSelectField
                                label={
                                  <Trans>Board executive summary preview</Trans>
                                }
                                name={`configList.${index}.Board`}
                                formsData={formsData}
                              />
                              <FormSelectField
                                label={
                                  <Trans>CMHC executive summary preview</Trans>
                                }
                                name={`configList.${index}.CMHC`}
                                formsData={formsData}
                              />

                              <Typography
                                style={{ padding: 10, fontWeight: "bold" }}
                              >
                                <Trans>Record Types</Trans>
                              </Typography>

                              <FormSelectField
                                label={<Trans>Funding Stream</Trans>}
                                name={`configList.${index}.fundingStream`}
                                formsData={fundingStreams.map((obj) => ({
                                  id: obj.id,
                                  name: getLabelFromTranslationData({
                                    data: obj.fullName,
                                    langVersion: lang,
                                  }),
                                }))}
                              />

                              <div style={{ padding: 5 }}>
                                <FormikFastTextField
                                  name={`configList.${index}.recordType`}
                                  label={<Trans>Record type</Trans>}
                                  select
                                >
                                  {recordTypes.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </FormikFastTextField>
                              </div>

                              <div style={{ padding: 5 }}>
                                <FormikFastTextField
                                  name={`configList.${index}.objectiveRecordType`}
                                  label={<Trans>Objective record type</Trans>}
                                  style={{ marginBottom: 10 }}
                                  select
                                >
                                  {objectiveRecordTypes.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </FormikFastTextField>
                              </div>
                            </div>
                          )}

                          <div>
                            <FormikCheckboxGroupField
                              label={<Trans>Disable in provinces</Trans>}
                              style={{ padding: 10, width: "100%" }}
                              name={`configList.${index}.disableInProvinces`}
                              multiple
                              FormGroupProps={{
                                classes: {
                                  root: classes.FormGroup_grid,
                                },
                              }}
                              FormControlLabelProps={{
                                classes: {
                                  root: classes.FormControlLabel_grid,
                                },
                              }}
                              CheckboxProps={{
                                classes: {
                                  root: classes.MuiCheckbox_root,
                                },
                              }}
                              FormHelperTextProps={{
                                classes: {
                                  root: classes.HelperText_root,
                                },
                              }}
                              options={[
                                {
                                  label: <Trans>Alberta</Trans>,
                                  value: "Alberta",
                                },
                                {
                                  label: <Trans>British Columbia</Trans>,
                                  value: "British Columbia",
                                },
                                {
                                  label: <Trans>Manitoba</Trans>,
                                  value: "Manitoba",
                                },
                                {
                                  label: <Trans>New Brunswick</Trans>,
                                  value: "New Brunswick",
                                },
                                {
                                  label: (
                                    <Trans>Newfoundland and Labrador</Trans>
                                  ),
                                  value: "Newfoundland and Labrador",
                                },
                                {
                                  label: <Trans>Northwest Territories</Trans>,
                                  value: "Northwest Territories",
                                },
                                {
                                  label: <Trans>Nova Scotia</Trans>,
                                  value: "Nova Scotia",
                                },
                                {
                                  label: <Trans>Nunavut</Trans>,
                                  value: "Nunavut",
                                },
                                {
                                  label: <Trans>Ontario</Trans>,
                                  value: "Ontario",
                                },
                                {
                                  label: <Trans>Prince Edward Island</Trans>,
                                  value: "Prince Edward Island",
                                },
                                {
                                  label: <Trans>Québec</Trans>,
                                  value: "Québec",
                                },
                                {
                                  label: <Trans>Saskatchewan</Trans>,
                                  value: "Saskatchewan",
                                },
                                {
                                  label: <Trans>Yukon</Trans>,
                                  value: "Yukon",
                                },
                              ]}
                            />
                          </div>

                          <div>
                            <FormikCheckboxGroupField
                              label={<Trans>Avaiable in languages</Trans>}
                              style={{ padding: 10, width: "100%" }}
                              name={`configList.${index}.avaliableInLanguages`}
                              multiple
                              row
                              options={Object.values(portalLanguagesData).map(
                                (obj) => ({
                                  label: obj.labelTrans,
                                  value: obj.editKey,
                                })
                              )}
                            />
                          </div>

                          <div style={{ padding: 5 }}>
                            <b>
                              <Trans>Your time zone:</Trans>
                            </b>{" "}
                            {Intl.DateTimeFormat().resolvedOptions().timeZone}
                            {" ("}
                            {new Date().getTimezoneOffset()}
                            {")"}
                          </div>

                          <Grid container>
                            <Grid xs={6} item style={{ padding: 5 }}>
                              <Field name={`configList.${index}.startTime`}>
                                {({ field, form }) => {
                                  return (
                                    <>
                                      <Grid
                                        container
                                        direction="row"
                                        wrap="nowrap"
                                      >
                                        <DateTimePicker
                                          {...field}
                                          format={datetimeFormat}
                                          value={field.value || null}
                                          onChange={(e) => {
                                            form.setFieldValue(
                                              `configList.${index}.startTime`,
                                              e
                                            );
                                          }}
                                          fullWidth
                                          inputVariant="outlined"
                                          label={<Trans>Validity start</Trans>}
                                        />
                                        <div style={{ padding: 5 }}>
                                          <IconButton
                                            onClick={(e) => {
                                              form.setFieldValue(
                                                `configList.${index}.startTime`,
                                                null
                                              );
                                            }}
                                          >
                                            <Icon>close</Icon>
                                          </IconButton>
                                        </div>
                                      </Grid>
                                      {field.value && (
                                        <div style={{ padding: 5 }}>
                                          <b>
                                            <Trans>In Pacific time:</Trans>
                                          </b>{" "}
                                          {formatToTimeZone(
                                            moment.utc(field.value).local(true),
                                            datetimeFormat,
                                            {
                                              timeZone: "Canada/Pacific",
                                            }
                                          )}
                                        </div>
                                      )}
                                    </>
                                  );
                                }}
                              </Field>
                            </Grid>
                            <Grid xs={6} item style={{ padding: 5 }}>
                              <Field name={`configList.${index}.endTime`}>
                                {({ field, form }) => {
                                  return (
                                    <>
                                      <Grid
                                        container
                                        direction="row"
                                        wrap="nowrap"
                                      >
                                        <DateTimePicker
                                          {...field}
                                          format={dateFormat + " h:mm a"}
                                          value={field.value || null}
                                          onChange={(e) => {
                                            form.setFieldValue(
                                              `configList.${index}.endTime`,
                                              e
                                            );
                                          }}
                                          fullWidth
                                          inputVariant="outlined"
                                          label={<Trans>Validity end</Trans>}
                                        />
                                        <div style={{ padding: 5 }}>
                                          <IconButton
                                            onClick={(e) => {
                                              form.setFieldValue(
                                                `configList.${index}.endTime`,
                                                null
                                              );
                                            }}
                                          >
                                            <Icon>close</Icon>
                                          </IconButton>
                                        </div>
                                      </Grid>
                                      {field.value && (
                                        <div style={{ padding: 5 }}>
                                          <b>
                                            <Trans>In Pacific time:</Trans>
                                          </b>{" "}
                                          {formatToTimeZone(
                                            moment.utc(field.value).local(true),
                                            datetimeFormat,
                                            {
                                              timeZone: "Canada/Pacific",
                                            }
                                          )}
                                        </div>
                                      )}
                                    </>
                                  );
                                }}
                              </Field>
                            </Grid>
                          </Grid>

                          <div style={{ padding: 5 }}>
                            <Field name={`configList.${index}.reminderTime`}>
                              {({ field, form }) => {
                                return (
                                  <>
                                    <Grid
                                      container
                                      direction="row"
                                      wrap="nowrap"
                                    >
                                      <DateTimePicker
                                        {...field}
                                        format={datetimeFormat}
                                        value={field.value || null}
                                        onChange={(e) => {
                                          form.setFieldValue(
                                            `configList.${index}.reminderTime`,
                                            e
                                          );
                                        }}
                                        fullWidth
                                        inputVariant="outlined"
                                        label={
                                          <Trans>REMINDER_DATE_LABEL</Trans>
                                        }
                                      />
                                      <div style={{ padding: 5 }}>
                                        <IconButton
                                          onClick={(e) => {
                                            form.setFieldValue(
                                              `configList.${index}.reminderTime`,
                                              null
                                            );
                                          }}
                                        >
                                          <Icon>close</Icon>
                                        </IconButton>
                                      </div>
                                    </Grid>
                                    {field.value && (
                                      <div style={{ padding: 5 }}>
                                        <b>
                                          <Trans>In Pacific time:</Trans>
                                        </b>{" "}
                                        {formatToTimeZone(
                                          moment.utc(field.value).local(true),
                                          datetimeFormat,
                                          {
                                            timeZone: "Canada/Pacific",
                                          }
                                        )}
                                      </div>
                                    )}
                                  </>
                                );
                              }}
                            </Field>
                          </div>

                          <div>
                            <FormikCheckboxField
                              name={`configList.${index}.inTesting`}
                              style={{ marginLeft: 10 }}
                              FormControlLabelProps={{ labelPlacement: "end" }}
                              controlLabel={<Trans>In testing?</Trans>}
                            />
                            <FormikCheckboxField
                              name={`configList.${index}.obsolete`}
                              style={{ marginLeft: 10 }}
                              FormControlLabelProps={{ labelPlacement: "end" }}
                              controlLabel={<Trans>Is obsolete?</Trans>}
                            />
                          </div>
                          {appType && (
                            <div style={{ width: "100%" }}>
                              <MultilanguageTextFieldWithFormik
                                name={`configList.${index}.moreInfoUrl`}
                                label={<Trans>More info url</Trans>}
                                style={{ padding: 5 }}
                                inputDebounce
                              />
                              <Grid
                                container
                                direction="column"
                                style={{ padding: 5, flexGrow: 1 }}
                                justify="center"
                                alignContent="center"
                                alignItems="center"
                              >
                                <FormikFastTextField
                                  name={`configList.${index}.logo`}
                                  label={<Trans>Logo url</Trans>}
                                />
                                <Typography
                                  style={{ fontWeight: "bold", margin: 5 }}
                                >
                                  <Trans>Preview:</Trans>
                                </Typography>
                                <img
                                  src={item.logo}
                                  alt=""
                                  style={{
                                    borderRadius: 10,
                                    maxHeight: 250,
                                    maxWidth: 250,
                                  }}
                                />
                              </Grid>
                            </div>
                          )}
                        </Grid>
                      </Collapse>
                    </Card>
                  </ListItem>
                );
              })}
            </List>
          </div>
        );
      }}
    </Formik>
  );
};

export const FormSelectField = ({ name, formsData, label }) => {
  const idToLabel = {};
  formsData.forEach((obj) => {
    idToLabel[obj.id] = obj.name;
  });

  return (
    <FastField name={name}>
      {({ field, form }) => {
        const { setFieldValue } = form;
        return (
          <div style={{ padding: 5 }}>
            <Autocomplete
              {...field}
              freeSolo={false}
              value={field.value || ""}
              fullWidth
              getOptionLabel={(opt) => idToLabel[opt] || ""}
              options={formsData.map((item, index) => item.id)}
              onChange={(e, value) => {
                setFieldValue(name, value);
              }}
              renderInput={(params) => (
                <TextField variant="outlined" {...params} label={label} />
              )}
            />
          </div>
        );
      }}
    </FastField>
  );
};

const DeleteConfigurationButton = ({ handleDelete }) => {
  const [dialogOpen, openDialog] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setDeleting(false);
  }, [dialogOpen]);

  return (
    <>
      <Dialog open={dialogOpen}>
        <div style={{ width: 600 }}>
          <DialogTitle>
            <Trans>Are you sure you want to delete that section?</Trans>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Button
                disabled={deleting}
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleting(true);
                  enqueueSnackbar(<Trans>Deleting</Trans>, {
                    variant: "info",
                  });
                  handleDelete().then((r) => {
                    openDialog(false);
                  });
                }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-evenly"
                >
                  <Trans>Yes</Trans>
                  <Icon style={{ marginLeft: 5 }}>done</Icon>
                </Grid>
              </Button>
              <Button
                disabled={deleting}
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  openDialog(false);
                }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-evenly"
                >
                  <Trans>No</Trans>
                  <Icon style={{ marginLeft: 5 }}>close</Icon>
                </Grid>
              </Button>
            </Grid>
          </DialogContent>
        </div>
      </Dialog>
      <IconButton
        onClick={() => {
          openDialog(true);
        }}
      >
        <Icon>delete</Icon>
      </IconButton>
    </>
  );
};

export default ConfigureApplicationTypes;
