import { Trans } from '@lingui/macro'
import {
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip
} from '@material-ui/core'
import { portalLanguagesData } from 'app/appSettings'
import { useState } from 'react'
import { getEmptyLangValues } from 'utils'
import DebouncedTextField from '../forms/common/DebouncedTextField'
import { countHelperText } from '../page-layouts/FormElement'
import TranslationConnectionsDialog from './TranslationConnectionsDialog'

const ConfigureMultilanguageTextField = ({
  style = {},
  maxCharacters,
  fullWidth = true,
  handleChange,
  allowConnectingToPortalTranslation = true,
  disabled,
  customValueDisplay,
  resetToExisting = false,
  inputDebounce = false, //used for MultilanguageTextFieldWithFormik
  useDebounce = false,
  onlySelectTranslation = false,
  ...props
}) => {
  const value = props.value || {}
  const [showInColumn, setShowInColumn] = useState(false)
  const { portalTranslationId } = value || {}
  const [selectedLanguageKey, setSelectedLanguageKey] = useState('en')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [timer, setTimer] = useState({})
  const isId = allowConnectingToPortalTranslation && portalTranslationId

  const TextFieldComponent = useDebounce ? DebouncedTextField : TextField

  const handleValueChange = (e, editKey = null) => {
    if (showInColumn && editKey) {
      const newValue = { ...value, [editKey]: e.target.value }
      if (inputDebounce) {
        clearTimeout(timer)
        const newTimer = setTimeout(() => {
          handleChange(newValue, e, editKey)
        }, 700)

        setTimer(newTimer)
      } else {
        handleChange(newValue, e, editKey)
      }
    } else {
      const newValue = { ...value, [selectedLanguageKey]: e.target.value }
      handleChange(newValue, e, selectedLanguageKey)
    }
  }

  const handleLanguageChange = e => {
    setSelectedLanguageKey(e.target.value)
  }

  const getDisplayedValue = languageKey => {
    if (customValueDisplay) {
      return customValueDisplay(value, languageKey || selectedLanguageKey)
    }
    return isId
      ? portalTranslationId
      : value[languageKey || selectedLanguageKey] || ''
  }

  const saveTranslationConnection = id => {
    const newValue = getEmptyLangValues()
    newValue.portalTranslationId = id
    handleChange(newValue)
  }

  const clearTranslationConnection = () => {
    handleChange(
      resetToExisting
        ? { ...value, portalTranslationId: '' }
        : { portalTranslationId: '' }
    )
  }

  const inputProps = props.inputProps || {}
  if (maxCharacters) {
    inputProps.maxLength = maxCharacters
  }

  const toggleViewMode = () => {
    setShowInColumn(!showInColumn)
  }

  return (
    <>
      <Grid
        fullWidth
        container
        wrap='nowrap'
        //alignItems={showInColumn ? 'flex-start' : 'center'}
        alignItems='flex-start'
        spacing={2}
        style={{ ...style }}
      >
        {!isId && !onlySelectTranslation && (
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {showInColumn ? (
              <Trans>MULTILANGUAGE_EDIT_COLUMNS</Trans>
            ) : (
              <Trans>MULTILANGUAGE_EDIT_DROPDOWN</Trans>
            )}
            <Switch
              checked={showInColumn}
              onChange={toggleViewMode}
              color='primary'
            />
          </Grid>
        )}

        {(allowConnectingToPortalTranslation || onlySelectTranslation) && (
          <Grid item style={{ marginTop: 14 }}>
            {isId ? (
              <Tooltip
                title={<Trans>MULTILANGUAGE_EDIT_CLEAR_CONNECTION</Trans>}
              >
                <IconButton onClick={clearTranslationConnection} size='small'>
                  <Icon>clear</Icon>
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <Trans>
                    MULTILANGUAGE_EDIT_CONNECT_TO_PORTAL_TRANSLATION
                  </Trans>
                }
              >
                <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                  <IconButton onClick={() => setDialogOpen(true)} size='small'>
                    <Icon>language</Icon>
                  </IconButton>
                </Grid>
              </Tooltip>
            )}
          </Grid>
        )}

        {showInColumn && !isId ? (
          <Grid
            container
            fullWidth
            xs
            direction='column'
            justifyContent='center'
            style={{
              paddingLeft: 8
            }}
          >
            {props?.label && (
              <h6 style={{ marginBottom: 24, marginLeft: 16, marginTop: 24 }}>
                {props.label}
              </h6>
            )}
            {Object.values(portalLanguagesData).map(obj => (
              <Grid
                item
                xs
                key={obj.editKey}
                style={{ marginBottom: 10, marginLeft: 6 }}
              >
                <TextFieldComponent
                  disabled={!obj.editKey || isId || disabled}
                  onChange={e => handleValueChange(e, obj.editKey)}
                  fullWidth
                  variant='outlined'
                  multiline
                  helperText={
                    maxCharacters &&
                    countHelperText(value[obj.editKey] || '', maxCharacters)
                  }
                  {...props}
                  value={getDisplayedValue(obj.editKey)}
                  label={obj.labelTrans || ''}
                  inputProps={inputProps}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <>
            <Grid item xs>
              <TextFieldComponent
                disabled={!selectedLanguageKey || isId || disabled}
                onChange={e => handleValueChange(e)}
                fullWidth
                variant='outlined'
                multiline
                helperText={
                  maxCharacters &&
                  countHelperText(getDisplayedValue(), maxCharacters)
                }
                {...props}
                value={getDisplayedValue() || ''}
                inputProps={inputProps}
              />
            </Grid>
            {!isId && !onlySelectTranslation && (
              <Grid item style={{ width: 150 }}>
                <TextFieldComponent
                  select
                  variant='outlined'
                  fullWidth
                  value={selectedLanguageKey}
                  onChange={handleLanguageChange}
                  label={<Trans>Language</Trans>}
                >
                  {Object.values(portalLanguagesData).map(obj => (
                    <MenuItem key={obj.editKey} value={obj.editKey}>
                      {obj.label}
                    </MenuItem>
                  ))}
                </TextFieldComponent>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <TranslationConnectionsDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        saveTranslationConnection={saveTranslationConnection}
      />
    </>
  )
}

export default ConfigureMultilanguageTextField
