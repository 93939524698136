import { StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import {
  parseFormLabelText,
  parseSfFieldValue,
  pdfDefaultFontSize,
} from "../../common/Common";
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily,
} from "../../pdf-components/FormPdf";
import { parsePDFLabelToSupportWordBreaking } from "../../pdf-components/formPdfUtils";
import { pdfTableStyles } from "../../pdf-components/PDFCommon";

export const FormTablePdf = ({
  value,
  title,
  langVersion,
  connectedMap,
  typeProps,
  ...props
}) => {
  const fontFamily =
    langVersion === "iu-cans"
      ? inuktitutFormPdfFontFamily
      : defaultFormPdfFontFamily;

  const pdfStyles = StyleSheet.create({
    row: {
      flexWrap: 'nowrap',
      display: 'flex',
      width: '100%',
    },
  });

  const {
    connectedTo = [],
    columns = [],
    pdfTableFontSize,
    pdfTableTitleFontSize,
    pdfTableTitleTextAlign,
    pdfTableTitleProps = [],
    sortOrder = "asc",
  } = typeProps;
  let collection = [];
  const collectionKey = connectedTo[0]?.connectedCollection;
  const connectedObject = connectedTo[0]?.connectedObject;
  if (collectionKey && connectedObject) {
    collection =
      connectedMap[connectedObject]?.additionalInfo[collectionKey] || [];
  }

  const titleStyle = {
    ...pdfTableStyles.tableCol,
    fontSize: pdfDefaultFontSize,
    textAlign: pdfTableTitleTextAlign || "center",
  };
  if (pdfTableTitleProps.includes("bold")) {
    titleStyle.fontWeight = "bold";
  }
  if (pdfTableTitleProps.includes("italics")) {
    titleStyle.fontStyle = "italic";
  }
  if (pdfTableTitleProps.includes("underline")) {
    titleStyle.textDecoration = "underline";
  }
  if (pdfTableTitleFontSize) {
    titleStyle.fontSize = +pdfTableTitleFontSize;
  }
  const paddingKeys = ["Left", "Right", "Top", "Bottom"];
  paddingKeys.forEach((key) => {
    const fullKey = "pdfTableTitlePadding" + key;
    if (typeProps[fullKey]) {
      titleStyle["padding" + key] = Number(typeProps[fullKey]);
    }
  });

  columns.some((column) => {
    const { field, sortByThisColumn } = column;
    if (sortByThisColumn) {
      collection = collection.sort((a, b) => {
        const aV = a[field.name];
        const bV = b[field.name];
        let toRet;
        if (moment(aV).isValid()) {
          toRet = moment(aV).diff(moment(bV), "minutes");
        } else if (!isNaN(Number(aV))) {
          toRet = Number(aV) - Number(bV);
        } else {
          toRet = String(aV).localeCompare(String(bV));
        }
        if (sortOrder !== "asc") {
          toRet *= -1;
        }
        return toRet;
      });
      return true;
    }
    return false;
  });

  return (
    <View style={pdfTableStyles.table}>
      {Boolean(title) && <Text style={titleStyle}>{title}</Text>}
      <View style={[pdfTableStyles.tableRow, pdfStyles.row]}>
        {columns.map((column, index) => {
          const {
            pdfHeaderTextProps = [],
            pdfColumnFlex,
            pdfBackgroundColor,
            pdfHeaderTextAlign,
            pdfFontSize,
            label,
          } = column;
          const headerStyle = {
            ...pdfTableStyles.tableCol,
            ...pdfTableStyles.tableCell,
            flex: pdfColumnFlex ? Number(pdfColumnFlex) : 1,
            textAlign: "left",
            fontFamily
          };
          headerStyle.fontWeight = "bold";
          if (pdfHeaderTextProps.includes("italics")) {
            headerStyle.fontStyle = "italic";
          }
          if (pdfHeaderTextProps.includes("underline")) {
            headerStyle.textDecoration = "underline";
          }
          if (pdfBackgroundColor) {
            headerStyle.backgroundColor = pdfBackgroundColor.id;
          }
          if (pdfFontSize) {
            headerStyle.fontSize = +pdfFontSize;
          }
          return (
            <View key={index} style={headerStyle}>
              <Text>
                {parsePDFLabelToSupportWordBreaking(
                  parseFormLabelText({
                    langVersion,
                    text: label,
                    returnString: true,
                  })
                )}
              </Text>
            </View>
          );
        })}
      </View>
      {collection.map((item, index) => {
        return (
          <View style={[pdfTableStyles.tableRow, pdfStyles.row]} key={index}>
            {columns.map((column, index) => {
              const { field, pdfCellTextAlign, translate, pdfColumnFlex } =
                column;
              const cellStyle = {
                ...pdfTableStyles.tableCol,
                ...pdfTableStyles.tableCell,
                flex: pdfColumnFlex ? Number(pdfColumnFlex) : 1,
                textAlign: pdfCellTextAlign || "center",
                display: "flex",
                justifyContent: "flex-start",
                fontFamily
              };
              if (pdfTableFontSize) {
                cellStyle.fontSize = +pdfTableFontSize;
              }
              if (!field.name) {
                return (
                  <View key={index} style={[cellStyle, {height: 20}]}>
                    <Text>NO FIELD CONNECTED</Text>
                  </View>
                );
              }
              const fieldValue = item[field.name];
              return (
                <View key={index} style={cellStyle}>
                  <Text>
                    {parsePDFLabelToSupportWordBreaking(
                      parseSfFieldValue({
                        value: fieldValue,
                        type: field.type,
                        langVersion,
                        translate,
                      })
                    )}
                  </Text>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};
