import { Trans } from "@lingui/macro";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  addComment,
  committeeCaseMapping,
  createCaseByFlow,
  createVote,
  entityTypes,
  getCases,
  getVote,
  updateVote,
} from "app/services/sfAuth/sfData/sfCase";
import { getFormPage } from "app/services/sfAuth/sfData/sfForms";
import {
  closedStages,
  getOpportunity,
  getOpportunityStage,
  oppToStepper,
} from "app/services/sfAuth/sfData/sfOpportunity";
import { getExternalReviews } from "app/services/sfAuth/sfData/sfReviews";
import Loading from "egret/components/EgretLoadable/Loading";
import FormikRadioGroupField from "formik-material-fields/lib/FormikRadioGroupField";
import { withSnackbar } from "notistack";
import { Component, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import Form from "../../forms/Form";
import { constructFormAddressString } from "../../forms/common/Common";
import { OpportunityView } from "../../opportunity/OpportunityView";
import ProgressSnackbar from "../../page-layouts/CustomSnackbars";
import { withFormikIncluded } from "../applications/old-application/BaseIncludedForm";
import AllocationComitteQuestionsCard from "./AllocationComitteQuestionsCard";

const styles = {
  textBlock: {
    marginLeft: 30,
    marginTop: 20,
    marginRight: 30,
    //fontSize: 18,
    textAlign: "left",
  },
  subtitle: {
    fontSize: 12,
    color: "#919191",
  },
  switch: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    checked: {},
    track: {},
  },
  paperGrid: {
    padding: 20,
  },
  paper: {
    width: "100%",
    paddingBottom: 10,
  },
  sectionHeader: {
    fontSize: "48px",
    textAlign: "center",
    padding: 20,
  },
  fieldLabel: {},
};

export const fundLogos = {
  "Community-Based Tenant Initiative Fund (CBTIF)":
    "/assets/images/funds-icons-cbti.png",
  "Sector Transformation Fund – Sectoral Impact":
    "/assets/images/funds-icons-stf-sip.png",
  "Sector Transformation Fund – Local Project":
    "/assets/images/funds-icons-stf-lp.png",
  // TODO replace FCHI image with correct one
  "FCHI-2": "/assets/images/funds-icons-stf-lp.png",
  "ICCE Grant 2021": "/assets/images/icce-logo-circle.svg",
  "ICCE - Community Funding Program - Round 3":
    "/assets/images/icce-logo-circle.svg",
  "ICCE - Community Funding Program": "/assets/images/icce-logo-circle.svg",
  "Green Kickstarter Fund": "/assets/images/funds-icons-greenfund.png",
};

const initialValues = {
  questions: null,
  vote: "",
};

function ReviewApplicationsWithHooks() {
  return function WrappedComponent(props) {
    const { id } = useParams();
    const history = useHistory();
    return <ReviewApplications {...props} history={history} id={id} />;
  };
}

class ReviewApplications extends Component {
  state = {
    loadingCompleted: false,
    toggleAll: true,
    vote: "None",
    forceUpdate: 1,
    application: null,
    committeeType: null,
    maxVotes: 0,
    conflictVotes: 0,
    currentVotes: 0,
  };

  constructor(props) {
    super(props);
    const { configuration, fundingStreams, history, enqueueSnackbar, user } =
      props;
    this.getComments = this.getCases.bind(this);
    this.submitVote = this.submitVote.bind(this);
    this.addNewComment = this.addNewComment.bind(this);
    this.handleSaveComment = this.handleSaveComment.bind(this);
    this.handleSaveQuestion = this.handleSaveQuestion.bind(this);
    this.reloadStage = this.reloadStage.bind(this);
    this.state.committeeType = props.match.url.split("_")[1].split("/")[0];
    const applicationId = props.id;
    //console.log('got committee type', this.state.committeeType, fundingStreams)
    console.log("got committee type", this.state.committeeType, configuration);

    Promise.all([
      this.getCases(applicationId),
      getOpportunity(applicationId, {
        permissions: { CMHC: true },
        fields: [
          "Id",
          "LastModifiedDate",
          "RecordTypeId",
          "Assigned_Program_Manager__c",
          "StageName",
          "FGM_Base__Recommended_Amount__c",
          "AccountId",
          "RecordType.*",
          "FGM_Base__Amount_Requested__c",
          "Funding_Stream__c",
          "Funding_Stream__r.*",
          "FGM_Portal__Total_Project_Budget__c",
          "Other_grant_requests_related_to_project__c",
          "Submit_Date__c",
          "Summary__c",
          "Account.Name",
          "Description",
          "Priority_areas__c",
          "Geographical_scope__c",
          "Geographical_Location__c",
          "Demographic__c",
          "Target_demographics__c",
          "Other_target_demographic__c",
          "Units_women_and_children__c",
          "Units_single_parent__c",
          "Units_racialized_persons__c",
          "Units_Indigenous__c",
          "Units_fleeing_domestic_violence__c",
          "Supported_demographics__c",
          "Other_supported_demographic__c",
          "Impact_summary__c",
          "Partners_summary__c",
          "PM_Name__c",
          "PM_Recommendation__c",
          "PM_Recommendation_TXT__c",
        ],
      }),
      getVote(applicationId, null, entityTypes[this.state.committeeType]),
    ])
      .then(([cases, application, votes]) => {
        const vote = votes.find(
          (obj) => obj.CreatedById === this.props.user.userId
        );

        const currentVotes = votes.filter(
          (vote) =>
            vote.Conflict_of_interest__c !== "declared_conflict" &&
            ["For", "Against"].includes(vote.Result__c)
        ).length;
        const conflictVotes = votes.filter(
          (vote) =>
            vote.Result__c === "conflict_of_interest" ||
            vote.Conflict_of_interest__c === "declared_conflict"
        ).length;

        const config = fundingStreams.find(
          (fs) => fs.id === application.Funding_Stream__c
        );
        const formId = config && config[this.state.committeeType];

        let voteValue = "None";
        if (vote && vote.Result__c) {
          voteValue = vote.Result__c;
        }

        const isConflictOfInterest = Boolean(
          vote && vote.Conflict_of_interest__c === "declared_conflict"
        );

        const canVote =
          application.OpportunityTeamMembers?.records &&
          application.OpportunityTeamMembers.records.some(
            (member) =>
              member.UserId === user.userId &&
              member.TeamMemberRole === "Selection Committee Member"
          );

        const maxVotes = application.Funding_Stream__r.Total_Required_Votes__c;
        // application.OpportunityTeamMembers?.records?.filter(
        //   member => member.TeamMemberRole === 'Selection Committee Member'
        // ).length || 0

        if (isConflictOfInterest) {
          history.push(
            `/grants/ExecutiveSummaries/${this.state.committeeType}`
          );
          enqueueSnackbar(
            <Trans>NO_ACCESS_DUE_TO_CONFLICT_OF_INTEREST_SNACKBAR</Trans>,
            {
              variant: "info",
            }
          );
        }

        console.log("loaded questions", cases);
        console.log("loaded vote", vote);
        console.log("loaded application", application);
        props.setValues({
          ...this.props.values,
          questions: cases,
          vote: voteValue,
        });

        if (formId) {
          Promise.all([
            getFormPage(formId),
            getExternalReviews({
              FGM_Base__Request__c: applicationId,
            }),
          ]).then(([form, externalReviews]) => {
            let firstReview, inDepthReview;
            externalReviews.forEach((review) => {
              const recordType = String(review.RecordType.Name).toLowerCase();
              if (recordType.includes("first")) {
                firstReview = review.Id;
              } else if (recordType.includes("depth")) {
                inDepthReview = review.Id;
              }
            });

            console.log("found form for opportunity view", form);
            this.setState({
              loadingCompleted: true,
              vote: voteValue,
              maxVotes,
              currentVotes,
              conflictVotes,
              voteObjectId: vote ? vote.Id : null,
              canVote,
              user,
              form,
              applicationId,
              firstReview,
              inDepthReview,
              application: {
                ...oppToStepper(application),
                lastModifiedDate: application.LastModifiedDate,
              },
            });
          });
        } else {
          this.setState({
            loadingCompleted: true,
            vote: voteValue,
            maxVotes,
            currentVotes,
            conflictVotes,
            voteObjectId: vote ? vote.Id : null,
            canVote,
            applicationId,
            application: {
              ...oppToStepper(application),
              lastModifiedDate: application.LastModifiedDate,
            },
          });
        }
      })
      .catch((err) => {
        console.error("failed Loading data", err);
      });
  }

  getCases(applicationId) {
    return getCases(applicationId, {
      searchParams: {
        Type: committeeCaseMapping[this.state.committeeType],
      },
    })
      .then((cases) => {
        console.log("found cases", cases);
        return cases.map((item) => {
          const toSetComments = [];
          if (item.CaseComments) {
            item.CaseComments.records.forEach((record) => {
              toSetComments.push({
                user: record.CreatedBy.Name,
                comment: record.CommentBody,
                createdDate: record.CreatedDate,
                editable: false,
              });
            });
          }
          return {
            question: item.Description,
            caseId: item.Id,
            user: item.SuppliedName,
            type: item.Type,
            createdDate: item.CreatedDate,
            editable: false,
            comments: toSetComments,
          };
        });
      })
      .catch((e) => {
        console.log("error loading cases", e);
        this.props.enqueueSnackbar(
          <Trans>Error loading Questions/Comments</Trans>,
          {
            variant: "error",
          }
        );
      });
  }

  addNewComment(id) {
    const { values, setFieldValue } = this.props;
    let newQuestions = [...values.questions];
    newQuestions[id].comments.push({
      user: this.props.user.displayName,
      comment: "",
      editable: true,
    });
    setFieldValue("questions", newQuestions);
  }

  handleSaveComment(questionId, commentId) {
    let newQuestions = [...this.props.values.questions];
    return addComment(newQuestions[questionId].caseId, {
      comment: newQuestions[questionId].comments[commentId].comment,
    }).then(
      (result) => {
        this.props.enqueueSnackbar(<Trans>Comment saved</Trans>, {
          variant: "info",
        });
        newQuestions[questionId].comments[commentId].editable = false;
        const questionsToSet = [];
        newQuestions.forEach((question) => {
          const copyQuestion = { ...question };
          copyQuestion.comments = [];
          question.comments.forEach((comment) => {
            if (!comment.editable) {
              copyQuestion.comments.push(comment);
            }
          });
          if (!copyQuestion.editable) {
            questionsToSet.push(copyQuestion);
          }
        });
        this.props.setFieldValue("questions", newQuestions);
      },
      (reject) => {
        this.props.enqueueSnackbar(<Trans>Error while saving comment</Trans>, {
          variant: "error",
        });
      }
    );
  }

  handleSaveQuestion(index) {
    const { values, user, enqueueSnackbar, setFieldValue } = this.props;
    const { committeeType, application, applicationId } = this.state;
    let newQuestions = [...values.questions];

    return createCaseByFlow({
      type: committeeCaseMapping[committeeType],
      language: user.language,
      description: newQuestions[index].question,
      opportunityId: applicationId,
      assignedManager: application.info.assignedManager,
      userName: user.displayName,
    })
      .then((result) => {
        console.log("case created", newQuestions, index, result);
        newQuestions[index].editable = false;
        newQuestions[index].caseId = result[0].outputValues.createdId;
        setFieldValue("questions", newQuestions);
        enqueueSnackbar(<Trans>Question saved</Trans>, {
          variant: "info",
        });
      })
      .catch((reject) => {
        enqueueSnackbar(<Trans>Error while saving question</Trans>, {
          variant: "error",
        });
      });
  }

  reloadStage(vote) {
    return Promise.all([
      getOpportunityStage(this.state.application.info.id),
      getVote(
        this.state.application.info.id,
        null,
        entityTypes[this.state.committeeType]
      ),
    ]).then(([stage, votes]) => {
      const currentVotes = votes.filter(
        (vote) =>
          vote.Conflict_of_interest__c !== "declared_conflict" &&
          ["For", "Against"].includes(vote.Result__c)
      ).length;
      const app = { ...this.state.application };
      app.info.stageName = stage;
      this.setState({
        application: app,
        currentVotes,
        vote: vote,
        submittingVote: false,
      });
    });
  }

  submitVote(voteValue) {
    console.log("submitting vote", voteValue);
    const { committeeType, application, voteObjectId } = this.state;
    const { enqueueSnackbar, values, user, closeSnackbar, history } =
      this.props;
    const currentState = { ...this.state };
    this.setState({ submittingVote: true });
    const infoSnackbar = enqueueSnackbar(<Trans>Submitting vote</Trans>, {
      variant: "info",
      persist: true,
    });
    if (voteObjectId) {
      updateVote({
        vote: voteValue,
        updateId: voteObjectId,
      }).then(
        (result) => {
          this.reloadStage(voteValue).then((result) => {
            closeSnackbar(infoSnackbar);
            enqueueSnackbar(<Trans>Vote submitted</Trans>, {
              variant: "success",
            });
            if (voteValue === "conflict_of_interest") {
              history.push(`/grants/ExecutiveSummaries/${committeeType}`);
            }
          });
        },
        (reject) => {
          console.log("error updating vote", reject);
          this.setState({
            ...currentState,
            submittingVote: false,
          });
          closeSnackbar(infoSnackbar);
          enqueueSnackbar(<Trans>Error while submitting vote</Trans>, {
            variant: "error",
          });
        }
      );
    } else {
      createVote({
        voteValue,
        id: application.info.id,
        userId: user.userId,
        entityType: entityTypes[committeeType],
      }).then(
        (result) => {
          this.setState({ voteObjectId: result.id });
          this.reloadStage(values.vote).then((result) => {
            closeSnackbar(infoSnackbar);
            enqueueSnackbar(<Trans>Vote submitted</Trans>, {
              variant: "info",
            });
          });
        },
        (reject) => {
          console.log("error creating vote", reject);
          this.setState({
            ...currentState,
            submittingVote: false,
          });
          closeSnackbar(infoSnackbar);
          enqueueSnackbar(<Trans>Error while submitting vote</Trans>, {
            variant: "error",
          });
        }
      );
    }
  }

  render() {
    const {
      application,
      applicationId,
      form,
      loadingCompleted,
      committeeType,
      vote,
      canVote,
      submittingVote,
      forceUpdate,
      toggleAll,
      firstReview,
      inDepthReview,
      currentVotes,
      maxVotes,
      conflictVotes,
    } = this.state;
    const {
      values,
      setValues,
      setFieldValue,
      enqueueSnackbar,
      closeSnackbar,
      user,
    } = this.props;
    // let validationStatus = validationStatusMapping[this.state.committeeType]

    let showReviewed = false;
    let canBeReviewed = false;

    if (application) {
      showReviewed =
        (application.recommendation.recommendedAmount <= 50000 &&
          !committeeType.includes("SC")) ||
        closedStages.includes(application.info.stageName);
      canBeReviewed = committeeType === application.info.stageName;
    }

    if (!loadingCompleted) {
      return <Loading />;
    }

    return (
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        style={{ height: "100%" }}
      >
        <Grid item xs={6}>
          {form ? (
            <Form
              defaultFormType="printable"
              mainObject="Opportunity"
              formId={form.id}
              fetchString={constructFormAddressString({
                ids: {
                  User: user.userId,
                  Account: application.info.account,
                  Opportunity: applicationId,
                  FGM_Base__Review__c: [firstReview, inDepthReview],
                  Funding_Stream__c: application.info.fundingStream,
                },
                objectsConnected: form.objectsConnected,
              })}
              fixedDisplay
            />
          ) : (
            <OpportunityView
              displayPrint
              opportunity={application}
              committeeType={committeeType}
              defaultConfigurationOptions={{ ac: true }}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <Paper style={styles.paper} square={true}>
            {showReviewed ? (
              <div style={{ paddingTop: 30 }} align="center">
                <Grid
                  style={{
                    width: "100%",
                    paddingTop: 30,
                    paddingBottom: 30,
                  }}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography style={styles.sectionHeader}>
                    <Trans>Reviewed</Trans>
                  </Typography>
                  <Icon color="secondary" style={{ fontSize: 48, margin: 10 }}>
                    check_box
                  </Icon>
                </Grid>
              </div>
            ) : (
              <div style={{ paddingTop: 30, paddingBottom: 30 }} align="center">
                {canVote && (
                  <div>
                    <Typography style={{ fontSize: 16, marginBottom: 24 }}>
                      <b>
                        <Trans>Vote</Trans>
                      </b>
                    </Typography>
                    <Typography style={{ fontSize: 14, marginBottom: 12 }}>
                      <b>
                        <Trans>REVIEW_APPLICATIONS_CURRENT_VOTES_LABEL</Trans>:
                      </b>{" "}
                      {maxVotes
                        ? `${currentVotes} / ${maxVotes}`
                        : currentVotes}
                    </Typography>
                    <Typography style={{ fontSize: 14 }}>
                      <Trans>REVIEW_APPLICATIONS_MY_VOTE_LABEL</Trans>
                      {": "}
                      <b>
                        <Trans id={vote} />
                      </b>
                    </Typography>

                    {canBeReviewed && (
                      <>
                        <FormikRadioGroupField
                          name="vote"
                          margin="normal"
                          disabled={submittingVote || !canVote}
                          options={[
                            { label: <Trans>For</Trans>, value: "For" },
                            {
                              label: <Trans>Against</Trans>,
                              value: "Against",
                            },
                            committeeType !== "CMHC" && {
                              label: <Trans>Request Live Discussion</Trans>,
                              value: "Request Live Discussion",
                            },
                          ]}
                          onChange={(e) => {
                            setFieldValue("vote", e);
                          }}
                          row="all"
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={submittingVote || !canVote}
                          style={{ padding: 10, margin: 15 }}
                          onClick={() => {
                            this.submitVote(values.vote);
                          }}
                        >
                          <Trans>Vote</Trans>!
                        </Button>

                        {committeeType === "SC" && (
                          <ConflictOfInterestButton
                            disabled={submittingVote || !canVote}
                            handleSubmit={(e) => {
                              this.submitVote("conflict_of_interest");
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}

                <div style={{ width: "80%" }}>
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      marginBottom: 12,
                    }}
                  >
                    <Typography align="center" style={{ fontSize: 18 }}>
                      {committeeType !== "CMHC" ? (
                        <Trans>Questions {`&`} Answers</Trans>
                      ) : (
                        <Trans>Comments</Trans>
                      )}
                    </Typography>
                    {canVote && (
                      <IconButton
                        disabled={!canVote}
                        onClick={() => {
                          let newValues = { ...values };
                          newValues.questions.push({
                            question: "",
                            user: user.displayName,
                            editable: true,
                            comments: [],
                          });
                          setValues(newValues);
                        }}
                      >
                        <Icon style={{ color: "#5DBD94", fontSize: 36 }}>
                          add_circle
                        </Icon>
                      </IconButton>
                    )}
                  </Grid>
                </div>

                <div style={{ paddingBottom: 16 }}>
                  <Button
                    variant="contained"
                    style={{
                      margin: 5,
                      marginBottom: 10,
                      color: "white",
                      backgroundColor: "#5DBD94",
                    }}
                    onClick={() => {
                      this.setState({
                        toggleAll: true,
                        forceUpdate: forceUpdate + 1,
                      });
                    }}
                  >
                    <Typography>
                      <Trans>Show all</Trans>
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      margin: 5,
                      marginBottom: 10,
                      color: "white",
                      backgroundColor: "#5DBD94",
                    }}
                    onClick={() => {
                      this.setState({
                        toggleAll: false,
                        forceUpdate: forceUpdate + 1,
                      });
                    }}
                  >
                    <Typography>
                      <Trans>Hide all</Trans>
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      margin: 5,
                      marginBottom: 10,
                      color: "white",
                      backgroundColor: "#5DBD94",
                    }}
                    onClick={() => {
                      const refreshingKey = enqueueSnackbar(null, {
                        variant: "info",
                        persist: true,
                        content: (key) =>
                          ProgressSnackbar(<Trans>Refreshing comments</Trans>),
                      });
                      this.getCases(application.info.id).then((result) => {
                        setFieldValue("questions", result);
                        closeSnackbar(refreshingKey);
                        enqueueSnackbar(<Trans>Comments refreshed</Trans>, {
                          variant: "success",
                        });
                      });
                    }}
                  >
                    <Typography>
                      <Trans>Refresh comments</Trans>
                    </Typography>
                  </Button>
                </div>
                {values.questions &&
                  values.questions.map((question, index) => {
                    return (
                      <AllocationComitteQuestionsCard
                        disabled={!canBeReviewed}
                        toggleAll={toggleAll}
                        forceUpdate={forceUpdate}
                        index={index}
                        handleSaveQuestion={this.handleSaveQuestion}
                        handleSaveComment={this.handleSaveComment}
                        handleComment={() => {
                          this.addNewComment(index);
                        }}
                        {...question}
                      />
                    );
                  })}
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const ConflictOfInterestButton = ({ disabled, handleSubmit }) => {
  const [warning, showWarning] = useState(false);

  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        color="secondary"
        onClick={(e) => {
          showWarning(true);
        }}
      >
        <Trans>Conflict of interest</Trans>
      </Button>
      <Dialog open={warning}>
        <DialogTitle id="alert-dialog-title">
          <Trans>Are you sure you want to declare conflic of interest?</Trans>
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            <Trans>You cannot undo this decision</Trans>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              showWarning(false);
              handleSubmit();
            }}
          >
            <Trans>Yes</Trans>
          </Button>
          <Button onClick={(e) => showWarning(false)}>
            <Trans>No</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    user: state.user,
    fundingStreams: state.fundingStreams.streams,
    organization: state.organization,
    configuration: state.configuration,
  };
};

export default withFormikIncluded({
  initialValues,
  persist: true,
})(
  connect(mapStateToProps, null, null, { forwardRef: true })(
    withSnackbar(ReviewApplicationsWithHooks())
  )
);
