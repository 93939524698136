import { Text } from "@react-pdf/renderer";
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily,
} from "../../pdf-components/FormPdf";

export function FormHeaderPDF({ title, langVersion, typeProps, ...props }) {
  const { bold, italics, pdfFontSize = 11, centerText } = typeProps;
  let styleToPass = {
    fontWeight: 300,
    fontFamily:
      langVersion === "iu-cans"
        ? inuktitutFormPdfFontFamily
        : defaultFormPdfFontFamily,
  };

  if (pdfFontSize) {
    styleToPass.fontSize = +pdfFontSize;
  }
  if (bold) {
    styleToPass.fontWeight = "bold";
  }
  if (italics) {
    styleToPass.fontStyle = "italic";
  }
  if (centerText) {
    styleToPass.textAlign = "center";
  }

  return <Text style={styleToPass}>{title}</Text>;
}
