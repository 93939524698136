import { t } from "@lingui/macro";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { currencyFormatedString } from "app/views/common/Formats";
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily,
} from "app/views/forms/pdf-components/FormPdf";
import { pdfTableStyles } from "app/views/forms/pdf-components/PDFCommon";
import _ from "lodash";
import { myI18n } from "translation/I18nConnectedProvider";
import { sanitizeLineKey } from "./FormBudget";

export const FormBudgetPdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  const expenses = typeProps.expensesLines || [];
  const revenues = typeProps.revenuesLines || [];
  const expensesHelpText = typeProps?.expensesHelpText?.[langVersion] || "";
  const revenuesHelpText = typeProps?.revenuesHelpText?.[langVersion] || "";

  const expensesYears = Object.keys(value.expenses);
  const revenuesYears = Object.keys(value.revenues);

  const years = [...new Set([...expensesYears, ...revenuesYears])].sort(
    (a, b) => a - b
  );

  const styles = {
    ...pdfTableStyles,
    ...StyleSheet.create({
      firstCol: {
        direction: "flex",
        justifyContent: "center",
        width: "35%",
      },
      secondCol: {
        width: "15%",
      },
      thirdCol: {
        width: "50%",
      },
      secondTitle: {
        fontFamily: defaultFormPdfFontFamily,
        fontSize: 10,
        margin: "2px",
        textAlign: "center",
        marginBottom: "5px",
      },
      mainTableCell: {
        ...pdfTableStyles.tableCell,
        fontWeight: "bold",
        margin: "2px",
      },
      headerRow: {
        direction: "flex",
        justifyContent: "center",
      },
    }),
  };

  if (langVersion === "iu-cans") {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily;
    styles.mainTableCell.fontFamily = inuktitutFormPdfFontFamily;
    styles.helptextCell.fontFamily = inuktitutFormPdfFontFamily;
  }

  const combined = {
    expenses: {},
    revenues: {},
  };
  years.forEach((year) => {
    const keys = ["expenses", "revenues"];
    keys.forEach((typeKey) => {
      Object.entries(value[typeKey][year]).forEach(([key, obj]) => {
        let existing = _.get(combined[typeKey], key) || {
          value: 0,
          comments: [],
        };
        existing.value += obj.value;
        if (obj.comment) {
          existing.comments.push(obj.comment + "\n");
        }
        _.set(combined[typeKey], key, existing);
      });
    });
  });

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[pdfTableStyles.tableCol, styles.firstCol]} />

        <View
          style={[
            pdfTableStyles.tableCol,
            styles.secondCol,
            pdfTableStyles.headerTableCell,
          ]}
        >
          <Text style={styles.tableCell}>{myI18n._(t`Amount`)}</Text>
        </View>
        <View
          style={[
            pdfTableStyles.tableCol,
            styles.thirdCol,
            pdfTableStyles.headerTableCell,
          ]}
        >
          <Text style={styles.tableCell}>{myI18n._(t`Comments`)}</Text>
        </View>
      </View>

      <View
        style={[pdfTableStyles.tableCol, styles.firstCol, { width: "100%" }]}
      >
        <Text style={styles.mainTableCell}>{myI18n._(t`Revenues`)}</Text>
        {revenuesHelpText ? (
          <Text style={styles.helptextCell}>{revenuesHelpText}</Text>
        ) : null}
      </View>

      {revenues.map((item, index) => (
        <View style={styles.tableRow} wrap={false} key={index}>
          <View style={[pdfTableStyles.tableCol, styles.firstCol]}>
            <Text style={[styles.tableCell, pdfTableStyles.secondLevelRowCell]}>
              {myI18n._(item)}
            </Text>
          </View>
          <View style={[pdfTableStyles.tableCol, styles.secondCol]}>
            <Text style={styles.tableCell}>
              {currencyFormatedString(
                combined?.revenues?.[item]?.value,
                langVersion
              )}
            </Text>
          </View>
          <View style={[pdfTableStyles.tableCol, styles.thirdCol]}>
            <Text
              style={{
                ...styles.tableCell,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {combined?.revenues?.[item]?.comments.map((comment, index) => {
                return (
                  <Text style={{ width: "100%" }} key={index}>
                    {comment}
                  </Text>
                );
              })}
            </Text>
          </View>
        </View>
      ))}

      <View style={styles.tableRow} wrap={false}>
        <View
          style={[pdfTableStyles.tableCol, styles.firstCol, { width: "100%" }]}
        >
          <Text style={styles.mainTableCell}>{myI18n._(t`Expenses`)}</Text>
          {expensesHelpText ? (
            <Text style={styles.helptextCell}>{expensesHelpText}</Text>
          ) : null}
        </View>
      </View>

      {expenses.map((item, index) => {
        const itemKey = sanitizeLineKey(item);
        return (
          <View style={styles.tableRow} wrap={false} key={index}>
            <View style={[pdfTableStyles.tableCol, styles.firstCol]}>
              <Text
                style={[styles.tableCell, pdfTableStyles.secondLevelRowCell]}
              >
                {myI18n._(item)}
              </Text>
            </View>
            <View style={[pdfTableStyles.tableCol, styles.secondCol]}>
              <Text style={styles.tableCell}>
                {currencyFormatedString(
                  combined?.expenses?.[itemKey]?.value,
                  langVersion
                )}
              </Text>
            </View>
            <View style={[pdfTableStyles.tableCol, styles.thirdCol]}>
              <Text
                style={{
                  ...styles.tableCell,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {combined?.expenses?.[itemKey]?.comments.map(
                  (comment, index) => {
                    return (
                      <Text debug style={{ width: "100%" }} key={index}>
                        {comment}
                      </Text>
                    );
                  }
                )}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};
