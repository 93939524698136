import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'

const styles = {
  ...pdfTableStyles,
  ...StyleSheet.create({
    firstRow: {
      justifyContent: 'center',
      fontWeight: 'bold'
    },
    firstCol: {
      justifyContent: 'center',
      width: '35%'
    },
    secondCol: {
      width: '15%'
    },
    thirdCol: {
      width: '50%'
    },
    tableCol: {
      ...pdfTableStyles.tableCol,
      minHeight: 35,
    },
    mainTableCell: {
      ...pdfTableStyles.tableCell,
      fontWeight: 'bold',
    }
  })
}

export const FormBudgetFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  const expenses = typeProps.expensesLines || []
  const revenues = typeProps.revenuesLines || []
  const expensesHelpText = typeProps?.expensesHelpText?.[langVersion] || ''
  const revenuesHelpText = typeProps?.revenuesHelpText?.[langVersion] || ''

  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
    styles.mainTableCell.fontFamily = inuktitutFormPdfFontFamily
    styles.helptextCell.fontFamily = inuktitutFormPdfFontFamily
  }

  return (
    <View style={styles.table}>
      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableCol, styles.firstCol, styles.firstRow]}>
          <Text style={styles.mainTableCell}>{myI18n._(t`Revenues`)}</Text>
          {revenuesHelpText ? (
            <Text style={styles.helptextCell}>{revenuesHelpText}</Text>
          ) : null}
        </View>
        <View style={[styles.tableCol, styles.secondCol, styles.firstRow]}>
          <Text style={styles.tableCell}>{myI18n._(t`Amount`)}</Text>
        </View>
        <View style={[styles.tableCol, styles.thirdCol, styles.firstRow]}>
          <Text style={styles.tableCell}>{myI18n._(t`Comments`)}</Text>
        </View>
      </View>

      {revenues.map((item, index) => (
        <View style={styles.tableRow} wrap={false} key={index}>
          <View style={[styles.tableCol, styles.firstCol]}>
            <Text style={styles.tableCell}>{myI18n._(item)}</Text>
          </View>
          <View style={[styles.tableCol, styles.secondCol]}></View>
          <View style={[styles.tableCol, styles.thirdCol]}></View>
        </View>
      ))}

      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableCol, styles.firstCol]}>
          <Text style={styles.mainTableCell}>
            {myI18n._(t`SUM_OF_REVENUES`)}
          </Text>
        </View>
        <View style={[styles.tableCol, styles.secondCol]}></View>
        <View style={[styles.tableCol, styles.thirdCol]}></View>
      </View>

      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableCol, styles.firstCol]}>
          <Text style={styles.mainTableCell}>{myI18n._(t`Expenses`)}</Text>
          {expensesHelpText ? (
            <Text style={styles.helptextCell}>{expensesHelpText}</Text>
          ) : null}
        </View>
        <View style={[styles.tableCol, styles.secondCol]}></View>
        <View style={[styles.tableCol, styles.thirdCol]}></View>
      </View>

      {expenses.map((item, index) => (
        <View style={styles.tableRow} wrap={false} key={index}>
          <View style={[styles.tableCol, styles.firstCol]}>
            <Text style={styles.tableCell}>{myI18n._(item)}</Text>
          </View>
          <View style={[styles.tableCol, styles.secondCol]}></View>
          <View style={[styles.tableCol, styles.thirdCol]}></View>
        </View>
      ))}

      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableCol, styles.firstCol]}>
          <Text style={styles.mainTableCell}>
            {myI18n._(t`SUM_OF_EXPENSES`)}
          </Text>
        </View>
        <View style={[styles.tableCol, styles.secondCol]}></View>
        <View style={[styles.tableCol, styles.thirdCol]}></View>
      </View>
    </View>
  )
}
