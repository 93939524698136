import { Trans } from "@lingui/macro";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import DebouncedTextField from "app/views/forms/common/DebouncedTextField";
import { FormConnectToObject } from "app/views/forms/common/FormConnectToObject";
import { PdfPropsForm } from "app/views/forms/common/PdfPropsForm";
import ConfigureMultilanguageTextField from "app/views/internal/ConfigureMultilanguageTextField";
import { useDispatch } from "react-redux";
import { languages } from "translation/I18nConnectedProvider";
import { NumberFormatDefault } from "../../../common/Common";
import { FormProjectAffiliations } from "./FormProjectAffiliations";

export const FormEditorProjectAffiliations = ({
  editMode,
  showPdfProps,
  depth,
  typeProps = {},
  injectable,
  injectableId,
  disabled,
  ...props
}) => {
  const dispatch = useDispatch();
  const { roles = [], showRelationshipColumn } = typeProps;
  if (!editMode) {
    return (
      <FormProjectAffiliations editMode typeProps={typeProps} {...props} />
    );
  }
  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(showRelationshipColumn)}
              disabled={disabled}
              onChange={(e) => {
                const toSet = { ...typeProps };
                toSet.showRelationshipColumn = e.target.checked;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: { ...toSet },
                });
              }}
            />
          }
          label={<Trans>Show relationship column</Trans>}
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 5, marginBottom: 10 }}
          disabled={disabled}
          onClick={(e) => {
            const toSet = { ...typeProps };
            toSet.roles = [...roles];
            const label = languages.reduce((acc, lang) => {
              acc[lang] = "";
              return acc;
            }, {});
            toSet.roles.push({
              label,
              limit: 1,
            });
            dispatch({
              type: "FIELD",
              injectable,
              depth: depth.split("."),
              fieldName: "typeProps",
              fieldValue: { ...toSet },
            });
          }}
        >
          <Grid container alignItems="center">
            <Trans>Add contact role</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Grid>
        </Button>
        {roles.map((item, index) => (
          <Grid
            key={index}
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item xs>
              <ConfigureMultilanguageTextField
                value={item.label}
                label={<Trans>Role label</Trans>}
                handleChange={(value) => {
                  const toSet = { ...typeProps };
                  toSet.roles[index].label = value;
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
                useDebounce
              />
            </Grid>

            <Grid item xs style={{ padding: 5 }}>
              <DebouncedTextField
                variant="outlined"
                label={<Trans>API value</Trans>}
                fullWidth
                value={item.apiValue || ""}
                disabled={disabled}
                onChange={(e) => {
                  const toSet = { ...typeProps };
                  toSet.roles[index].apiValue = e.target.value;
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
              />
            </Grid>
            <Grid item xs style={{ padding: 5 }}>
              <TextField
                variant="outlined"
                label={<Trans>Default organization</Trans>}
                fullWidth
                select
                value={item.defaultOrganization || ""}
                disabled={disabled}
                onChange={(e) => {
                  const toSet = { ...typeProps };
                  toSet.roles[index].defaultOrganization = e.target.value;
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
              >
                <MenuItem value="current" key="current">
                  <Trans>Current Organization</Trans>
                </MenuItem>
                <MenuItem value="household" key="household">
                  <Trans>Household</Trans>
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item style={{ padding: 5, width: 100 }}>
              <DebouncedTextField
                variant="outlined"
                label={<Trans>Minimum</Trans>}
                fullWidth
                value={item.min || ""}
                InputProps={{ inputComponent: NumberFormatDefault }}
                disabled={disabled}
                onChange={(e) => {
                  const toSet = { ...typeProps };
                  const item = toSet.roles[index];
                  item.min = e.target.value;
                  if (item.min && item.limit && +item.limit < +item.min) {
                    item.limit = Number(+item.min + 1);
                  }
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
              />
            </Grid>
            <Grid item style={{ padding: 5, width: 100 }}>
              <DebouncedTextField
                variant="outlined"
                label={<Trans>Limit</Trans>}
                fullWidth
                value={item.limit || ""}
                InputProps={{ inputComponent: NumberFormatDefault }}
                disabled={disabled}
                onChange={(e) => {
                  const toSet = { ...typeProps };
                  const item = toSet.roles[index];
                  item.limit = e.target.value;
                  if (item.min && item.limit && +item.min > +item.limit) {
                    item.min = Number(+item.limit - 1);
                  }
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                style={{ margin: 0 }}
                disabled={disabled}
                onChange={(e) => {
                  const toSet = { ...typeProps };
                  toSet.roles[index].askForRelationship = e.target.checked;
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
                checked={Boolean(item.askForRelationship)}
                control={<Checkbox />}
                label={<Trans>Ask for relationship?</Trans>}
              />
            </Grid>
            <Grid item>
              <IconButton
                onClick={(e) => {
                  const toSet = { ...typeProps };
                  toSet.roles.splice(index, 1);
                  dispatch({
                    type: "FIELD",
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: { ...toSet },
                  });
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </div>

      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}

      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
    </div>
  );
};
