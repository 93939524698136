import { Trans } from "@lingui/macro";
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { dateFormat } from "app/appSettings";
import { DialogTitleWithIconClose } from "app/views/common-components/DialogTitleWithIconClose";
import { bilingualDateFormat } from "app/views/common/Formats";
import MUDatePicker from "app/views/forms/multiuser/components/MUDatePicker";
import MUTextField from "app/views/forms/multiuser/components/MUTextField";
import { commitChangeToMultipleFields } from "app/views/forms/multiuser/grpcMultiuserEdit";
import {
  endDateChange,
  startDateChange,
} from "app/views/utilities/milestone-date-calcs";
import { FieldArray, useFormikContext } from "formik";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { myI18n } from "translation/I18nConnectedProvider";
import "./FormMilestoneUpdate.css";

/**
 * Form element which allows adding and configuring project milestone updates.
 * Milestone Updates are saved as Benchmark_Update__c child objects on connected Grantee Report object.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection} connectedObject Connected Salesforce object of type Opportunity.
 * @param  {Object} connectedMap Map of all connected objects.
 * @param  {string[]} typeProps.options Array of phases defined in the form editor. For each one user will need to fill the required information.
 * @param  {number}  [typeProps.maxChar] If provided, the number of characters that a text input field will be restricted to.
 * @param  {number}  [typeProps.maxWords] If provided, the number of words that a text input field will be restricted to.
 * @param  {number}  [typeProps.rowQuantity = 1] Number of rows which a text input field should occupy.
 * @param  {boolean} [typeProps.expandable=false] If a text field should expand further below if new line is inserted.
 */
export const FormMilestoneUpdate = ({
  id,
  useMultiuser,
  muBag,
  title,
  helpText,
  connectedObject,
  editMode,
  disabled,
  typeProps,
  describeMap,
  langVersion,
  i18n,
  connectedMap,
  ...props
}) => {
  const { maxWords, rowQuantity, expandable } = typeProps;
  const { setFieldValue } = useFormikContext();
  const value = props.value || [];

  const language = useSelector((state) => state.user.language) || "en";

  const granteeReportConnectedId = typeProps.connectedTo?.find(
    (item) =>
      connectedMap?.[item?.connectedObject]?.objectType ===
      "FGM_Base__Grantee_Report__c"
  )?.connectedObject;

  const lastReportTitle = (
    <Trans>FORM_MILESTONE_UPDATE_LAST_REPORT_SUBTITLE</Trans>
  );
  const previousReportTitle = (
    <Trans>FORM_MILESTONE_UPDATE_PREVIOUS_REPORT_SUBTITLE</Trans>
  );

  const [dialog, setDialog] = useState({
    open: false,
    name: null,
    milestones: [],
    setFieldValue: null,
  });

  const confirmDateChange = () => {
    const { name, milestones } = dialog;
    const toSet = [...milestones];
    milestones.forEach((milestone, idx) => {
      milestone.milestoneUpdate.areDatesChanged = "no";
      milestone.milestoneUpdate.phaseStartDate = milestone.startDate;
      milestone.milestoneUpdate.phaseEndDate = milestone.endDate;
    });
    setFieldValue(`${name}`, toSet);
    setDialog({
      open: false,
      name: null,
      milestones: [],
      setFieldValue: null,
    });
  };

  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== "Opportunity"
  );
  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: "red" }}>
        <Trans>
          There is no object connected in editor or main connected object is not
          of "Opportunity" type!
        </Trans>
      </div>
    );
  }

  if (!granteeReportConnectedId && !editMode) {
    return (
      <div style={{ padding: 10, color: "red" }}>
        <Trans>FORM_MILESTONE_UPDATE_GRANTEE_REPORT_CONNECTION_ERROR</Trans>
      </div>
    );
  }

  const onNoClick = () => {
    setDialog({
      open: false,
      name: null,
      milestones: [],
      setFieldValue: null,
    });
  };

  return (
    <Paper className="'form-update-root">
      <Dialog open={dialog.open}>
        <DialogTitleWithIconClose
          label={
            <Trans>FORM_MILESTONE_UPDATE_CANCEL_DATE_CHANGING_QUESTION</Trans>
          }
          handleClose={onNoClick}
        />
        <DialogContent
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Button onClick={onNoClick} color="primary" variant="contained">
            <Trans>No</Trans>
          </Button>
          <Button
            onClick={() => confirmDateChange()}
            color="primary"
            variant="contained"
          >
            <Trans>Yes</Trans>
          </Button>
        </DialogContent>
      </Dialog>

      <FieldArray
        name={id}
        render={({ push, form, name, remove, meta }) => {
          const {
            setFieldValue,
            setFieldTouched,
            errors,

            touched,
          } = form;
          const milestones = form.values[name] || [];
          const milestonesErrors = errors[name] || [];
          const touchedMilestones = touched[name] || [];

          return (
            <div className="form-update body">
              <h2 className="main-title">{title}</h2>

              <Card className="form-update card" elevation={6}>
                <div className="description">
                  <Grid item xs={6}>
                    <h3>
                      <Trans>FORM_MILESTONE_UPDATE_VIEW_ONLY_TITLE</Trans>
                    </h3>
                  </Grid>
                  <Grid item xs={6}>
                    <h3>
                      <Trans>FORM_MILESTONE_UPDATE_UPDATES_TITLE</Trans>
                    </h3>
                  </Grid>
                </div>
              </Card>

              {milestones.map((item, index) => {
                // console.log({item})
                const areDatesChanged =
                  item.milestoneUpdate?.areDatesChanged || "no";
                const milestoneUpdateError =
                  milestonesErrors?.[index]?.milestoneUpdate;
                const milestoneUpdateTouched =
                  touchedMilestones?.[index]?.milestoneUpdate;
                const isPhaseDateError =
                  Boolean(milestoneUpdateError?.phaseDate) &&
                  (milestoneUpdateTouched?.phaseStartDate ||
                    milestoneUpdateTouched?.phaseEndDate);
                const startDate = !item.startDate
                  ? ""
                  : typeof item.startDate === "string"
                  ? item.startDate
                  : item.startDate.startOf("day").format(dateFormat);
                const endDate = !item.endDate
                  ? ""
                  : typeof item.endDate === "string"
                  ? item.endDate
                  : item.endDate.startOf("day").format(dateFormat);
                return (
                  <Card className="form-update card" elevation={6}>
                    <div
                      className={`title-section section ${item.id}`}
                      {...(item.id ? { id: item.id } : {})}
                    >
                      <h2>{myI18n._(item.stage)}</h2>
                    </div>

                    <div className="section">
                      <div className="updates">
                        <Grid item container xs={6} direction="row">
                          <Grid item xs={2}></Grid>
                          <Grid
                            item
                            xs={10}
                            container
                            className="milestone-dates"
                          >
                            <div style={{ display: "flex" }}>
                              <h4 className="subtitle">
                                <Trans>Start Date</Trans>:
                              </h4>
                              <p>{startDate}</p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <h4 className="subtitle">
                                <Trans>End Date</Trans>:
                              </h4>
                              <p>{endDate}</p>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          {index === 0 && (
                            <>
                              <p className="date-question">
                                <Trans>
                                  FORM_MILESTONE_UPDATE_ARE_DATES_CHANGED_SUBTITLE
                                </Trans>
                              </p>
                              <FormControl>
                                <RadioGroup
                                  aria-label="areDatesChanged"
                                  name="areDatesChanged"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingBottom: "24px",
                                  }}
                                  value={areDatesChanged}
                                  onChange={(e, value) => {
                                    const newValue = e.target.value;
                                    const areDatesChanged = milestones.some(
                                      (item, idx) => {
                                        return (
                                          item.startDate !==
                                            item.milestoneUpdate
                                              .phaseStartDate ||
                                          item.endDate !==
                                            item.milestoneUpdate.phaseEndDate
                                        );
                                      }
                                    );
                                    if (newValue === "no" && areDatesChanged) {
                                      setDialog({
                                        open: true,
                                        name,
                                        setFieldValue,
                                        milestones,
                                      });
                                    } else {
                                      const toSet = [...milestones];
                                      milestones.forEach((milestone, idx) => {
                                        milestone.milestoneUpdate.areDatesChanged =
                                          newValue;
                                      });
                                      setFieldValue(`${name}`, toSet);
                                    }
                                  }}
                                >
                                  <FormControlLabel
                                    disabled={disabled}
                                    value="yes"
                                    control={<Radio />}
                                    label={<Trans>Yes</Trans>}
                                  />
                                  <FormControlLabel
                                    disabled={disabled}
                                    value="no"
                                    control={<Radio />}
                                    label={<Trans>No</Trans>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </>
                          )}
                          {areDatesChanged === "yes" && (
                            <div>
                              <Typography
                                variant="subtitle2"
                                style={{ marginBottom: 4 }}
                              >
                                <Trans>
                                  FORM_MILESTONE_UPDATE_DATES_UPDATE_PROMPT
                                </Trans>
                              </Typography>
                            </div>
                          )}
                          {isPhaseDateError && !disabled && (
                            <div>
                              <Typography
                                variant="subtitle2"
                                className="error-dates"
                                style={{ marginBottom: 4, fontWeight: 400 }}
                              >
                                {milestoneUpdateError?.phaseDate}
                              </Typography>
                            </div>
                          )}
                          <Grid
                            container
                            wrap="nowrap"
                            className="date-pickers"
                          >
                            <Grid
                              item
                              xs
                              className={`bordered-date-picker ${
                                areDatesChanged === "no" ? "disabled" : ""
                              }`}
                            >
                              <Typography variant="subtitle2">
                                <Trans>Start date</Trans>
                              </Typography>
                              <MUDatePicker
                                id={
                                  id +
                                  `[${index}].milestoneUpdate.phaseStartDate`
                                }
                                muBag={muBag}
                                useMultiuser={useMultiuser}
                                displayFieldHistoryIcon={true}
                                autoOk
                                // minDate={endDateMin}
                                // maxDate={endDateMax}
                                disabled={disabled || areDatesChanged === "no"}
                                format={dateFormat}
                                /** property shouldUnlockFieldWithoutChangesAfterOnChangeEvent shows if field should be unlocked without changes
                                 * after onChange event. */
                                shouldUnlockFieldWithoutChangesAfterOnChangeEvent={
                                  true
                                }
                                notTouchOnOpen={true}
                                handleOpen={() => {
                                  setFieldTouched(
                                    `${name}[${index}].milestoneUpdate.phaseStartDate`,
                                    false
                                  );
                                  setFieldTouched(
                                    `${name}[${index}].milestoneUpdate.phaseEndDate`,
                                    false
                                  );
                                }}
                                handleClose={() => {
                                  setFieldTouched(
                                    `${name}[${index}].milestoneUpdate.phaseStartDate`,
                                    true
                                  );
                                  setFieldTouched(
                                    `${name}[${index}].milestoneUpdate.phaseEndDate`,
                                    true
                                  );
                                }}
                                onChange={(event) => {
                                  const toSet = _.cloneDeep(value);
                                  const item = _.cloneDeep(toSet[index]);
                                  /** if date is not changed, unlock field without changes */
                                  if (
                                    moment(event).format("YYYY-MM-DD") ===
                                    moment(
                                      item.milestoneUpdate.phaseStartDate
                                    ).format("YYYY-MM-DD")
                                  ) {
                                    return;
                                  }
                                  /** Create array of fields to be changed by this event.
                                   * All this fields will be handled by commitChangeToMultipleFields to save them in backend history properly */
                                  const arrToUpd = [];
                                  if (!toSet[index]) {
                                    toSet[index] = {};
                                  }
                                  toSet[index].milestoneUpdate.phaseStartDate =
                                    event;
                                  startDateChange({
                                    phases: toSet,
                                    id,
                                    idx: index,
                                    arrToUpd,
                                    fields: {
                                      startDate:
                                        "milestoneUpdate.phaseStartDate",
                                      endDate: "milestoneUpdate.phaseEndDate",
                                      endDateMinDate:
                                        "milestoneUpdate.endDateMinDate",
                                      endDateMaxDate:
                                        "milestoneUpdate.endDateMaxDate",
                                      startDateMinDate:
                                        "milestoneUpdate.startDateMinDate",
                                      startDateMaxDate:
                                        "milestoneUpdate.startDateMaxDate",
                                    },
                                  });

                                  setFieldValue(id, toSet);
                                  if (useMultiuser) {
                                    commitChangeToMultipleFields({
                                      array: arrToUpd,
                                      onSuccess: (response) => {},
                                      onFail: (error) => {
                                        console.log("error", { error });
                                      },
                                      token: muBag.token,
                                    });
                                  }
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs
                              className={`bordered-date-picker ${
                                areDatesChanged === "no" ? "disabled" : ""
                              }`}
                            >
                              <Typography variant="subtitle2">
                                <Trans>End date</Trans>
                              </Typography>
                              <MUDatePicker
                                id={
                                  id + `[${index}].milestoneUpdate.phaseEndDate`
                                }
                                muBag={muBag}
                                useMultiuser={useMultiuser}
                                displayFieldHistoryIcon={true}
                                autoOk
                                // minDate={endDateMin}
                                // maxDate={endDateMax}
                                disabled={disabled || areDatesChanged === "no"}
                                format={dateFormat}
                                /** property shouldUnlockFieldWithoutChangesAfterOnChangeEvent shows if field should be unlocked without changes
                                 * after onChange event. */
                                shouldUnlockFieldWithoutChangesAfterOnChangeEvent={
                                  true
                                }
                                notTouchOnOpen={true}
                                handleOpen={() => {
                                  setFieldTouched(
                                    `${name}[${index}].milestoneUpdate.phaseStartDate`,
                                    false
                                  );
                                  setFieldTouched(
                                    `${name}[${index}].milestoneUpdate.phaseEndDate`,
                                    false
                                  );
                                }}
                                handleClose={() => {
                                  setFieldTouched(
                                    `${name}[${index}].milestoneUpdate.phaseStartDate`,
                                    true
                                  );
                                  setFieldTouched(
                                    `${name}[${index}].milestoneUpdate.phaseEndDate`,
                                    true
                                  );
                                }}
                                onChange={(event) => {
                                  console.log("MuDatePicker onChange", {
                                    event,
                                  });
                                  const toSet = _.cloneDeep(value);
                                  const item = _.cloneDeep(toSet[index]);

                                  /** if date is not changed, unlock field without changes */
                                  if (
                                    moment(event).format("YYYY-MM-DD") ===
                                    moment(
                                      item.milestoneUpdate.phaseEndDate
                                    ).format("YYYY-MM-DD")
                                  ) {
                                    return;
                                  }
                                  /** Create array of fields to be changed by this event.
                                   * All this fields will be handled by commitChangeToMultipleFields to save them in backend history properly */
                                  const arrToUpd = [];
                                  if (!toSet[index]) {
                                    toSet[index] = {};
                                  }
                                  toSet[index].milestoneUpdate.phaseEndDate =
                                    event;
                                  endDateChange({
                                    phases: toSet,
                                    id,
                                    idx: index,
                                    arrToUpd,
                                    fields: {
                                      startDate:
                                        "milestoneUpdate.phaseStartDate",
                                      endDate: "milestoneUpdate.phaseEndDate",
                                      endDateMinDate:
                                        "milestoneUpdate.endDateMinDate",
                                      endDateMaxDate:
                                        "milestoneUpdate.endDateMaxDate",
                                      startDateMinDate:
                                        "milestoneUpdate.startDateMinDate",
                                      startDateMaxDate:
                                        "milestoneUpdate.startDateMaxDate",
                                    },
                                  });
                                  // toSet[index] = item
                                  setFieldValue(id, toSet);
                                  if (useMultiuser) {
                                    commitChangeToMultipleFields({
                                      array: arrToUpd,
                                      onSuccess: (response) => {},
                                      onFail: (error) => {
                                        console.log("error", { error });
                                      },
                                      token: muBag.token,
                                    });
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <div className="section no-border">
                      <div className="updates"></div>
                      <div className="questionAnswers">
                        <Grid item container xs={6} direction="column">
                          <Grid
                            item
                            container
                            direction="column"
                            style={{ marginBottom: 32 }}
                          >
                            <Grid item>
                              <h4>
                                <Trans>
                                  FORM_MILESTONE_UPDATE_PRIMARY_ACTIVITIES_TITLE
                                </Trans>
                              </h4>
                            </Grid>
                            <Grid item className="outcomes">
                              {item.primaryActivities}
                            </Grid>
                          </Grid>
                          <Grid item>
                            <div className="answers">
                              {item.reportMilestoneUpdates.map(
                                (answer, idx) => {
                                  const title =
                                    idx === 0
                                      ? lastReportTitle
                                      : previousReportTitle;
                                  const formattedDate = bilingualDateFormat(
                                    item?.reports[idx]
                                      ?.FGM_Base__Submission_Date__c,
                                    language
                                  );
                                  return (
                                    <Grid direction="column" className="answer">
                                      <h4>
                                        {title}: {formattedDate}
                                      </h4>
                                      <p>
                                        {answer?.Primary_activities_planned__c}
                                      </p>
                                    </Grid>
                                  );
                                }
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid item xs={6}>
                          <p className="question">
                            <Trans>
                              FORM_MILESTONE_UPDATE_PRIMARY_ACTIVITIES_QUESTION
                            </Trans>
                          </p>

                          <MUTextField
                            useMultiuser={useMultiuser}
                            muBag={muBag}
                            id={
                              id +
                              `[${index}].milestoneUpdate.primaryActivities`
                            }
                            label=""
                            multiline
                            minRows={rowQuantity || 5}
                            maxRows={expandable ? null : rowQuantity || 5}
                            disabled={disabled}
                            margin="normal"
                            limit={800}
                            maxWords={maxWords && Number(maxWords)}
                          />
                        </Grid>
                      </div>
                    </div>

                    <div className="section">
                      <div className="updates">
                        <Grid item container xs={6} direction="column">
                          <Grid item>
                            {/* <h4><Trans>Comments</Trans></h4> */}
                            <h4>
                              <Trans>
                                FORM_MILESTONE_UPDATE_COMMENTS_TITLE
                              </Trans>
                            </h4>
                          </Grid>
                          <Grid item className="outcomes">
                            {item.comments}
                          </Grid>
                          <Grid item xs={6}></Grid>
                        </Grid>
                      </div>
                    </div>

                    <div className="section">
                      <div className="updates">
                        <Grid container xs={6} direction="column">
                          <div style={{ marginBottom: "32px" }}>
                            <h4>
                              <Trans>
                                FORM_MILESTONE_UPDATE_IMPLEMENTED_ACTIVITIES_TITLE
                              </Trans>
                            </h4>
                          </div>
                          <div className="outcomes">
                            <div className="answers">
                              {item.reportMilestoneUpdates.map(
                                (answer, idx) => {
                                  const title =
                                    idx === 0
                                      ? lastReportTitle
                                      : previousReportTitle;
                                  const bilingualDate = bilingualDateFormat(
                                    item?.reports[idx]
                                      ?.FGM_Base__Submission_Date__c,
                                    language
                                  );
                                  return (
                                    <Grid direction="column" className="answer">
                                      <h4>
                                        {title}: {bilingualDate}
                                      </h4>
                                      <p>
                                        {answer?.Primary_activities_progress__c}
                                      </p>
                                    </Grid>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <p className="question">
                            <Trans>
                              FORM_MILESTONE_UPDATE_IMPLEMENTED_ACTIVITIES_QUESTION
                            </Trans>
                          </p>

                          <MUTextField
                            useMultiuser={useMultiuser}
                            muBag={muBag}
                            id={
                              id +
                              `[${index}].milestoneUpdate.implementedActivities`
                            }
                            label=""
                            multiline
                            minRows={rowQuantity || 5}
                            maxRows={expandable ? null : rowQuantity || 5}
                            disabled={disabled}
                            margin="normal"
                            limit={800}
                            maxWords={maxWords && Number(maxWords)}
                            required
                          />
                        </Grid>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>
          );
        }}
      />
    </Paper>
  );
};
