import { Trans } from "@lingui/macro";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FormColorAutocomplete } from "app/views/forms/common/FormColorAutocomplete";
import ConfigureMultilanguageTextField from "app/views/internal/ConfigureMultilanguageTextField";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { languages } from "translation/I18nConnectedProvider";
import { NumberFormatDefault } from "../../common/Common";
import DebouncedTextField from "../../common/DebouncedTextField";
import { FormConnectToObject } from "../../common/FormConnectToObject";
import { PdfPropsForm } from "../../common/PdfPropsForm";
import { FormTable } from "./FormTable";

export const FormEditorTable = ({
  depth,
  showPdfProps,
  typeProps,
  editMode,
  injectable,
  injectableId,
  disabled,
  ...props
}) => {
  const dispatch = useDispatch();
  const { values } = useFormikContext();
  const { objects, objectsConnected } = values;
  const {
    connectedTo = [],
    columns = [],
    pdfTableFontSize,
    pdfTableTitleFontSize,
    pdfTableTitleTextAlign,
    pdfTableTitleProps = [],
    sortOrder = "asc",
  } = typeProps;

  if (!editMode) {
    return <FormTable editMode typeProps={typeProps} {...props} />;
  }

  let options = [];
  if (connectedTo.length > 0) {
    const connectedObject = connectedTo[0].connectedObject;
    const collectionKey = connectedTo[0]?.connectedCollection;
    const avaliableObjectsMap = {};
    objectsConnected.forEach((obj) => {
      avaliableObjectsMap[obj.identId] = obj;
    });
    connectedObject &&
      objects.some((obj) => {
        if (!avaliableObjectsMap[connectedObject]) {
          return false;
        }
        const bool = obj.name === avaliableObjectsMap[connectedObject].type;
        if (bool) {
          const { relatedCollections } = obj;
          relatedCollections.some((collection) => {
            if (collection.key === collectionKey) {
              options = collection.fields;
            }
            return collection.key === collectionKey;
          });
        }
        return bool;
      });
  }
  const optionsMap = {};
  options.forEach((opt) => {
    optionsMap[opt.name] = opt;
  });

  return (
    <div>
      {showPdfProps && (
        <FormControl style={{ padding: 5 }}>
          <FormLabel>
            <Trans>Columns sorting</Trans>
          </FormLabel>
          <RadioGroup
            value={sortOrder}
            onChange={(e) => {
              const toSet = { ...typeProps };
              toSet.sortOrder = e.target.value;
              dispatch({
                type: "FIELD",
                injectable,
                depth: depth.split("."),
                fieldName: "typeProps",
                fieldValue: toSet,
              });
            }}
            row
          >
            {[
              { label: <Trans>Ascending</Trans>, value: "asc" },
              { label: <Trans>Descending</Trans>, value: "desc" },
            ].map((obj, index) => (
              <FormControlLabel
                key={index}
                value={obj.value}
                disabled={disabled}
                control={<Radio />}
                label={obj.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}

      <Grid container style={{ padding: 5 }} alignItems="center">
        <Typography variant="h6" style={{ marginRight: 20 }}>
          <Trans>Columns</Trans>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={(e) => {
            const toSet = { ...typeProps };
            toSet.columns = [...columns];
            const label = languages.reduce((acc, lang) => {
              acc[lang] = "";
              return acc;
            }, {});
            toSet.columns.push({
              label,
              field: "",
              pdfHeaderTextProps: [],
            });
            dispatch({
              type: "FIELD",
              injectable,
              depth: depth.split("."),
              fieldName: "typeProps",
              fieldValue: { ...toSet },
            });
          }}
        >
          <Grid container alignItems="center">
            <Trans>Add column</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Grid>
        </Button>
      </Grid>

      {columns.map((column, index) => {
        const {
          label,
          field,
          translate,
          pdfColumnFlex,
          pdfHeaderTextProps = [],
          pdfHeaderTextAlign,
          pdfCellTextAlign,
          pdfBackgroundColor,
          pdfFontSize,
          sortByThisColumn,
        } = column;
        return (
          <div key={index}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs style={{ marginLeft: 20 }}>
                <b>{index + 1 + ". "}</b>
              </Grid>
              <IconButton
                size="small"
                onClick={(e) => {
                  const toSet = { ...typeProps };
                  const toMove = toSet.columns[index];
                  const toReplace = toSet.columns[index + 1];
                  toSet.columns[index + 1] = toMove;
                  toSet.columns[index] = toReplace;
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: { ...toSet },
                  });
                }}
                disabled={index === columns.length - 1 || disabled}
              >
                <Icon>arrow_downward</Icon>
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  const toSet = { ...typeProps };
                  const toMove = toSet.columns[index];
                  const toReplace = toSet.columns[index - 1];
                  toSet.columns[index - 1] = toMove;
                  toSet.columns[index] = toReplace;
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: { ...toSet },
                  });
                }}
                disabled={index === 0 || disabled}
              >
                <Icon>arrow_upward</Icon>
              </IconButton>
              <IconButton
                disabled={disabled}
                onClick={(e) => {
                  const toSet = { ...typeProps };
                  toSet.columns.splice(index, 1);
                  dispatch({
                    type: "FIELD",
                    injectable,

                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: { ...toSet },
                  });
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Grid>

            <ConfigureMultilanguageTextField
              value={label}
              label={<Trans>Header label</Trans>}
              handleChange={(value) => {
                const toSet = { ...typeProps };
                toSet.columns[index].label = value;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: toSet,
                });
              }}
              useDebounce
            />

            <Autocomplete
              style={{ paddingTop: 10 }}
              freeSolo={false}
              value={field?.name || ""}
              disabled={disabled}
              onChange={(e, value) => {
                const toSet = { ...typeProps };
                toSet.columns[index].field = {
                  name: value.name,
                  type: value.type,
                };
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: toSet,
                });
              }}
              fullWidth
              options={options}
              getOptionSelected={(o, v) => o.name === v}
              getOptionLabel={(o) => {
                if (typeof o === "object") {
                  return o.label || "";
                } else {
                  return optionsMap[o]?.label || "";
                }
              }}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  label={<Trans>Column field</Trans>}
                />
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ marginLeft: 10 }}
                  checked={Boolean(translate)}
                  disabled={disabled}
                  onChange={(e) => {
                    const toSet = { ...typeProps };
                    toSet.columns[index].translate = e.target.checked;
                    delete toSet.justify;
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: { ...toSet },
                    });
                  }}
                />
              }
              label={<Trans>Translate field</Trans>}
            />
            {showPdfProps && (
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ marginLeft: 10 }}
                    checked={Boolean(sortByThisColumn)}
                    disabled={disabled}
                    onChange={(e) => {
                      const toSet = { ...typeProps };
                      toSet.columns.forEach((column, index) => {
                        delete toSet.columns[index].sortByThisColumn;
                      });
                      toSet.columns[index].sortByThisColumn = e.target.checked;
                      dispatch({
                        type: "FIELD",
                        injectable,
                        depth: depth.split("."),
                        fieldName: "typeProps",
                        fieldValue: { ...toSet },
                      });
                    }}
                  />
                }
                label={<Trans>Sort by this column</Trans>}
              />
            )}
            {showPdfProps && (
              <div style={{ padding: 10 }}>
                <div style={{ padding: 5 }}>
                  <Typography style={{ marginBottom: 10, fontSize: 16 }}>
                    <Trans>Cell</Trans> - <Trans>Pdf props</Trans>
                  </Typography>
                  <FormControl>
                    <FormLabel>
                      <Trans>Text align</Trans>
                    </FormLabel>
                    <RadioGroup
                      value={pdfCellTextAlign || "center"}
                      disabled={disabled}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        toSet.columns[index].pdfCellTextAlign = e.target.value;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                      row
                    >
                      {[
                        { label: <Trans>Left</Trans>, value: "left" },
                        { label: <Trans>Center</Trans>, value: "center" },
                        { label: <Trans>Right</Trans>, value: "right" },
                      ].map((obj, index) => (
                        <FormControlLabel
                          key={index}
                          value={obj.value}
                          control={
                            <Radio checked={pdfCellTextAlign === obj.value} />
                          }
                          label={obj.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>

                <div style={{ padding: 5 }}>
                  <Typography style={{ marginBottom: 10, fontSize: 16 }}>
                    <Trans>Header</Trans> - <Trans>Pdf props</Trans>
                  </Typography>

                  <Grid container>
                    <Grid item xs style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <DebouncedTextField
                        label={<Trans>Font size</Trans>}
                        fullWidth
                        variant="outlined"
                        value={pdfFontSize || ""}
                        disabled={disabled}
                        onChange={(e) => {
                          const toSet = { ...typeProps };
                          toSet.columns[index].pdfFontSize = e.target.value;
                          dispatch({
                            type: "FIELD",
                            injectable,
                            depth: depth.split("."),
                            fieldName: "typeProps",
                            fieldValue: toSet,
                          });
                        }}
                        inputProps={{
                          maxLength: 2,
                        }}
                        InputProps={{
                          inputComponent: NumberFormatDefault,
                        }}
                      />
                    </Grid>
                    <Grid item xs style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <DebouncedTextField
                        label={<Trans>Column size</Trans>}
                        fullWidth
                        variant="outlined"
                        value={pdfColumnFlex || "1"}
                        disabled={disabled}
                        onChange={(e) => {
                          const toSet = { ...typeProps };
                          toSet.columns[index].pdfColumnFlex = e.target.value;
                          dispatch({
                            type: "FIELD",
                            injectable,
                            depth: depth.split("."),
                            fieldName: "typeProps",
                            fieldValue: toSet,
                          });
                        }}
                        inputProps={{
                          maxLength: 2,
                        }}
                        InputProps={{
                          inputComponent: NumberFormatDefault,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <FormGroup row>
                    {[
                      { key: "bold", label: <Trans>Bold</Trans> },
                      { key: "italics", label: <Trans>Italics</Trans> },
                      { key: "underline", label: <Trans>Underline</Trans> },
                    ].map((item) => (
                      <FormControlLabel
                        key={item.key}
                        control={
                          <Checkbox
                            checked={pdfHeaderTextProps.includes(item.key)}
                            value={item.key}
                            disabled={disabled}
                            onChange={(e) => {
                              const v = e.target.value;
                              const toSet = { ...typeProps };
                              const newTextProps = [...pdfHeaderTextProps];
                              if (newTextProps.includes(v)) {
                                newTextProps.splice(newTextProps.indexOf(v), 1);
                              } else {
                                newTextProps.push(v);
                              }
                              toSet.columns[index].pdfHeaderTextProps =
                                newTextProps;
                              dispatch({
                                type: "FIELD",
                                injectable,
                                depth: depth.split("."),
                                fieldName: "typeProps",
                                fieldValue: { ...toSet },
                              });
                            }}
                          />
                        }
                        label={item.label}
                      />
                    ))}
                  </FormGroup>

                  <FormControl>
                    <FormLabel>
                      <Trans>Text align</Trans>
                    </FormLabel>
                    <RadioGroup
                      value={pdfHeaderTextAlign || "center"}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        toSet.columns[index].pdfHeaderTextAlign =
                          e.target.value;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                      row
                    >
                      {[
                        { label: <Trans>Left</Trans>, value: "left" },
                        { label: <Trans>Center</Trans>, value: "center" },
                        { label: <Trans>Right</Trans>, value: "right" },
                      ].map((obj, index) => (
                        <FormControlLabel
                          key={index}
                          value={obj.value}
                          disabled={disabled}
                          control={
                            <Radio checked={pdfHeaderTextAlign === obj.value} />
                          }
                          label={obj.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormColorAutocomplete
                    injectable={injectable}
                    depth={depth}
                    disabled={disabled}
                    fieldArray="columns"
                    fieldArrayIndex={index}
                    label={<Trans>Cell background color</Trans>}
                    name="pdfBackgroundColor"
                    typeProps={typeProps}
                    value={pdfBackgroundColor}
                  />
                </div>
              </div>
            )}

            <Divider />
          </div>
        );
      })}
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        connectToCollection
        disableMultiple
        clearOnChange={["columns"]}
        clearOnDisconnect={["columns"]}
      />
      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <PdfPropsForm
            typeProps={typeProps}
            dispatch={dispatch}
            injectable={injectable}
            depth={depth}
          />

          <div style={{ padding: 5, marginTop: 10 }}>
            <Typography style={{ marginBottom: 10, fontSize: 16 }}>
              <Trans>Table</Trans>
            </Typography>
            <DebouncedTextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant="outlined"
              value={pdfTableFontSize || ""}
              disabled={disabled}
              onChange={(e) => {
                const toSet = { ...typeProps };
                toSet.pdfTableFontSize = e.target.value;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: toSet,
                });
              }}
              inputProps={{
                maxLength: 2,
              }}
              InputProps={{
                inputComponent: NumberFormatDefault,
              }}
            />

            <Typography
              style={{ marginTop: 10, marginBottom: 10, fontSize: 16 }}
            >
              <Trans>Table title</Trans>
            </Typography>
            <DebouncedTextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant="outlined"
              value={pdfTableTitleFontSize || ""}
              disabled={disabled}
              onChange={(e) => {
                const toSet = { ...typeProps };
                toSet.pdfTableTitleFontSize = e.target.value;
                dispatch({
                  type: "FIELD",
                  injectable,
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: toSet,
                });
              }}
              inputProps={{
                maxLength: 2,
              }}
              InputProps={{
                inputComponent: NumberFormatDefault,
              }}
            />
            <FormGroup row>
              {[
                { key: "bold", label: <Trans>Bold</Trans> },
                { key: "italics", label: <Trans>Italics</Trans> },
                { key: "underline", label: <Trans>Underline</Trans> },
              ].map((item) => (
                <FormControlLabel
                  key={item.key}
                  control={
                    <Checkbox
                      checked={pdfTableTitleProps.includes(item.key)}
                      value={item.key}
                      disabled={disabled}
                      onChange={(e) => {
                        const v = e.target.value;
                        const toSet = { ...typeProps };
                        const newTextProps = [...pdfTableTitleProps];
                        if (newTextProps.includes(v)) {
                          newTextProps.splice(newTextProps.indexOf(v), 1);
                        } else {
                          newTextProps.push(v);
                        }
                        toSet.pdfTableTitleProps = newTextProps;
                        dispatch({
                          type: "FIELD",
                          injectable,
                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: { ...toSet },
                        });
                      }}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
            <FormControl>
              <FormLabel>
                <Trans>Text align</Trans>
              </FormLabel>
              <RadioGroup
                value={pdfTableTitleTextAlign || "left"}
                onChange={(e) => {
                  const toSet = { ...typeProps };
                  toSet.pdfTableTitleTextAlign = e.target.value;
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
                row
              >
                {[
                  { label: <Trans>Left</Trans>, value: "left" },
                  { label: <Trans>Center</Trans>, value: "center" },
                  { label: <Trans>Right</Trans>, value: "right" },
                ].map((obj, index) => (
                  <FormControlLabel
                    key={index}
                    value={obj.value}
                    disabled={disabled}
                    control={
                      <Radio
                        checked={
                          (!pdfTableTitleTextAlign && obj.value === "left") ||
                          pdfTableTitleTextAlign === obj.value
                        }
                      />
                    }
                    label={obj.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Grid container>
              {[
                {
                  key: "pdfTableTitlePaddingLeft",
                  label: <Trans>Padding left</Trans>,
                },
                {
                  key: "pdfTableTitlePaddingRight",
                  label: <Trans>Padding right</Trans>,
                },
                {
                  key: "pdfTableTitlePaddingTop",
                  label: <Trans>Padding top</Trans>,
                },
                {
                  key: "pdfTableTitlePaddingBottom",
                  label: <Trans>Padding bottom</Trans>,
                },
              ].map((obj, index) => {
                return (
                  <Grid item xs key={index} style={{ padding: 5 }}>
                    <DebouncedTextField
                      style={{ marginBottom: 10 }}
                      label={obj.label}
                      value={typeProps[obj.key] || "0"}
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatDefault,
                      }}
                      variant="outlined"
                      disabled={disabled}
                      onChange={(e) => {
                        const toSet = { ...typeProps };
                        toSet[obj.key] = e.target.value;
                        dispatch({
                          type: "FIELD",
                          injectable,

                          depth: depth.split("."),
                          fieldName: "typeProps",
                          fieldValue: toSet,
                        });
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};
