import { Trans } from "@lingui/macro";
import { TextField } from "@material-ui/core";
import { FormConnectToObject } from "app/views/forms/common/FormConnectToObject";
import { PdfPropsForm } from "app/views/forms/common/PdfPropsForm";
import { useDispatch } from "react-redux";
import { FormAffiliatedOrganizations } from "./FormAffiliatedOrganizations";
import { FormAffiliatedOrganizationsReadOnly } from "./FormAffiliatedOrganizationsReadOnly";

export const FormEditorAffiliatedOrganizations = ({
  editMode,
  depth,
  showPrintProps,
  showPdfProps,
  showEditableProps,
  showPrintComponent,
  typeProps = {},
  disabled,
  injectable,
  injectableId,
  ...props
}) => {
  const dispatch = useDispatch();
  const { tag } = typeProps;
  if (!editMode) {
    if (showPrintComponent) {
      return (
        <FormAffiliatedOrganizationsReadOnly
          editorPreview
          typeProps={typeProps}
          {...props}
        />
      );
    }
    return (
      <FormAffiliatedOrganizations editMode typeProps={typeProps} {...props} />
    );
  }
  return (
    <div>
      {!showPrintProps && (
        <TextField
          label={<Trans>Tag</Trans>}
          fullWidth
          value={tag || ""}
          variant="outlined"
          disabled={disabled}
          onChange={(e) => {
            const toSet = { ...typeProps };
            toSet.tag = e.target.value;
            dispatch({
              type: "FIELD",
              injectable,
              depth: depth.split("."),
              fieldName: "typeProps",
              fieldValue: { ...toSet },
            });
          }}
        />
      )}

      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}

      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
    </div>
  );
};
