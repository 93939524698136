import { Grid } from '@material-ui/core'
import { dateFormat } from 'app/appSettings'
import CustomDatePicker from 'app/views/common-components/CustomDatePicker'
import { useField, useFormikContext } from 'formik'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'

import { useSelector } from 'react-redux'
import {
  endEditingField,
  startEditingField,
  unlockFieldWithoutChanges
} from '../grpcMultiuserEdit'
import { MUEditedByLabel } from './MUEditedByLabel'
import { MUPreviouslySavedValuesPanel } from './MUPreviouslySavedValuesPanel'

/**
 * Multiuser variant of Material UI DatePicker. Supports the same props as regular DatePicker with additional multiuser functionalities.
 * @category Multiuser
 * @component
 * @extends FormDatePicker
 * @returns {JSX.Element}
 * @property {object} muBag Object containing multiuser data needef for making the queries.
 * @property {object} muBag.token Current multiuser session token.
 * @property {object} muBag.formId Id of a realm the component is used in.
 * @property {string} id Id of a field. Used for Formik and multiuser functionalities.
 * @property {boolean} [displayFieldHistoryIcon] If true, above the field the icon will be renderd which opens up a multiuser field history panel.
 * @property {boolean} [useMultiuser] If true, multiuser functionalities will be active in the component.
 * @property {ref} formikRef Ref to the Formik the DatePicker is inside of.
 * @property {function} [onChange] Callback that will be fired on DatePicker onChange.
 * @property {function} [handleOpen] Callback that will be fired when the DatePicker dialog is opened
 * @property {function} [handleClose] Callback that will be fired when the DatePicker dialog is closed
 */
const MUDatePicker = ({
  id,
  formikRef,
  useMultiuser,
  displayFieldHistoryIcon,
  onChange,
  muBag,
  handleOpen,
  handleClose,
  ...props
}) => {
  const [editedValue, setEditedValue] = useState(null)
  const { values, setFieldTouched, setFieldValue } = useFormikContext()
  const user = useSelector(state => state.user)
  const [field, meta] = useField(id)
  const { muInfo, muUsers = {} } = values
  const muState = muInfo[id]
  let userColor, userName, isEdited
  if (muState) {
    const { user, locked } = muState
    if (locked) {
      isEdited = true
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }
  let marginBottom = 0
  const style = { ...props.style }
  if (style && style.marginBottom) {
    marginBottom = style.marginBottom || style.margin || 0
    if (isEdited) {
      delete style.marginBottom
    }
  }
  const error = props.error || meta.error
  const isError = error && meta.touched

  // these useEffects are created to handle milestone update start and end date fields to work synchronously.
  // but they can be used for any other field that needs to trigger some action on open/close of the datepicker dialog
  const openRef = useRef()
  useEffect(() => {
    if (openRef.current && handleOpen) {
      handleOpen()
    }
  }, [openRef.current, meta.touched])
  useEffect(() => {
    if (openRef.current === false && handleClose) {
      handleClose()
    }
  }, [openRef.current])

  const component = (
    <Grid container direction='column'>
      <CustomDatePicker
        {...field}
        {...props}
        disabled={
          props.disabled ||
          Boolean(muState && muState.locked && muState.user !== user.userId)
        }
        error={isError && !props.disabled}
        helperText={isError && !props.disabled ? error : props.helperText}
        color={userColor}
        handleOpen={e => {
          openRef.current = true
          setEditedValue(moment.utc(field.value).format(dateFormat))
          startEditingField({
            ...muBag,
            fieldId: id
          })
        }}
        onChange={e => {
          let parsedValue = e?.format(dateFormat)

          if(!parsedValue){
            parsedValue = null
          }
          if (onChange) {
            onChange(parsedValue)
            if (props.shouldUnlockFieldWithoutChangesAfterOnChangeEvent) return
          } else {
            setFieldValue(id, parsedValue)
          }
          if (useMultiuser) {
            endEditingField({
              ...muBag,
              fieldId: id,
              fieldValue: parsedValue,
              lockId: values.muInfo.lockId
            })
          }
        }}
        handleClose={date => {
          openRef.current = false
          if (!meta.touched) {
            setFieldTouched(id)
          }
          if (useMultiuser) {
            const passedDate = moment.utc(date).format(dateFormat)
            if (
              !date ||
              passedDate === editedValue ||
              props.shouldUnlockFieldWithoutChangesAfterOnChangeEvent
            ) {
              unlockFieldWithoutChanges({
                ...muBag,
                fieldId: id,
                lockId: values.muInfo.lockId
              })
            } else {
              //Handle if user opened dialog, changed date and clicked cancel
              setTimeout(() => {
                const currentValue = formikRef.current.values[id]
                if (
                  !currentValue ||
                  passedDate !== moment.utc(currentValue).format(dateFormat)
                ) {
                  unlockFieldWithoutChanges({
                    ...muBag,
                    fieldId: id,
                    lockId: values.muInfo.lockId
                  })
                }
              }, 500)
            }
          }
        }}
      />
      <div style={{ marginBottom: marginBottom }}>
        <MUEditedByLabel color={userColor} userName={userName} />
      </div>
    </Grid>
  )

  if (displayFieldHistoryIcon) {
    return (
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        style={style}
      >
        <Grid item xs>
          {component}
        </Grid>
        <Grid item>
          <MUPreviouslySavedValuesPanel
            disabled={props.disabled}
            muBag={muBag}
            fieldId={id}
            type='datePicker'
            useMultiuser={useMultiuser}
          />
        </Grid>
      </Grid>
    )
  } else {
    return component
  }
}

export default MUDatePicker
