import { t } from '@lingui/macro'
import { Text, View } from '@react-pdf/renderer'
import { STF_LP_RECORD_TYPE } from 'app/services/sfAuth/sfData/sfOpportunity'
import { pdfDefaultFontSize } from 'app/views/forms/common/Common'
import {
  defaultFormPdfFontFamily,
  formPDFStyles,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import FormPdfFillArea from 'app/views/forms/pdf-components/FormPdfFillArea'
import { myI18n } from 'translation/I18nConnectedProvider'

const styles = {
  objectives: {
    marginTop: 8,
    marginBottom: 8
  },
  objective: {
    flexDirection: 'row',
    marginBottom: 4
  },
  objectiveText: {
    fontSize: pdfDefaultFontSize,
    fontFamily: defaultFormPdfFontFamily,
    marginLeft: 8
  },
  title: {
    fontSize: pdfDefaultFontSize,
    fontFamily: defaultFormPdfFontFamily,
    fontWeight: 'bold',
    marginBottom: 4
  },
  description: {
    fontSize: pdfDefaultFontSize - 2,
    fontFamily: defaultFormPdfFontFamily,
    fontStyle: 'italic',
    marginTop: 4,
    marginBottom: 4
  },
  descriptionWrapper: {
    flexDirection: 'row'
  },
  required: {
    fontSize: pdfDefaultFontSize - 2,
    fontFamily: defaultFormPdfFontFamily,
    fontStyle: 'italic',
    marginLeft: 4,
    marginTop: 4
  },
  fillArea: {
    marginBottom: 8
  }
}

export const FormObjectivesFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  connectedObject,
  altLabelPlacement,
  ...props
}) => {
  const { options = [] } = typeProps
  const needsHelpText = typeProps?.needsHelpText?.[langVersion]
  const outcomesHelpText = typeProps?.outcomesHelpText?.[langVersion]
  const fundType = connectedObject
    ? connectedObject.RecordType.Id
    : STF_LP_RECORD_TYPE

  if (langVersion === 'iu-cans') {
    styles.description.fontFamily = inuktitutFormPdfFontFamily
    styles.objectiveText.fontFamily = inuktitutFormPdfFontFamily
    styles.title.fontFamily = inuktitutFormPdfFontFamily
    styles.required.fontFamily = inuktitutFormPdfFontFamily
  }

  return (
    <View>
      <View style={styles.objectives}>
        {options
          .filter(option => option?.recordTypes?.includes(fundType))
          .map((item, index) => (
            <View style={styles.objective} key={index}>
              <View style={formPDFStyles.checkbox} />
              <Text style={styles.objectiveText}>
                {item?.labels?.[langVersion] ? item.labels[langVersion] : myI18n._(item.value)}
              </Text>
            </View>
          ))}
      </View>
      <Text style={styles.title}>{myI18n._(t`Needs`)}</Text>
      <View>
        <Text style={styles.description}>
          {needsHelpText}
          {typeProps?.needsRequired && ` (${myI18n._(t`Required`)})`}
        </Text>
      </View>
      <View style={styles.fillArea}>
        <FormPdfFillArea lines={6} />
      </View>

      <Text style={styles.title}>{myI18n._(t`Outcomes`)}</Text>
      <View>
        <Text style={styles.description}>
          {outcomesHelpText}{' '}
          {typeProps?.outcomesRequired && ` (${myI18n._(t`Required`)})`}
        </Text>
      </View>
      <View style={styles.fillArea}>
        <FormPdfFillArea lines={6} />
      </View>
    </View>
  )
}
