import { Trans, t } from '@lingui/macro'
import { Grid, Icon, IconButton, Link, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { getDocumentsByEntities } from 'app/services/sfAuth/sfData/sfFiles'
import {
  getApplicationConfigurations,
  getFormPages
} from 'app/services/sfAuth/sfData/sfForms'
import {
  deletePrequalification,
  getPrequalifications
} from 'app/services/sfAuth/sfData/sfPrequalification'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import ReactToPrint from 'react-to-print'
import { myI18n } from 'translation/I18nConnectedProvider'
import { isDateBeforeNow, muiDateCompare } from 'utils'
import Loading from '../../../../egret/components/EgretLoadable/Loading'
import {
  dateFormat,
  datetimeFormat,
  defaultDocTitle
} from '../../../appSettings'
import {
  CBTI_RECORD_TYPE,
  GREEN_KICKSTARTER_RECORD_TYPE,
  deleteOpportunity,
  getOpportunities,
  getOpportunityToStepper,
  oppToStepper,
  opportunitiesStagesInOrder
} from '../../../services/sfAuth/sfData/sfOpportunity'
import Form from '../../forms/Form'
import { checkFormValidity } from '../../forms/FormHelpersValidation'
import { constructFormAddressString } from '../../forms/common/Common'
import { OpportunityView } from '../../opportunity/OpportunityView'
import { HEADER } from '../../opportunity/opportunityViewComponents'
import { muiTextLabels } from '../../utilities/muiDataTablesTranslations'
import { granteeReportConfiguration } from '../grantee-reports/GranteeReport'
import { ApplicationActions } from './ApplicationActions'
import { ConfigurableEligibilityDialog } from './ConfigurableEligibilityDialog'
import { OrganisationToPrint } from './old-application/PrintView'

export const printStyles = (theme) => {
  return {
    root: {
      margin: theme.spacing(2),
      padding: theme.spacing(2)
      // width: 800
    },
    CellCentered: {
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center'
    },
    button: {
      margin: theme.spacing(1)
    },
    gridObjectives: {
      flexGrow: 1
    },
    dataList: {
      padding: 15,
      marginBottom: 15,
      marginLeft: 20,
      marginRight: 20,
      flexGrow: [[1], '!important']
    },
    dataContainer: {
      padding: 15,
      width: '100%'
    },
    paperContainerStretch: {
      width: '100%',
      padding: 15
    },
    gridContainerStretch: {
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 15
    },
    gridContainerStretchNoBreak: {
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 15
    },
    contactGridItem: {
      minHeight: [['90px'], '!important']
    },
    '@media print': {
      fill: {
        width: [['100%'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191'
      },
      titleCard: {
        padding: '5px',
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        display: [['inline-block'], '!important'],
        justifyContent: [['center'], '!important'],
        marginLeft: [[25], '!important'],
        marginRight: [[15], '!important'],
        height: [['auto'], '!important'],
        width: [['750px'], '!important']
      },
      titleCardStretch: {
        padding: '5px',
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        display: [['inline-block'], '!important'],
        justifyContent: [['center'], '!important'],
        // marginLeft: [[25], '!important'],
        // marginRight: [[15], '!important'],
        height: [['auto'], '!important'],
        width: [['100%'], '!important']
      },
      titleGrid: {
        margin: [[0], '!important'],
        height: [['auto'], '!important'],
        width: [['750px'], '!important']
        // alignItems: [['center'], '!important'],
        // justify: [['flex-start'], '!important'],
        // flexDirection: [['row'], '!important'],
        // display: [['flex'], '!important']
      },
      nameGrid: {
        width: [['300px'], '!important']
      },
      gridObjectives: {
        paddingLeft: 20,
        paddingRight: 20,
        flexGrow: 1
      },
      programManagerCard: {
        padding: [[10], '!important'],
        // marginBottom: [[40], '!important'],
        // paddinTop: [[20], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        display: [['inline-block'], '!important'],
        height: [['150px'], '!important'],
        width: [['300px'], '!important']
      },
      imageLogo: {
        display: [['inline'], '!important']
      },
      gridContainer: {
        display: [['flex'], '!important'],
        width: [['100%'], '!important'],
        justifyContent: [['space-evenly'], '!important'],
        alignItems: [['center'], '!important']
      },
      gridContainerStretch: {
        paddingLeft: 0,
        paddingRight: 0,
        display: [['flex'], '!important'],
        width: [['100%'], '!important'],
        marginBottom: 15,
        justifyContent: [['center'], '!important']
      },
      gridContainerStretchNoBreak: {
        paddingLeft: 0,
        paddingRight: 0,
        display: [['flex'], '!important'],
        width: [['100%'], '!important'],
        marginBottom: 15,
        justifyContent: [['center'], '!important'],
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important']
      },
      gridChild: {
        display: [['block'], '!important'],
        alignSelf: [['center'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto'
      },
      otherGrantsGrid: {
        borderRadius: 3,
        margin: 0,
        padding: 0,
        display: [['block'], '!important'],
        alignSelf: [['center'], '!important'],
        border: '2px solid',
        borderColor: '#919191',
        height: [['300px'], '!imortant'],
        width: [['368px'], '!important']
      },
      contactGridItem: {
        minHeight: [['95px'], '!important'],
        // width: [['350px'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191'
      },
      gridChildHalfWidth: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        // flexGrow: 1,
        // width: '100%'
        width: [['575px'], '!important']
      },
      gridChildTable1: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['600px'], '!important']
      },
      gridChildTable2: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['150px'], '!important']
      },
      gridChildTable3: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['350px'], '!important']
      },
      gridChildTable4: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['950px'], '!important']
      },
      gridChildTable5: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        minHeight: [['65px'], '!important'],
        width: [['180px'], '!important']
      },
      paperContainer: {
        borderRadius: 3,
        paddingLeft: 15,
        paddingRight: 15,
        border: '2px solid',
        borderColor: '#919191'
      },
      description: {
        padding: 15,
        margin: 0,
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        width: [['1150px'], '!important']
      },
      paperContainerStretch: {
        padding: 15,
        margin: 0,
        // margin: 0,
        // marginLeft: 20,
        // marginRight: 20,
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        width: [['1150px'], '!important']
      },
      dataList: {
        // padding: 15,
        // marginLeft: 20,
        // marginRight: 20,
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important'],
        // flexGrow: 1,
        width: [['760px'], '!important']
      },
      dataContainer: {
        padding: 15,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
        borderRadius: 3,
        flexGrow: 1,
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important'],
        border: [['2px solid'], '!important'],
        borderColor: [['#919191'], '!important']
        // width: [['1150px'], '!important']
      },
      mainTitle: {
        fontSize: [[24], '!important'],
        marginLeft: [[15], '!important'],
        padding: [[10], '!important']
      },
      subtitle: {
        fontSize: 12,
        color: '#919191'
      },
      hideInPrint: {
        display: [['none'], '!important'],
        visibility: [['hidden', '!important']]
      }
    }
  }
}

const useStyles = makeStyles((theme) => printStyles(theme))

export const OldFundsPrintViewDialog = ({
  opportunityToPrint,
  handleClose
}) => {
  const printRef = useRef()
  const classes = useStyles()
  const config = granteeReportConfiguration({
    classes,
    applicationList: true
  })
  config.fields.splice(
    5,
    0,
    ...OrganisationToPrint({
      classes
    }),
    {
      type: HEADER,
      style: { fontSize: 24 },
      text: (item) => (
        <span className={classes.mainTitle}>
          <Trans>Application Details</Trans>
        </span>
      )
    }
  )
  return (
    <Dialog open maxWidth='md' fullWidth scroll='paper'>
      <DialogTitle>
        <Grid container direction='row' justify='space-between'>
          <ReactToPrint
            onAfterPrint={() => (document.title = defaultDocTitle)}
            onBeforePrint={() =>
              (document.title = opportunityToPrint.info.projectName)}
            trigger={() => (
              <Tooltip title={<Trans>Print</Trans>}>
                <span>
                  <IconButton
                    disabled={!opportunityToPrint.readyToPrint}
                    aria-label={<Trans>Print</Trans>}
                    className=''
                  >
                    <Icon>print</Icon>
                  </IconButton>
                </span>
              </Tooltip>
            )}
            content={() => printRef.current}
          />
          <IconButton onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {!opportunityToPrint.readyToPrint && (
          <div style={{ height: 500 }}>
            <Loading />
          </div>
        )}
        {opportunityToPrint.readyToPrint && (
          <div ref={printRef}>
            <OpportunityView
              opportunity={opportunityToPrint}
              committeeType='External Review Accept'
              configuration={config}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export const shouldShowReminderBanner = (data, recordType, cutoffDate) => {
  return (
    data
      .filter((item) => !item.isPrequalificaiton)
      .some((item) => {
        return (
          (item.stageName === 'In Progress' ||
            item.stageName === 'More info required') &&
          item.recordType === recordType
        )
      }) &&
    momentTZ
      .tz(moment.utc(), momentTZ.tz.guess())
      .isBefore(momentTZ.tz(cutoffDate, 'Pacific/Pitcairn'))
  )
}

export const ReminderBanner = ({ title, rows }) => {
  return (
    <Alert severity='warning' style={{ marginTop: 10 }}>
      <AlertTitle>
        <Trans id={title} />
      </AlertTitle>
      {rows.map((item) => {
        return (
          <div style={item.style}>
            <Trans id={item.id} />
          </div>
        )
      })}
    </Alert>
  )
}

const ApplicationsList = () => {
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState(null)
  const [applicationToPrint, setApplicationToPrint] = React.useState(null)
  const [eligibilityOpen, setEligibilityOpen] = React.useState(false)
  const [appToDelete, setAppToDelete] = React.useState(null)
  const [appIdsToDelete, setAppIdsToDelete] = React.useState([])
  const [opportunitiesWithContract, setOpportunitiesWithContract] = useState(
    []
  )
  const [forms, setForms] = React.useState([])
  const [isOrganizationValid, setIsOrganizationValid] = React.useState(true)
  const [formsConfig, setFormsConfig] = React.useState(null)
  const [nearEndFunds, setNearEndFunds] = React.useState([])
  const configurations = useSelector((state) => state.configuration)
  const language = useSelector((state) => state?.user?.language || 'en')
  const fundingStreams = useSelector((state) => state.fundingStreams.streams)
  const organization = useSelector((state) => state.organization)
  const user = useSelector((state) => state.user)
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const location = useLocation()
  const pathname = location?.pathname

  let userRole = 'No role'
  if (user.organizationMember) {
    userRole = user.organizationMember.TeamMemberRole
  }
  const rolePermissions = {
    manager: userRole === 'Manager',
    contributor: userRole === 'Contributor',
    partner: userRole === 'Operator',
    associate: userRole === 'Associate'
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [organization])

  const fetchData = () => {
    return Promise.all([
      organization.id
        ? getOpportunities(
          {
            permissions: {
              TEAM_MEMBERS: true
            },
            searchParams: {
              AccountId: organization.id
            }
          },
          true
        )
        : Promise.resolve([]),
      getFormPages(),
      getApplicationConfigurations(),
      getPrequalifications({
        searchParams: {
          Organization_Legal_Name__c: organization.id
        }
      })
    ])
      .then(([list, forms, appConfigurations, prequalifications]) => {
        console.log('get applications', list, prequalifications)
        console.log('got form templates', forms)

        const formsConfig = {}
        const formsMap = {}

        const upcomingFunds = {}
        appConfigurations.forEach((config) => {
          const { reminderTime, recordType, endTime } = config
          if (
            reminderTime &&
            isDateBeforeNow(reminderTime) &&
            !upcomingFunds[recordType]
          ) {
            const formattedEndTime = endTime
              ? moment.utc(endTime).format(datetimeFormat)
              : null

            upcomingFunds[recordType] = {
              fundName: null,
              endTime: formattedEndTime,
              opportunities: []
            }
          }
        })

        let organizationForm
        forms.forEach((form) => {
          const objects = form.config.objectsConnected || []
          formsConfig[form.id] = [...objects]
          formsMap[form.id] = form
          if (form.id === configurations.FORM_ORGANIZATION_DETAILS) {
            organizationForm = form
          }
        })
        setFormsConfig(formsConfig)
        setForms(forms)

        list = list
          .filter(
            (opp) =>
              opp.UserRecordAccess.HasReadAccess &&
              // Filter out Development Project opportunities - thosee are internal CHTC projects about organization and we don't want to show user these
              opp.RecordTypeId !== '012Am0000004LchIAE'
          )
          .map((item) => {
            const stepperItem = oppToStepper(item)
            const fundId = stepperItem.info.recordTypeId
            const fundName = stepperItem.info.fundTypeName
            const oppName = stepperItem.info.name
            const stageName = stepperItem.info.stageName

            if (
              upcomingFunds.hasOwnProperty(fundId) &&
              stageName === 'In Progress'
            ) {
              upcomingFunds[fundId].fundName = fundName
              upcomingFunds[fundId].opportunities.push(oppName)
            }
            return stepperItem
          })
        setNearEndFunds(Object.entries(upcomingFunds))

        return getDocumentsByEntities(list.map((opp) => opp.info.id)).then(
          (files) => {
            const applicationsWithContract = []
            files.forEach((file) => {
              if (
                !applicationsWithContract.includes(file.LinkedEntityId) &&
                file.ContentVersion.TagCsv === 'unsigned_contract'
              ) {
                applicationsWithContract.push(file.LinkedEntityId)
              }
            })
            setOpportunitiesWithContract(applicationsWithContract)
            const dataToSet = [
              ...prequalifications.map((item, index) => {
                const fundingStream =
                  fundingStreams.find((fs) => fs.id === item.fundingStream) ||
                  undefined

                return {
                  name: item.name,
                  stageName: item.stage,
                  createdDate: item.createdDate,
                  fundingStream: fundingStream?.id,
                  fund: getLabelFromTranslationData({
                    langVersion: language,
                    data: fundingStream?.fullName
                  }),
                  pm: {
                    isPm: Boolean(item.assignedProgramManager),
                    assignedManager: item.assignedProgramManager,
                    pmName: item.assignedProgramManagerDetails.name,
                    pmMail: item.assignedProgramManagerDetails.mail,
                    pmPhone: item.assignedProgramManagerDetails.phone
                  },
                  actions: {
                    editAccess: true,
                    dataIndex: index,
                    fundingStream: fundingStream?.id,
                    fundingStreamApplicationForm: fundingStream?.form,
                    id: item.id,
                    stageName: item.stage,
                    isPrequalification: true,
                    pm: {
                      isPm: Boolean(item.assignedProgramManager),
                      assignedManager: item.assignedProgramManager,
                      pmName: item.assignedProgramManagerDetails.name,
                      pmMail: item.assignedProgramManagerDetails.mail,
                      pmPhone: item.assignedProgramManagerDetails.phone
                    }
                  },
                  lastModifiedDate: item.lastModifiedDate,
                  id: item.id,
                  isPrequalification: true
                  // block print for new forms
                }
              }),
              ...list.map((item, index) => {
                const mail = item.info.pmContact?.split(';')[0]

                const fundingStream =
                  fundingStreams.find(
                    (fs) => fs.id === item.info.fundingStream
                  ) || undefined
                const formId = fundingStream?.form
                const printForm = fundingStream?.printForm
                const formConfig = formId ? formsConfig[formId] : null
                const recordType = item.info.fundTypeName

                return {
                  name: item.info.name,
                  recordType,
                  fundingStream: fundingStream?.id,
                  fund:
                    fundingStream &&
                    getLabelFromTranslationData({
                      langVersion: language,
                      data: fundingStream?.fullName
                    }),
                  stageName: item.info.stageName,
                  createdDate: item.info.createdDate,
                  pm: {
                    isPm: Boolean(item.info.pmName && mail),
                    assignedManager: item.info.assignedManager,
                    pmName: item.info.pmName,
                    pmMail: mail,
                    pmPhone: item.info.pmContact?.split(';')[1]
                  },
                  actions: {
                    formId,
                    appToPrint: item,
                    fundingStream: fundingStream?.id,
                    fundingStreamApplicationForm: fundingStream?.form,
                    hasReadAccess: item.info.hasReadAccess,
                    editAccess: item.info.hasEditAccess,
                    printForm,
                    hasContract: opportunitiesWithContract.includes(
                      item.info.id
                    ),
                    formConfig,
                    wasHardCoded: item.info.hardCoded,
                    oldFormVersion: item.info.oldFormVersion,
                    grantForm: item.info.grantForm,
                    dataIndex: index,
                    recordType,
                    id: item.info.id,
                    pm: {
                      isPm: Boolean(item.info.pmName && mail),
                      assignedManager: item.info.assignedManager,
                      pmName: item.info.pmName,
                      pmMail: mail,
                      pmPhone: item.info.pmContact?.split(';')[1]
                    },
                    isPrequalification: false,
                    stageName: item.info.stageName
                  },
                  lastModifiedDate:
                    item.info.lastModifiedByUserDate ||
                    item.info.lastModifiedDate,
                  id: item.info.id
                  // block print for new forms
                }
              })
            ]

            if (organizationForm && organization.id) {
              return checkFormValidity({
                formId: organizationForm.id,
                pathname,
                id: constructFormAddressString({
                  ids: {
                    Account: organization.id,
                    User: user.userId
                  },
                  objectsConnected: organizationForm.config.objectsConnected
                })
              }).then((isValid) => {
                setIsOrganizationValid(isValid)
                setData(dataToSet)
                setLoading(false)
                enqueueSnackbar(<Trans>Loaded</Trans>, {
                  variant: 'info'
                })
              })
            } else {
              setData(dataToSet)
              setLoading(false)
              enqueueSnackbar(<Trans>Loaded</Trans>, {
                variant: 'info'
              })
            }
          }
        )
      })
      .catch((reject) => console.error(reject))
  }

  const handleClose = () => {
    setAppToDelete(null)
    setEligibilityOpen(false)
  }

  const onRemoveItem = (id, isPrequalification) => {
    const deletingSnackbarKey = enqueueSnackbar(<Trans>Deleting</Trans>, {
      variant: 'info'
    })
    setAppIdsToDelete((prev) => [...prev, id])
    setAppToDelete(null)

    const deleteFunction = isPrequalification
      ? deletePrequalification
      : deleteOpportunity

    deleteFunction(id)
      .then((result) => {
        if (!result.success) {
          enqueueSnackbar(<Trans>Error while Deleting</Trans>, {
            variant: 'error'
          })
        } else {
          closeSnackbar(deletingSnackbarKey)
          setData((prevData) => prevData.filter((item) => item.id !== id))
          enqueueSnackbar(<Trans>Deleted</Trans>, {
            variant: 'success'
          })
        }
      })
      .catch((error) => {
        console.error('Error while deleting:', error)
        enqueueSnackbar(<Trans>Error while Deleting</Trans>, {
          variant: 'error'
        })
      })
      .then(() => {
        setAppIdsToDelete((prev) => prev.filter((appId) => appId !== id))
      })
  }

  if (loading) {
    return <Loading />
  }

  const config = granteeReportConfiguration({
    classes,
    applicationList: true
  })
  config.fields.splice(
    5,
    0,
    ...OrganisationToPrint({
      classes
    }),
    {
      type: HEADER,
      style: { fontSize: 24 },
      text: (item) => (
        <span className={classes.mainTitle}>
          <Trans>Application Details</Trans>
        </span>
      )
    }
  )

  const insufficientRole = Boolean(
    !rolePermissions.manager // && !rolePermissions.contributor
  )
  const invalidAccountInfo = Boolean(!isOrganizationValid && organization.id)
  const displayCBTIBanner = shouldShowReminderBanner(
    data,
    CBTI_RECORD_TYPE,
    '2023-01-31 11:59 PM'
  )
  const displayGKFBanner = shouldShowReminderBanner(
    data,
    GREEN_KICKSTARTER_RECORD_TYPE,
    '2023-06-16 11:59 PM'
  )

  const activePrequalificationFundingStreams = Object.fromEntries(
    data
      .filter((item) => item.isPrequalification)
      .map((item) => [
        item.fundingStream,
        { stage: item.stageName, id: item.id }
      ])
  )

  return (
    <>
      {applicationToPrint &&
        (applicationToPrint.formPrintId
          ? (
            <Form
              inDialog={Boolean(
                applicationToPrint && applicationToPrint.formPrintId
              )}
              onDialogClose={(e) => {
                setApplicationToPrint(null)
              }}
              forceDisabled
              disableTitle
              formId={applicationToPrint.formPrintId}
              defaultFormType='pdf'
              fetchString={constructFormAddressString({
                ids: {
                  User: user.userId,
                  Account: organization.id,
                  Opportunity: applicationToPrint.opportunityId,
                  Funding_Stream__c: applicationToPrint.fundingStream
                },
                objectsConnected: formsConfig[applicationToPrint.formPrintId]
              })}
            />
            )
          : (
            <OldFundsPrintViewDialog
              opportunityToPrint={applicationToPrint}
              handleClose={(e) => {
                setApplicationToPrint(null)
              }}
            />
            ))}
      <Paper className={classes.root}>
        <div>
          <div style={{ paddingLeft: 15, paddingTop: 20, paddingRight: 15 }}>
            <Button
              variant='contained'
              color='primary'
              type='button'
              disabled={
                !organization.id || insufficientRole || invalidAccountInfo
              }
              onClick={() => setEligibilityOpen(true)}
            >
              <Icon color='inherit'>add</Icon>
              <span data-tut='reactour__applicationList__new'>
                <Trans>New Application</Trans>
              </span>
            </Button>
            {insufficientRole && organization.id && (
              <Alert severity='warning' style={{ marginTop: 10 }}>
                <AlertTitle>
                  <Trans>
                    You are not authorized to create new applications for this
                    organization. Please contact your account manager.
                  </Trans>
                </AlertTitle>
                <Trans>You can manage your organizations</Trans>{' '}
                <Link href='/grants/Organizations'>
                  <Trans>here</Trans>
                </Link>
              </Alert>
            )}
            {invalidAccountInfo && !insufficientRole && (
              <Alert severity='warning' style={{ marginTop: 10 }}>
                <Trans>
                  You cannot create new application until you fill out all field
                  marked as required in the{' '}
                  <Link href='/grants/organization-details'>
                    <Trans>Organisation Details</Trans>
                  </Link>
                </Trans>
              </Alert>
            )}

            {!organization.id && (
              <Alert severity='warning' style={{ marginTop: 10 }}>
                <Trans>
                  Before you will be able to create a new application you have
                  to associate an organisation with your account
                </Trans>{' '}
                <Link href='/grants/Organizations'>
                  <Trans>You can connect to an organization here</Trans>
                </Link>
              </Alert>
            )}

            {!insufficientRole && displayCBTIBanner && (
              <ReminderBanner
                title={t`APPLICATIONS_LIST_CBTI_BANNER_HEADER`}
                rows={[
                  { id: t`APPLICATIONS_LIST_CBTI_BANNER_LINE1` },
                  {
                    id: t`APPLICATIONS_LIST_CBTI_BANNER_LINE2`,
                    style: { marginTop: 5, fontWeight: 'bold' }
                  }
                ]}
              />
            )}
            {!insufficientRole && displayGKFBanner && (
              <ReminderBanner
                title={t`APPLICATIONS_LIST_GKF_BANNER_HEADER`}
                rows={[{ id: t`APPLICATIONS_LIST_GKF_BANNER_LINE1` }]}
              />
            )}

            {!insufficientRole && nearEndFunds.length !== 0 && (
              <>
                {nearEndFunds.map(
                  ([key, obj]) =>
                    obj.opportunities.length > 0 && (
                      <Alert
                        severity='warning'
                        key={key}
                        style={{ marginTop: 10 }}
                      >
                        <AlertTitle>
                          <Trans>
                            APPLICATIONS_LIST_FUNDING_NEAR_END_ALERT{' '}
                            {obj.fundName}
                          </Trans>
                          {'. '}
                          {obj.endTime && (
                            <Trans>
                              APPLICATIONS_LIST_FUNDING_NEAR_END_ALERT_END_TIME{' '}
                              {moment.utc(obj.endTime).format(dateFormat)}
                            </Trans>
                          )}
                          :
                        </AlertTitle>
                        <div style={{ marginTop: 5 }}>
                          {obj.opportunities.map((opp, index) => (
                            <div key={index}>
                              <Trans>{opp}</Trans>
                            </div>
                          ))}
                        </div>
                      </Alert>
                    )
                )}
              </>
            )}
          </div>

          <div className='w-100 overflow-auto' style={{ padding: 20 }}>
            <MUIDataTable
              title={
                <Typography variant='h5'>
                  <b>
                    <Trans>Applications list</Trans>
                  </b>
                </Typography>
              }
              data={data}
              options={{
                textLabels: muiTextLabels(myI18n),
                filter: true,
                filterType: 'checkbox',
                selectableRows: 'none',
                print: false,
                download: false,
                viewColumns: false,
                setRowProps: (row, dataIndex, rowIndex) => {
                  return {
                    style: row[7] && { backgroundColor: '#F2F2F2' }
                  }
                },
                sortOrder: {
                  direction: 'desc',
                  name: 'lastModifiedDate'
                }
              }}
              columns={[
                {
                  name: 'name',
                  label: myI18n._(t`Name`),
                  options: {
                    filter: false,
                    customHeadLabelRender: (props) => {
                      return (
                        <div style={{ marginLeft: 20 }}>
                          <Trans>Name</Trans>
                        </div>
                      )
                    },
                    customBodyRender: (value, { rowData }) => {
                      const isPrequalificaiton = rowData[7]
                      return (
                        <>
                          {isPrequalificaiton && (
                            <span
                              style={{
                                position: 'relative',
                                left: 8,
                                top: -11,
                                paddingRight: 8,
                                paddingLeft: 8,
                                paddingTop: 2,
                                paddingBottom: 2,
                                borderColor: '#6F6F6F',
                                color: '#6F6F6F',
                                border: '1px solid'
                              }}
                            >
                              <Trans>PREQUALIFICATION</Trans>
                            </span>
                          )}
                          <div style={{ marginLeft: 24 }}>{value}</div>
                        </>
                      )
                    }
                  }
                },
                {
                  name: 'fund',
                  label: myI18n._(t`Fund`),
                  options: {
                    filter: true,
                    customHeadLabelRender: (props) => {
                      return <Trans>Fund</Trans>
                    }
                  }
                },
                {
                  name: 'lastModifiedDate',
                  sortCompare: muiDateCompare('lastModifiedDate'),
                  label: myI18n._(t`Modification`),
                  options: {
                    filter: false,
                    customHeadLabelRender: (props) => {
                      return (
                        <div style={{ textAlign: 'center' }}>
                          <Trans>Modification</Trans>
                        </div>
                      )
                    },
                    customBodyRender: (value) => {
                      return <div>{moment.utc(value).format(dateFormat)}</div>
                    }
                  }
                },
                {
                  name: 'stageName',
                  label: myI18n._(t`Stage`),
                  options: {
                    filter: true,
                    sortCompare:
                      (order) =>
                        ({ data: stage1 }, { data: stage2 }) => {
                          const ret =
                          opportunitiesStagesInOrder.indexOf(stage1) -
                          opportunitiesStagesInOrder.indexOf(stage2)
                          return order === 'asc' ? ret : ret * -1
                        },
                    customHeadLabelRender: (props) => {
                      return <Trans>Stage</Trans>
                    },
                    customBodyRender: (value) => myI18n._(value)
                  }
                },
                {
                  name: 'createdDate',
                  sortCompare: muiDateCompare('createdDate'),
                  label: myI18n._(t`Created date`),
                  options: {
                    filter: false,
                    customHeadLabelRender: (props) => {
                      return <Trans>Created date</Trans>
                    },
                    customBodyRender: (value) => {
                      return <div>{moment.utc(value).format(dateFormat)}</div>
                    }
                  }
                },
                {
                  name: 'pm',
                  label: myI18n._(t`Program Manager`),
                  options: {
                    filter: false,
                    sort: false,
                    customHeadLabelRender: (props) => {
                      return <Trans>Program Manager</Trans>
                    },
                    customBodyRender: (value) => {
                      if (!value) {
                        return <div />
                      }
                      return (
                        <div>
                          <div>{value.pmName}</div>
                          <div>{value.pmMail}</div>
                          <div>{value.pmPhone}</div>
                        </div>
                      )
                    }
                  }
                },
                {
                  name: 'actions',
                  label: myI18n._(t`Actions`),
                  options: {
                    sort: false,
                    filter: false,
                    customHeadLabelRender: (props) => {
                      return (
                        <div style={{ textAlign: 'center', width: '100%' }}>
                          <Trans>Actions</Trans>
                        </div>
                      )
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <ApplicationActions
                          {...value}
                          handleShowPrint={({
                            useOldForm,
                            id,
                            fundingStream,
                            appToPrint,
                            printForm
                          }) => {
                            if (useOldForm) {
                              setApplicationToPrint(appToPrint)
                              getOpportunityToStepper(id).then((app) =>
                                setApplicationToPrint({
                                  ...app,
                                  readyToPrint: true
                                })
                              )
                            } else {
                              setApplicationToPrint({
                                opportunityId: id,
                                formPrintId: printForm,
                                fundingStream
                              })
                            }
                          }}
                          handleDelete={(app) => {
                            setAppToDelete(app)
                          }}
                          rolePermissions={rolePermissions}
                          disableDeleteButton={appIdsToDelete.includes(
                            value.id
                          )}
                        />
                      )
                    }
                  }
                },
                {
                  name: 'isPrequalification',
                  label: myI18n._(t`APPLICATIONS_LIST_APPLICATION_TYPE_LABEL`),
                  options: {
                    sort: false,
                    filter: true,
                    print: false,
                    filterOptions: {
                      names: [
                        myI18n._(t`APPLICATIONS_LIST_OPPORTUNITIES_LABEL`),
                        myI18n._(t`APPLICATIONS_LIST_PREQUALIFICATIONS_LABEL`)
                      ],
                      logic: (isPrequalification, filterVal) => {
                        const opportunitiesLabel = myI18n._(
                          t`APPLICATIONS_LIST_OPPORTUNITIES_LABEL`
                        )
                        const prequalificationsLabel = myI18n._(
                          t`APPLICATIONS_LIST_PREQUALIFICATIONS_LABEL`
                        )

                        if (
                          filterVal.includes(opportunitiesLabel) &&
                          !isPrequalification
                        ) {
                          return false
                        }
                        if (
                          filterVal.includes(prequalificationsLabel) &&
                          isPrequalification
                        ) {
                          return false
                        }
                        return true
                      }
                    },
                    searchable: false,
                    display: 'excluded',
                    customHeadLabelRender: (props) => {
                      return <Trans>Application Type</Trans>
                    }
                  }
                },
                {
                  name: 'id',
                  options: {
                    sort: false,
                    filter: false,
                    print: false,
                    searchable: false,
                    display: 'excluded'
                  }
                }
              ]}
            />
          </div>
        </div>
        <Dialog
          open={Boolean(appToDelete)}
          onClose={handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {'\n' +
              myI18n._(t`Are you sure you want to delete this application?`)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              This process cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='default'>
              {myI18n._(t`Cancel`)}
            </Button>
            <Button
              onClick={() =>
                onRemoveItem(appToDelete.id, appToDelete.isPrequalification)}
              color='default'
            >
              {myI18n._(t`Yes, delete it forever~`)}
            </Button>
          </DialogActions>
        </Dialog>
        <ConfigurableEligibilityDialog
          fetchData={fetchData}
          forms={forms}
          open={eligibilityOpen}
          handleClose={() => setEligibilityOpen(false)}
          activePrequalificationFundingStreams={
            activePrequalificationFundingStreams
          }
        />
      </Paper>
    </>
  )
}

export default ApplicationsList
