import { StyleSheet } from "@react-pdf/renderer";
import { defaultFormPdfFontFamily } from "./FormPdf";

export const pdfTableStyles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    //@ts-ignore
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    borderStyle: "solid",
    minHeight: 16,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  centeredCell: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
  },
  headerTableCell: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 4,
    fontWeight: "bold",
  },
  secondLevelRowCell: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  mainTableCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
    fontWeight: "bold",
    padding: 2,
  },
  helptextCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 8,
    fontStyle: "italic",
    margin: "0 2px",
  },
  elementTitle: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
    fontWeight: "bold",
  },
  title: {
    padding: 10,
    fontSize: 16,
    fontWeight: 400,
  },
});
