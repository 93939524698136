import { Trans } from "@lingui/macro";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FormConnectToObject } from "app/views/forms/common/FormConnectToObject";
import { PdfPropsForm } from "app/views/forms/common/PdfPropsForm";
import {
  validFieldTypesForPicklist,
  validFieldTypesForTextField,
} from "app/views/forms/editor/FormWizard";
import ConfigureMultilanguageTextField from "app/views/internal/ConfigureMultilanguageTextField";
import "ol/ol.css";
import { useDispatch } from "react-redux";
import { AutocompleteWithGeographicalOptions } from "./AutocompleteWithGeographicalOptions";
import { FormCensusDivision } from "./FormCensusDivision";

export function FormEditorCensusDivision({
  editMode,
  typeProps,
  showPdfProps,
  depth,
  disabled,
  injectable,
  injectableId,
  ...props
}) {
  const dispatch = useDispatch();
  const {
    avaliableProvinces = [],
    connectedTo = [],
    predefinedGroups = [],
    isOrganizationVariant, // indicates if a form element is a variant of census division for organizations
  } = typeProps || {};

  if (!editMode) {
    return <FormCensusDivision {...props} typeProps={typeProps} />;
  }

  return (
    <div>
      <Grid
        container
        style={{ marginTop: 10, marginBottom: 10 }}
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(isOrganizationVariant)}
              onChange={(e) => {
                const toSet = { ...typeProps };
                toSet.isOrganizationVariant = e.target.checked;
                dispatch({
                  type: "FIELD",
                  depth: depth.split("."),
                  fieldName: "typeProps",
                  fieldValue: { ...toSet },
                });
              }}
            />
          }
          label={
            <Trans>FORM_EDITOR_CENZUS_DIVISION_IS_ORGANIZATION_VARIANT</Trans>
          }
        />
      </Grid>

      <Grid
        container
        style={{ marginTop: 10, marginBottom: 10 }}
        alignItems="center"
      >
        <Grid item style={{ marginRight: 10 }}>
          <b>
            <Trans>Predifined groups</Trans>
          </b>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={(e) => {
              const toSet = { ...typeProps };
              toSet.predefinedGroups = [...predefinedGroups, {}];
              dispatch({
                type: "FIELD",
                injectable,
                depth: depth.split("."),
                fieldName: "typeProps",
                fieldValue: toSet,
              });
            }}
          >
            <Trans>Add new group</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Button>
        </Grid>
      </Grid>
      {predefinedGroups.map((obj, index) => {
        const { options = [], label } = obj;
        return (
          <Grid container alignItems="center" style={{ marginBottom: 15 }}>
            <Grid item style={{ paddingRight: 10 }}>
              {index + 1}
            </Grid>
            <Grid item xs>
              <Grid container>
                <Grid container style={{ marginBottom: 10 }}>
                  <ConfigureMultilanguageTextField
                    value={label}
                    label={<Trans>Label</Trans>}
                    handleChange={(value) => {
                      const toSet = { ...typeProps };
                      toSet.predefinedGroups[index].label = value;
                      dispatch({
                        type: "FIELD",
                        injectable,
                        depth: depth.split("."),
                        fieldName: "typeProps",
                        fieldValue: toSet,
                      });
                    }}
                    useDebounce
                  />
                </Grid>
                <AutocompleteWithGeographicalOptions
                  value={options}
                  label={<Trans>Geographical scope</Trans>}
                  disabled={disabled}
                  onChange={(e, value) => {
                    const toSet = { ...typeProps };
                    toSet.predefinedGroups[index].options = value;
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: toSet,
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <IconButton
                disabled={disabled}
                onClick={(e) => {
                  const toSet = { ...typeProps };
                  toSet.predefinedGroups.splice(index, 1);
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Grid>
          </Grid>
        );
      })}

      <AutocompleteWithGeographicalOptions
        style={{ marginTop: 10 }}
        label={<Trans>Avaliable Provinces</Trans>}
        onlyProvinces
        value={avaliableProvinces}
        disabled={disabled}
        onChange={(e, value) => {
          const toSet = { ...typeProps };
          toSet.avaliableProvinces = value;
          dispatch({
            type: "FIELD",
            injectable,
            depth: depth.split("."),
            fieldName: "typeProps",
            fieldValue: toSet,
          });
        }}
      />

      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}

      <FormConnectToObject
        depth={depth}
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        disableMultiple
        fieldRender={({ fieldsToConnect, fieldsToConnectMap, index }) => {
          const item = connectedTo[index] || {};
          const {
            cenzusDivision,
            pilotPhaseScopeType,
            regionalScope,
            impactsProvincialNorth,
            pilotPhase,
            cities,
            provinces,
          } = item;

          const stringFields = [...fieldsToConnect].filter((item) =>
            validFieldTypesForTextField.includes(item.type)
          );
          const picklistFields = [...fieldsToConnect].filter((item) =>
            validFieldTypesForPicklist.includes(item.type)
          );

          return (
            <div>
              <Autocomplete
                freeSolo={false}
                value={cenzusDivision ? cenzusDivision.label : null}
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps };
                  toSet.connectedTo[index].cenzusDivision =
                    fieldsToConnectMap[value];
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={stringFields.map((item) => item.label)}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label={<Trans>Cenzus division field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={provinces ? provinces.label : null}
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps };
                  toSet.connectedTo[index].provinces =
                    fieldsToConnectMap[value];
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={picklistFields.map((item) => item.label)}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label={<Trans>Provinces field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={cities?.label || null}
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps };
                  toSet.connectedTo[index].cities = fieldsToConnectMap[value];
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={stringFields.map((item) => item.label)}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label={<Trans>Cities field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={regionalScope ? regionalScope.label : null}
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps };
                  toSet.connectedTo[index].regionalScope =
                    fieldsToConnectMap[value];
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={picklistFields.map((item) => item.label)}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label={<Trans>Geographical scope field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={
                  impactsProvincialNorth ? impactsProvincialNorth.label : null
                }
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps };
                  toSet.connectedTo[index].impactsProvincialNorth =
                    fieldsToConnectMap[value];
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={picklistFields.map((item) => item.label)}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label={<Trans>Impact Provincial North field</Trans>}
                  />
                )}
              />

              {!isOrganizationVariant && (
                <Autocomplete
                  freeSolo={false}
                  value={pilotPhaseScopeType ? pilotPhaseScopeType.label : null}
                  disabled={disabled}
                  onChange={(e, value) => {
                    const toSet = { ...typeProps };
                    toSet.connectedTo[index].pilotPhaseScopeType =
                      fieldsToConnectMap[value];
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: toSet,
                    });
                  }}
                  style={{ marginTop: 15 }}
                  fullWidth
                  options={picklistFields.map((item) => item.label)}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      label={<Trans>Pilot Phase Scope field</Trans>}
                    />
                  )}
                />
              )}

              {!isOrganizationVariant && (
                <Autocomplete
                  freeSolo={false}
                  value={pilotPhase ? pilotPhase.label : null}
                  disabled={disabled}
                  onChange={(e, value) => {
                    const toSet = { ...typeProps };
                    toSet.connectedTo[index].pilotPhase =
                      fieldsToConnectMap[value];
                    dispatch({
                      type: "FIELD",
                      injectable,
                      depth: depth.split("."),
                      fieldName: "typeProps",
                      fieldValue: toSet,
                    });
                  }}
                  style={{ marginTop: 15 }}
                  fullWidth
                  options={picklistFields.map((item) => item.label)}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      label={
                        <Trans>Pilot Phase In Smaller Location field</Trans>
                      }
                    />
                  )}
                />
              )}
            </div>
          );
        }}
      />
    </div>
  );
}
