import { Trans } from "@lingui/macro";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import ConfigureMultilanguageTextField from "app/views/internal/ConfigureMultilanguageTextField";
import { useEffect, useState } from "react";
import { myI18n } from "translation/I18nConnectedProvider";

const FormObjectiveItem = ({
  item,
  index,
  typeProps,
  dispatch,
  injectable,
  depth,
  disabled,
  avaliableObjectives,
  avaliableRecordTypes,
  options,
}) => {
  const [selectedObjective, setSelectedObjective] = useState(item);

  useEffect(() => {
    setSelectedObjective(item);
  }, [item]);

  const handleObjectiveChange = (event, item) => {
    const selectedObjective = event.target.value;
    const selectedObject = avaliableObjectives.find(
      (obj) => obj.value === selectedObjective
    );

    const updatedItem = {
      ...item,
      value: selectedObjective,
      labels: selectedObject.labels ? selectedObject.labels : {},
    };

    setSelectedObjective(updatedItem);

    const toSet = { ...typeProps };
    toSet.options[index] = updatedItem;
    dispatch({
      type: "FIELD",
      injectable,
      depth: depth.split("."),
      fieldName: "typeProps",
      fieldValue: toSet,
    });
  };

  const handleLabelChange = (newLabels) => {
    const toSet = { ...typeProps };
    if (!toSet.options[index].labels) {
      toSet.options[index].labels = {};
    }
    toSet.options[index].labels = newLabels;
    dispatch({
      type: "FIELD",
      injectable,
      depth: depth.split("."),
      fieldName: "typeProps",
      fieldValue: toSet,
    });

    setSelectedObjective((prev) => ({ ...prev, labels: newLabels }));
  };

  return (
    <div style={{ padding: 5 }}>
      <Grid container alignItems="center">
        <Grid item xs>
          <b>{Number(index + 1) + ". "}</b>
        </Grid>
        <IconButton
          disabled={disabled}
          onClick={(e) => {
            const toSet = { ...typeProps };
            toSet.options.splice(index, 1);
            dispatch({
              type: "FIELD",
              injectable,
              depth: depth.split("."),
              fieldName: "typeProps",
              fieldValue: { ...toSet },
            });
          }}
        >
          <Icon>delete</Icon>
        </IconButton>
      </Grid>
      <TextField
        style={{ marginBottom: 10 }}
        select
        label={<Trans>Selected Objective</Trans>}
        fullWidth
        variant="outlined"
        value={selectedObjective.value}
        onChange={(e) => {
          const newValue = e.target.value;
          const selectedObj = avaliableObjectives.find(
            (obj) => obj.value === newValue
          );
          handleObjectiveChange(e, selectedObj);
        }}
      >
        {avaliableObjectives.map((objective, index) => {
          return (
            <MenuItem key={objective.value} value={objective.value}>
              {myI18n._(objective.value)}
            </MenuItem>
          );
        })}
      </TextField>
      <FormLabel style={{ padding: 5, marginTop: 10, marginBottom: 5 }}>
        {<Trans>FORM_OBJECTIVE_ITEM_OBJECTIVE_LABELS_LABEL</Trans>}
      </FormLabel>
      <div style={{ padding: 5, marginTop: 5 }}>
        <ConfigureMultilanguageTextField
          key={selectedObjective.value}
          value={selectedObjective.labels}
          handleChange={handleLabelChange}
          disabled={avaliableObjectives.length === 0 || disabled}
          inputDebounce
          useDebounce
        />
      </div>
      <div style={{ padding: 5 }}>
        <FormLabel>
          <Trans>Avaliable in record types</Trans>:
        </FormLabel>
        <FormGroup row>
          {avaliableRecordTypes.map((item) => {
            const checked =
              selectedObjective.recordTypes &&
              selectedObjective.recordTypes.some(
                (recordType) => recordType === item.value
              );
            return (
              <FormControlLabel
                disabled={disabled}
                onChange={(e) => {
                  const toSet = { ...typeProps };
                  const newArray = selectedObjective.recordTypes
                    ? [...selectedObjective.recordTypes]
                    : [];
                  if (!checked) {
                    newArray.push(item.value);
                  } else {
                    const index = newArray.indexOf(item.value);
                    newArray.splice(index, 1);
                  }
                  toSet.options[index].recordTypes = newArray;
                  dispatch({
                    type: "FIELD",
                    injectable,
                    depth: depth.split("."),
                    fieldName: "typeProps",
                    fieldValue: toSet,
                  });
                }}
                style={{ width: "24%" }}
                key={item.value}
                value={item.value}
                control={<Checkbox checked={checked} />}
                label={item.label}
              />
            );
          })}
        </FormGroup>
      </div>
    </div>
  );
};

export default FormObjectiveItem;
