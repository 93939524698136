import { FormConnectToObject } from "app/views/forms/common/FormConnectToObject";
import { PdfPropsForm } from "app/views/forms/common/PdfPropsForm";
import { useDispatch } from "react-redux";
import { FormOtherGrants } from "./FormOtherGrants";

export const FormEditorOtherGrants = ({
  editMode,
  showPrintProps,
  typeProps,
  depth,
  injectable,
  injectableId,
  showPdfProps,
  ...props
}) => {
  const dispatch = useDispatch();

  if (!editMode) {
    return <FormOtherGrants typeProps={typeProps} {...props} />;
  }

  return (
    <>
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        validTypes={["textarea", "string"]}
      />
    </>
  );
};
