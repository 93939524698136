import { Trans, t } from "@lingui/macro";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { getLabelFromTranslationData } from "app/views/common/TranslationsCommon";
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily,
} from "app/views/forms/pdf-components/FormPdf";
import { pdfTableStyles } from "app/views/forms/pdf-components/PDFCommon";
import { myI18n } from "translation/I18nConnectedProvider";

export const FormObjectivesPdf = ({
  id,
  title,
  langVersion,
  typeProps,
  value,
}) => {
  const fontFamily =
    langVersion === "iu-cans"
      ? inuktitutFormPdfFontFamily
      : defaultFormPdfFontFamily;
  const {
    pdfHeaderTextProps = [],
    pdfBackgroundColor,
    pdfFontSize,
    displayNeedsInPDF,
    options = [],
  } = typeProps;

  const styles = {
    ...pdfTableStyles,
    ...StyleSheet.create({
      headerCell: {
        ...pdfTableStyles.tableCol,
        ...pdfTableStyles.tableCell,
        display: "flex",
        justifyContent: "center",
        alignItems: "left",
        flex: 1,
        fontFamily,
      },
      cell: {
        ...pdfTableStyles.tableCol,
        ...pdfTableStyles.tableCell,
        flex: 1,
        fontFamily,
      },
      tableRow: {
        ...pdfTableStyles.tableRow,
        flexWrap: "nowrap",
        display: "flex",
        width: "100%",
      },
    }),
  };
  const headerStyle = { ...styles.headerCell };
  headerStyle.fontWeight = "bold";
  if (pdfHeaderTextProps.includes("italics")) {
    headerStyle.fontStyle = "italic";
  }
  if (pdfHeaderTextProps.includes("underline")) {
    headerStyle.textDecoration = "underline";
  }
  if (pdfBackgroundColor) {
    headerStyle.backgroundColor = pdfBackgroundColor.id;
  }
  if (pdfFontSize) {
    headerStyle.fontSize = +pdfFontSize;
  }

  const objectiveToTranslation = {};
  options.forEach((opt) => {
    if (opt.labels && opt.value) {
      objectiveToTranslation[opt.value] = getLabelFromTranslationData({
        data: opt.labels,
        langVersion,
      });
    }
  });

  return (
    <View style={pdfTableStyles.table}>
      {title ? (
        <View style={pdfTableStyles.tableCol}>
          <Text style={pdfTableStyles.title}>{title}</Text>
        </View>
      ) : (
        <View />
      )}
      <View style={styles.tableRow}>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Objective`)}</Text>
        </View>
        {displayNeedsInPDF && (
          <View style={headerStyle}>
            <Text>{myI18n?._(t`Needs`)}</Text>
          </View>
        )}
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Outcomes`)}</Text>
        </View>
      </View>
      {value.map((data, index) => {
        const { objective, needs, outcomes = [] } = data;
        return (
          <View style={styles.tableRow} key={index}>
            <View style={styles.cell}>
              <Text style={{ fontWeight: "bold" }}>
                {objectiveToTranslation[objective] || myI18n._(objective)}
              </Text>
            </View>
            {displayNeedsInPDF && (
              <View style={styles.cell}>
                <Text>{needs}</Text>
              </View>
            )}
            <View style={styles.cell}>
              {outcomes.map((item, index) => {
                return (
                  <View key={index}>
                    <Text>
                      - <Trans id={item} />
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        );
      })}
    </View>
  );
};
