import { Trans } from '@lingui/macro'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { FormRedirectButton } from './FormRedirectButton'
import DebouncedTextField from 'app/views/forms/common/DebouncedTextField'

export const FormEditorRedirectButton = ({
  editMode,
  depth,
  typeProps,
  injectable,
  ...props
}) => {
  const dispatch = useDispatch()
  if (!editMode) {
    return <FormRedirectButton typeProps={typeProps} {...props} />
  }
  const { url, color, openInNewTab } = typeProps
  return (
    <div>
      <DebouncedTextField
        label={<Trans>URL</Trans>}
        fullWidth
        value={url}
        variant='outlined'
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.url = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(openInNewTab)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.openInNewTab = e.target.checked
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        }
        label={<Trans>FORM_EDITOR_REDIRECT_BUTTON_OPEN_IN_NEW_TAB_CHECKBOX</Trans>}
      />

      <DebouncedTextField
        label={<Trans>Color</Trans>}
        fullWidth
        value={color}
        variant='outlined'
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.color = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
    </div>
  )
}
